import React from 'react';
import {
  Modal,
} from 'antd';

import './GasLiftModal.scss';

const GasLiftModal = ({ showModal, onClose, children }) => (
  <div>
    {showModal && (
    <Modal
      className="gas-lift-modal"
      centered
      visible={showModal}
      onOk={() => onClose()}
      onCancel={() => onClose()}
      title={<div className="modal-header" />}
      footer={null}
    >
      {children}
    </Modal>
    )}
  </div>
);

export default GasLiftModal;
