/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BeatLoader } from 'react-spinners';
import { Button, Timeline } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import useFetch from '../hooks/useFetch';
import { StyledInput } from '../styled';
import { useListFilter } from '../hooks/useFilter.tsx';
import EditDiv from '../Editing/EditDiv';

const Container = styled.div`
  ${(props) => `
    background: ${props.theme.widgets.background.full};
    border: ${props.bordered ? props.theme.widgets.border.border.full : 'none'};
    box-shadow: ${props.theme.widgets.border.boxShadow};
    border-radius: ${props.rounded ? '12px' : 'none'};
    height: 100%;
    overflow: scroll;
    padding: 12px;
    
    ::-webkit-scrollbar {
      display: none
    }
    && p {
      color: ${props.theme.widgets.color.full}
    }
    .ant-timeline-item-tail {
      border-left: 2px solid ${props.theme.widgets.background.dark}
    }
    @media (max-width: 500px) {
      display: none;
    }
  `}
`;

const Header = styled.div`
  display: flex;
`;

const StyledA = styled.a`
  color: ${(props) => (props.demoMode ? 'transparent !important' : '')};
  text-shadow: ${(props) => (props.demoMode ? '0 0 12px rgba(0,0,0,0.5) !important' : '')};
`;

const WidgetTimelineV2 = ({
  id,
  props,
  config,
  match,
  parentId,
  roundedTheme,
  altitudeGroupId,
}) => {
  const {
    bordered = true,
    backgroundColor = 'white',
    redirect = false,
    styles,
    dot: {
      colorSettings: { dataIndexes, dangerValues, successValues, infoValues, warningValues },
    },
    timestampDataIndex,
    filters,
  } = props;

  const theme = useSelector((state) => state.resources.theme.palette);
  const demoMode = useSelector((state) => state.resources.demo);
  const editableLayout = useSelector((state) => state.layouts.editing.editable_layout);

  const history = useHistory();
  const location = useLocation();
  const { params } = match;
  const defaultParams = {
    org_id: params.org_id || -1,
    site_id: params.site_id || -1,
    asset_id: params.asset_id || -1,
    device_id: params.device_id || -1,
  };

  const [loading, values, fetchTimeline, error] = useFetch(config.endpoint);
  const { filteredList, setFilter } = useListFilter(values, filters);

  useEffect(() => {
    fetchTimeline({ ...defaultParams, ...config, filters: altitudeGroupId });
  }, [params, altitudeGroupId]);

  useEffect(() => {
    if (error) {
      throw Error(`Error Loading ${id} widget`);
    }
  }, [error]);

  const hasSearch = location.search.length > 0;
  const editMode = editableLayout === parentId;

  const formatTimeAndRedirect = (timestamp) => {
    const start = moment(timestamp).format('MM-DD-YYYY');
    const end = moment(timestamp).add(1, 'day').format('MM-DD-YYYY');
    history.push({ search: `?start=${start}%2000:00:00&end=${end}%2023:59:59` });
  };

  const redirectToAsset = (obj) => {
    history.push(`/asset/${obj.org_id}/${obj.site_id}/${obj.asset_id}/${obj.device_id}`);
  };

  const dotColorSettings = (obj) => {
    let color = 'blue';
    dataIndexes.map((di) => {
      const isIncluded = (val) => obj[di].includes(val);
      if (dangerValues.some(isIncluded)) {
        color = theme.danger.full;
      }
      if (successValues.some(isIncluded)) {
        color = theme.success.full;
      }
      if (infoValues.some(isIncluded)) {
        color = 'blue';
      }
      if (warningValues.some(isIncluded)) {
        color = 'orange';
      }
      return 'blue';
    });
    return color;
  };

  // redirectToAsset is hard coded to go to altitude
  const handleItemClick = (item) => {
    if (redirect) {
      return redirectToAsset(item);
    }
    return formatTimeAndRedirect(item[timestampDataIndex]);
  };

  const ResetTimeSpan = () => {
    return (
      <Button type="link" onClick={() => history.push(location.pathname)}>
        Reset Date
      </Button>
    );
  };

  return (
    <Container
      style={styles}
      bordered={bordered}
      backgroundColor={backgroundColor}
      rounded={roundedTheme}
    >
      {editMode && <EditDiv id={id} />}
      <Header>
        {hasSearch && <ResetTimeSpan />}
        <StyledInput
          placeholder="Type to filter ..."
          onChange={(e) => setFilter(e.target.value)}
          rounded={roundedTheme}
        />
      </Header>
      <Timeline style={{ padding: '24px' }}>
        {loading && <BeatLoader color="#FF6600" />}
        {!loading &&
          filteredList &&
          filteredList.map((x, index) => (
            <Timeline.Item key={index} color={dotColorSettings(x)}>
              <p>{moment(x[timestampDataIndex]).format('MM/DD/YYYY hh:mm a')}</p>
              <StyledA demoMode={demoMode.demo} onClick={() => handleItemClick(x)}>
                {x.msg}
              </StyledA>
            </Timeline.Item>
          ))}
      </Timeline>
    </Container>
  );
};

WidgetTimelineV2.defaultProps = {
  bordered: false,
  backgroundColor: 'white',
  redirect: false,
  styles: {},
  filters: [],
};

WidgetTimelineV2.propTypes = {
  props: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired,
  roundedTheme: PropTypes.bool.isRequired,
  bordered: PropTypes.bool,
  backgroundColor: PropTypes.string,
  redirect: PropTypes.bool,
  styles: PropTypes.shape({
    dot: PropTypes.shape({
      colorSettings: PropTypes.shape({
        dataIndexes: PropTypes.string,
        dangerValues: PropTypes.string,
        successValues: PropTypes.string,
        infoValues: PropTypes.string,
        warningValues: PropTypes.string,
      }),
    }),
  }),
  timestampDataIndex: PropTypes.string.isRequired,
  filters: PropTypes.array,
};

export default WidgetTimelineV2;
