import React, { useState } from 'react';

const SearchReports = ({ reports, setReports, defaultReports }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const searchSubmit = (input) => {
    const sanitizedInput = input.trim().toLowerCase();

    if (sanitizedInput !== '') {
      setSearchTerm(sanitizedInput);
      const filtered = reports.filter((r) => r.title.toLowerCase().includes(searchTerm));
      return setReports(filtered);
    }

    setSearchTerm('');
    return setReports(defaultReports);
  };

  const searchReportsComponent = (
    <section className="report-section">
      <p className="displayed-options-title">Choose Report</p>
      <div className="report-search-container">
        <div className="search-svg"></div>
        <input
          type="text"
          placeholder="Search reports..."
          className="report-search"
          name="report-search"
          onChange={(e) => searchSubmit(e.target.value)}
        ></input>
      </div>
    </section>
  );

  return searchReportsComponent;
};

export default SearchReports;
