/* tslint:disable */
import React, {
  useState,
  useEffect,
  ReactElement,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Divider,
  Table,
  Row,
  Col,
  Image,
} from 'antd';
import GoogleMapReact from 'google-map-react';
import { PushpinTwoTone } from '@ant-design/icons';

import { getUrl } from 'shared/helpers/repositoryHelpers';
import useFetch from 'components/hooks/useFetch';
import useAltitudeMatchParamsCallback from 'components/hooks/useAltitudeMatchParamsCallback';
import { tableColumns, tableConfig, mapConfig } from './constants';

interface Image {
  asset_name: string;
  alt_name: string;
  device_id: number;
  org_id: number;
  asset_id: number;
  site_id: number;
  site_name: string;
  org_name: string;
  source: string;
}

interface LatLong {
  lat: number;
  long: number;
}

interface TableData {
  name: string;
  value: string | number;
}
interface SingleAssetState {
  singleAsset: {
    asset: {
      assetName: string,
    }
  }
}

const MoreInformation = (): ReactElement => {
  const selectAsset = ({ singleAsset: { asset } }: SingleAssetState) => asset;
  const asset = useSelector(selectAsset);
  const getAltitudeParams = useAltitudeMatchParamsCallback();
  const [tableRows, setTableRows] = useState<TableData[] | []>([]);
  const [latLong, setLatLong] = useState<LatLong | null>(null);

  const baseUrl = getUrl(true);
  const [, image, fetchImage] = useFetch(`/unit-profiles/images/assets?assetName=${asset?.assetName}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
    baseUrl,
  });
  const [, tableData, fetchTableData] = useFetch('/v1/axil/config/tag_values/by_tag_id');
  const [, mapData, fetchMapData] = useFetch('/v1/axil/config/tag_values/by_tag_id');

  useEffect(() => {
    (async () => {
      fetchTableData({ ...getAltitudeParams(), ...tableConfig });
      fetchMapData({ ...getAltitudeParams(), ...mapConfig });
      fetchImage({});
    })();
  }, [getAltitudeParams]);

  useEffect(() => {
    if (tableData && tableData.length > 0) {
      const data = tableData[0];
      const {
        compressor_serial,
        application_type,
        next_pm_date,
        last_pm_date,
        driver_rated_hp,
        driver,
        latitude,
        longitude,
      } = data;

      const curatedTableData: TableData[] = [
        { name: 'Driver', value: driver },
        { name: 'Application Type', value: application_type },
        { name: 'Compressor Serial', value: compressor_serial },
        { name: 'Driver Rated HP', value: driver_rated_hp },
        { name: 'Next PM Date', value: next_pm_date },
        { name: 'Last PM Date', value: last_pm_date },
        { name: 'Latitude', value: latitude },
        { name: 'Longitude', value: longitude },
      ];

      setTableRows(curatedTableData);
    }
  }, [tableData]);

  useEffect(() => {
    if (mapData && mapData.length > 0) {
      const { lat, lon } = mapData[0];
      setLatLong({ lat, long: lon });
    }
  }, [mapData]);

  // lat/lng needed for point to work properly on map
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const MapPoint = ({ lat, lng }: { lat?: number; lng?: number }): ReactElement => (
    <PushpinTwoTone style={{ fontSize: '36px' }} />
  );

  return (
    <div style={{ marginTop: '24px', background: 'white', minHeight: '160vh' }}>
      <Row gutter={[12, 0]}>
        <Col span={6} offset={1}>
          <Divider> Unit Profile </Divider>
          <Table pagination={false} columns={tableColumns} dataSource={tableRows} />
        </Col>
        {image?.url && (
          <Col span={6} offset={1}>
            <Divider> Image </Divider>
            <Image src={image.url} />
          </Col>
        )}
        {latLong?.lat && latLong?.long && (
          <Col span={14} offset={1}>
            <Divider> Location </Divider>
            <div style={{ height: '500px' }}>
              <GoogleMapReact
                center={{ lat: latLong.lat, lng: latLong.long }}
                defaultZoom={10}
                yesIWantToUseGoogleMapApiInternals
                bootstrapURLKeys={{ key: 'AIzaSyB-_Hv0w3ujV4E0Fs0OjqHDsPO9iTSrMBk' }}
              >
                <MapPoint lat={latLong.lat} lng={latLong.long} />
              </GoogleMapReact>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default MoreInformation;
