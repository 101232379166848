import { useLayoutEffect, useState } from 'react';

const useWindowSize = () => {
  const [size, setSize] = useState([null, 0]);

  useLayoutEffect(() => {
    const updateSize = () => {
      if (window.innerWidth >= 1600) {
        setSize(['xl', window.innerWidth]);
      } else if (window.innerWidth < 1600 && window.innerWidth >= 1200) {
        setSize(['lg', window.innerWidth]);
      } else if (window.innerWidth < 1200 && window.innerWidth >= 996) {
        setSize(['md', window.innerWidth]);
      } else if (window.innerWidth < 996 && window.innerWidth >= 768) {
        setSize(['sm', window.innerWidth]);
      } else if (window.innerWidth < 768 && window.innerWidth >= 480) {
        setSize(['xs', window.innerWidth]);
      } else if (window.innerWidth < 480) {
        setSize(['xxs', window.innerWidth]);
      };
    };

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}

export default useWindowSize;
