import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Button } from 'antd';

import { StyledInput } from '../styled';

const Container = styled.div`
  color: ${(props) => props.theme.widgets.color.full};
  & label {
    color: ${(props) => props.theme.widgets.color.full};
  }
  && .ant-form-item-control-input-content {
    color: ${(props) => props.theme.widgets.color.full};
  }
  && .ant-form-item-explain {
    color: ${(props) => props.theme.widgets.color.full};
  }
`;

const AccountInformation = ({
  user = { name: '', email: '' },
  userSettings,
  setUserSettings,
  updatePreferences,
}) => {
  const [edit, toggleEdit] = useState(false);
  const [phoneVal, setVal] = useState({
    help: '',
    validation_status: '',
  });
  const layout = {
    labelCol: {
      span: 2,
    },
    wrapperCol: {
      span: 22,
    },
  };

  useEffect(() => {
    if (userSettings && userSettings.phone_number) {
      if (userSettings.phone_number.length === 10) {
        setVal({
          help: '',
          validation_status: 'success',
        });
      } else {
        setVal({
          help: '*Must be 10 digits. Numbers Only',
          validation_status: 'error',
        });
      }
    }
  }, [userSettings]);

  return (
    <Container>
      <Form {...layout}>
        <Form.Item label="Name"> {user.name} </Form.Item>
        <Form.Item label="Email"> {user.email} </Form.Item>
        <Form.Item
          label="Phone Number"
          help={phoneVal.help}
          validateStatus={phoneVal.validation_status}
        >
          {edit ? (
            <StyledInput
              style={{ width: '200px' }}
              value={userSettings && userSettings.phone_number ? userSettings.phone_number : ''}
              onChange={(e) => setUserSettings({ ...userSettings, phone_number: e.target.value })}
              type="number"
            />
          ) : userSettings && userSettings.phone_number ? (
            userSettings.phone_number
          ) : (
            ''
          )}
          {edit ? (
            <Button
              disabled={phoneVal.validation_status === 'error'}
              type="link"
              onClick={() => {
                toggleEdit(!edit);
                updatePreferences(userSettings);
              }}
            >
              Done
            </Button>
          ) : (
            <Button onClick={() => toggleEdit(!edit)} type="link">
              Edit
            </Button>
          )}
        </Form.Item>
        <Form.Item label="App Version"> 2.1.8 </Form.Item>
      </Form>
    </Container>
  );
};

export default AccountInformation;
