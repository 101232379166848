const isProd = process.env.REACT_APP_BUILD_ENV === 'production';
const devPrefix = !isProd ? 'dev-' : '';
const localSuffix = process.env.REACT_APP_IS_LOCAL === 'true' ? '/local' : '';
const baseUrl = `https://${devPrefix}api.axil.ai${localSuffix}`;
const enterpriseObjectsUrl = `${baseUrl}/enterprise-objects`;
const updateIpAddressUrl = `${baseUrl}/flux/services/connectivity`;
const connectIpAddressUrl = `${baseUrl}/integrations/axil/events`;

export class EnterpriseObjectsRepository {
  static async searchByAssetName(token, assetName) {
    try {
      const res = await fetch(`${enterpriseObjectsUrl}/assets?name=${assetName}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      const response = await res.json();
      return response;

      return res;
    } catch (error) {
      console.log('error in repo ', error);
      return error;
    }
  }

  static async checkIpAddress(token, body) {
    try {
      const res = await fetch(
        `${updateIpAddressUrl}/orgs/${body.orgId}/sites/${body.siteId}/assets/${body.assetId}?ipAddress=${body.ipAddress}&&port=${body.port}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      const response = await res.json();
      return response;
    } catch (error) {
      console.log('error in repo ', error);
      return error;
    }
  }

  static async connectAssetIpAddress(token, body) {
    try {
      const res = await fetch(connectIpAddressUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const response = await res.json();
      return response;
    } catch (error) {
      console.log('error in repo ', error);
      return error;
    }
  }
}
