import styled from 'styled-components';
const borderRadius = '3px';

export const TableRow = styled.tr`
`;

export const TableHead = styled.thead`
`;

export const TableHeader = styled.th`
  background-color: ${props => props.theme.widgets.background.dark};
  color: ${props => props.theme.widgets.color.full};
  font-size: 14px;
  text-align: left;
  line-height: 1.5;
  position: relative;
  font-variant: tabular-nums;
  &:after {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		height: 24px;
	} 
`;

export const TableCell = styled.td`
  color: ${props => props.theme.widgets.color.full};
  background-color: ${props => props.theme.widgets.background.full};
  line-height: 1.5;
  text-align: left;
  font-size: 14px;
  font-variant: tabular-nums;
`;

export const TableBody = styled.tbody`
  border-radius: ${borderRadius};
`;

export const AddRow = styled.div`
  display: inline-flex;
  width: 100%;
`;

export const TableContainer = styled.div`
  margin-bottom: 18px;
`;

export const HeaderRow = styled.tr`
  background-color: ${props => props.theme.widgets.background.full};
  color: ${props => props.theme.widgets.color.full};
  border-radius: 6px;
  height: 36px;
`;

export const InputWrapper = styled.div`
  width: 98%;
  margin: 6px;
`;

export const SaveButtonDiv = styled.div`
  float: right;
  margin-top: 12px;
`;

export const TableTitle = styled.div`
  background-color: ${props => props.theme.widgets.background.full};
  color: ${props => props.theme.widgets.color.full};
  font-weight: 500;
  font-size: 20px;
  padding: 16px;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
    ${TableBody} {
      box-shadow: ${({ bordered, ...props }) => bordered ? `0 0 0 1px grey` : 'none'};
      border-radius: ${borderRadius};
      box-sizing: border-box;

      ${TableCell} {
        &:first-child {
          border-left: 0;
        }
        border: ${({ bordered, ...props }) => bordered ? `1px solid #e8e8e8` : 'none'};
        box-sizing: border-box;
      }
      
      ${TableRow} {
        background-color: ${props => props.theme.widgets.background.full};
        color: ${props => props.theme.widgets.color.full};
        
          ${TableCell} {
            &:first-child {
              border-top-left-radius: ${({ striped }) => striped ? borderRadius : 0};
              border-bottom-left-radius: ${({ striped }) => striped ? borderRadius : 0};
            }

            &:last-child {
              border-top-right-radius: ${({ striped }) => striped ? borderRadius : 0};
              border-bottom-right-radius: ${({ striped }) => striped ? borderRadius : 0};
            }
          }
        }

        &:last-child {
          ${TableCell} {
            border-bottom: none;
            &:first-child {
              border-bottom-left-radius: ${borderRadius};
              border-left: none;
            }
            &:last-child {
              border-bottom-right-radius: ${borderRadius};
              border-right: none;
            }
          }
        }

        &:first-child {
          ${TableCell} {
            border-top: none;
            &:first-child {
              border-top-left-radius: ${borderRadius};
              border-left: none;
            }
            &:last-child {
              border-top-right-radius: ${borderRadius};
              border-right: none;
            }
          }
        }

        ${({ columnWidths }) => columnWidths && columnWidths.length > 0 && columnWidths
        .map((w, i) => `
          td:nth-child(${i + 1}), :nth-child(${i + 1}) {
            width: ${w.trim().length > 0 ? w : 'auto'};
          }
        `)};
      }
    }
`;

export const StyledTable = styled(Table)`
  ${TableRow} {
    background-color: ${props => props.theme.widgets.background.full};
    height: 40px;
    :hover {
      background-color: ${props => props.theme.widgets.background.light};
    }
  }
  ${TableHead} {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.5;
    ${TableHeader} {
      font-weight: 500;
      padding: 16px 16px;
      &:last-child {
        border-right: none;
      }
    }
  }
  ${TableHeader} {
    background-color: ${props => props.theme.widgets.background.dark};
  }
  ${(props) => props.width ? props.width.map((w, index) => (
    `td: nth-child(${index + 1}) {
       width: ${w}
       border: ${ props.borderless ? 'none' : '1px solid #e8e8e8 !important' };
       font-style: normal;
       font-size: 15px;
       color: rgba(0,0,0,0.65);
       line-height: 1.5;
       padding: 16px 16px;
       background-color: ${props.theme.widgets.background.full};
       color: ${props.theme.widgets.color.full};
     }`
   )) :
   `& td {
     witdh: 12%;
     border: ${ props.borderless ? 'none' : '1px solid #e8e8e8 !important' };
     font-style: normal;
     font-size: 15px;
     padding: 16px 16px;
     color: rgba(0,0,0,0.65);
     line-height: 1.5;
     background-color: ${props.theme.widgets.background.full};
     color: ${props.theme.widgets.color.full};
   }
   `
 }
`;

