import React from 'react';
import Modal from 'react-modal';

import ReportCardsComponent from '../report-form/ReportCardsComponent';
import SearchReportsComponent from '../report-form/ReportSearchComponent';

import ReportFrequencyComponent from './ReportFrequencyComponent';
import ScheduleDayComponent from './ScheduleDayComponent';
import ScheduleTimeComponent from './ScheduleTimeComponent';
import ReportTitleComponent from './ReportTitleComponent';

const ScheduleReportSection = ({
  currentFrequency,
  setScheduledTime,
  setScheduledDay,
  submitScheduledReport,
  setCurrentFrequency,
  handleChange,
  setShowSchedule,
  showSchedule,
  frequency,
}) => {
  return (
    <article className="schedule-container">
      <ReportTitleComponent handleChange={handleChange} />
      <div className="form-row">
        <ReportFrequencyComponent setCurrentFrequency={setCurrentFrequency} />
      </div>
      <div className="form-row">
        {currentFrequency !== 'Daily' && (
          <ScheduleDayComponent frequency={frequency} setScheduledDay={setScheduledDay} />
        )}
      </div>
      <div className="form-row">
        <ScheduleTimeComponent setScheduledTime={setScheduledTime} />
      </div>
      <button className="report-submit-button" onClick={() => submitScheduledReport()}>
        Submit
      </button>
      <button className="report-schedule-button" onClick={() => setShowSchedule(!showSchedule)}>
        Close Scheduling
      </button>
    </article>
  );
};

export default ScheduleReportSection;
