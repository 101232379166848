import React from 'react';

const AreasComponent = ({ handleChange, areas }) => {
  const displayedAreas = areas.map((asset) => {
    return (
      <option value={asset} key={asset} className="area-option">
        {asset}
      </option>
    );
  });

  return (
    <section className="report-section">
      <p className="displayed-options-title">Area</p>
      <select className="area-container" name="area" onChange={(e) => handleChange(e)}>
        {displayedAreas}
      </select>
    </section>
  );
};

export default AreasComponent;
