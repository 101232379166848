import {
  FLAXIL_API, AXIL_API, AXIL_API_DEV, FLAXIL_API_DEV,
} from 'shared/repositories/urls';

export const getUrl = (isAxil = false) => (
  process.env.REACT_APP_BUILD_ENV === 'production'
    ? (!isAxil
      ? FLAXIL_API
      : AXIL_API)
    : (!isAxil
      ? FLAXIL_API_DEV
      : process.env.REACT_APP_IS_LOCAL
        ? `${AXIL_API_DEV}/local`
        : AXIL_API_DEV
    ));
