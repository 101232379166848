export const findAssetMatch = (assetList, assetId) => assetList.find(({ asset_id }) => `${asset_id}` === `${assetId}`);

export const isNewAsset = ({
  org_id,
  site_id,
  asset_id,
  device_id,
}, {
  orgId,
  siteId,
  assetId,
  deviceId,
}) => (
  `${orgId}/${siteId}/${assetId}/${deviceId}`
  !== `${org_id}/${site_id}/${asset_id}/${device_id}`
);
