import React, { useRef } from 'react';
import { Row, Col, Card, DatePicker } from 'antd';
import styled from 'styled-components';
import moment from 'moment';

const Container = styled(Card)`
  && {
    background: ${props => props.theme.widgets.background.full};
    box-shadow: ${props => props.theme.widgets.border.boxShadow};
    border: ${props => props.theme.widgets.border.border.full};
    border-radius: ${props => props.rounded ? '12px' : 'none'};
    height: 100%;
  }
  .ant-card-body {
    padding: 12px;
  }
`;

const StatLabel = styled.p`
  margin: 0;
`;

const ScoreboardMockup = ({ props }) => {
  const { status } = props;

  const settings = status
    ? {
      color: 'green',
      stat1: '197',
      stat2: '200',
    }
    : {
      color: 'red',
      stat1: '154',
      stat2: '192',
    }

  return (
    <Container>
      <div style={{
        justifyContent: 'space-between',
        display: 'flex',
        height: '40px',
        borderBottom: '1px solid lightgrey',
        marginBottom: '8px',
        fontWeight: '400',
        fontSize: '18px',
        color: '#33333'
        }}
      >
        <div> Leakage Check - prior day comparison </div>
        <div>
          <DatePicker.RangePicker size='middle' />
        </div>
      </div>
      <Row justify="center" style={{ height: '100%' }}>
        <Col span={12}>
          <Row justify='center'>
            <StatLabel> Total Volume Metered (mcfd) </StatLabel>
          </Row>
          <Row justify='center'>
            <div style={{ fontSize: 48, color: settings.color }}> { settings.stat1 } </div>
          </Row>
        </Col>
        <Col span={12}>
          <Row justify='center'>
            <StatLabel> Process Simulator Rate (mcfd) </StatLabel>
          </Row>
          <Row justify='center'>
            <div style={{ fontSize: 48, color: settings.color }}> {settings.stat2} </div>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default ScoreboardMockup;
