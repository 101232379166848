import styled from 'styled-components';

export const UrsaLeoIFrame = styled.iframe`
  border: none;
  height: 650px;
  width: 100%;
`;

export const WrapperDiv = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px auto;
`;
