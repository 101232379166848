import React from 'react';

const ScheduleDayComponent = ({ setScheduledDay, frequency }) => {
  const scheduleDayOptions = [
    { label: 'Sunday', value: '0' },
    { label: 'Monday', value: '1' },
    { label: 'Tuesday', value: '2' },
    { label: 'Wednesday', value: '3' },
    { label: 'Thursday', value: '4' },
    { label: 'Friday', value: '5' },
    { label: 'Saturday', value: '6' },
  ];

  const scheduleMonthDayOptions = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
  ];

  const displayedScheduleWeekDayOptions = [];
  const displayedScheduleMonthDayOptions = [];

  scheduleDayOptions.map((option) => {
    displayedScheduleWeekDayOptions.push(
      <option key={option.value} value={option.value}>
        {option.label}
      </option>,
    );
  });

  scheduleMonthDayOptions.map((option) => {
    displayedScheduleMonthDayOptions.push(
      <option key={option} value={option}>
        {option}
      </option>,
    );
  });

  return (
    <section className="report-section">
      <p className="displayed-options-title">Day</p>
      <select
        name="frequency-day"
        onChange={(e) => setScheduledDay(e.target.value)}
        className="schedule-day-container"
      >
        {frequency === 'Monthly'
          ? displayedScheduleMonthDayOptions
          : displayedScheduleWeekDayOptions}
      </select>
    </section>
  );
};

export default ScheduleDayComponent;
