import * as telemetryLayoutActionTypes from 'single-asset/actions/telemetryConfigActionTypes';
import { flattenLegacyLayoutResponse } from 'single-asset/helpers/telemetryHelpers';

export const defaultState = {
  isPending: false,
};

const telemetryConfigReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case telemetryLayoutActionTypes.TELEMETRY_CONFIG__FETCH_CONFIG: {
      return {
        ...defaultState,
        isPending: true,
      };
    }

    case telemetryLayoutActionTypes.TELEMETRY_CONFIG__FETCH_CONFIG_FAILED: {
      const { errorResponse } = action;

      return {
        ...defaultState,
        errorResponse,
      };
    }

    case telemetryLayoutActionTypes.TELEMETRY_CONFIG__FETCH_CONFIG_SUCCEEDED: {
      return {
        ...defaultState,
        data: flattenLegacyLayoutResponse(action.config),
      };
    }

    default: {
      return state;
    }
  }
};

export default telemetryConfigReducer;
