import React, { useContext, useState, useEffect, ReactElement, useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import EditDiv from '../Editing/EditDiv';
import { GlobalContext } from '../../GlobalContext';
import { BackButton } from '../Framework';
import { AltitudeMatchParams, Asset } from '../../global.interfaces';
import { Routes } from '../../constants';
import useAltitudeMatchParamsCallback from '../hooks/useAltitudeMatchParamsCallback';

const BreadCrumbs = styled.div`
  display: inline-flex;
`;

const Label = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-size: 10px;
  margin-left: 20px;
  opacity: 0.2;
`;

const Crumb = styled.div<{ demoMode?: boolean }>`
  align-items: start;
  color: ${(props) => (props.demoMode ? 'transparent !important' : props.theme.widgets.color.full)};
  cursor: pointer;
  display: flex;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  padding: 3px;
  text-shadow: ${(props) => (props.demoMode ? '0 0 12px rgba(0,0,0,0.5) !important' : '')};
`;

const WidgetBreadcrumbs = ({ parentId, id }: { parentId: string; id: string }): ReactElement => {
  const history = useHistory();

  const [altNames, setAltNames] = useState({
    device_name: '',
    asset_name: '',
    site_name: '',
    org_name: 'Main Dashboard',
  });

  const { assetList } = useContext(GlobalContext);

  const demoMode = useAppSelector((state) => state.resources.demo);
  const editableLayout = useAppSelector((state) => state.layouts.editing.editable_layout);
  const editMode = editableLayout === parentId;
  const getAltitudeMatchParams = useAltitudeMatchParamsCallback();
  const { asset_id, site_id, org_id } = getAltitudeMatchParams();

  const findAsset = useCallback(() => {
    let asset;
    const altitudeParams = getAltitudeMatchParams();
    const idTypes = Object.keys(altitudeParams) as Array<keyof AltitudeMatchParams>;

    // eslint-disable-next-line prefer-const
    for (let idType of idTypes) {
      const param = parseInt(altitudeParams[idType] || '');

      if (param) {
        asset = assetList.find((lAsset) => lAsset[idType] === param);
        break;
      }
    }

    return asset;
  }, [assetList, getAltitudeMatchParams]);

  const generateAltNames = useCallback(
    (asset: Asset | undefined) => {
      const setName = (prefix: 'site' | 'asset' | 'device') => {
        const id = `${prefix}_id` as keyof AltitudeMatchParams;
        const name = `${prefix}_name` as keyof Asset;
        return (getAltitudeMatchParams()[id] && (asset?.[name] as string)) || '';
      };

      setAltNames({
        org_name: asset?.org_name || (org_id ? '' : 'Main Dashboard'),
        site_name: setName('site'),
        asset_name: setName('asset'),
        device_name: setName('device'),
      });
    },
    [getAltitudeMatchParams, org_id],
  );

  useEffect(() => {
    if (assetList.length > 0) {
      const asset = findAsset();

      generateAltNames(asset);

      if (!asset) history.replace(Routes.DASHBOARD);
    }
  }, [assetList, findAsset, generateAltNames, history]);

  const redirect = (depth: string) => {
    const orgLink = `${Routes.DASHBOARD}/${org_id}`;
    const siteLink = `${orgLink}/${site_id}`;
    let redirectLink: string;

    switch (depth) {
      case 'asset_name':
        redirectLink = `${siteLink}/${asset_id}`;
        break;
      case 'site_name':
        redirectLink = siteLink;
        break;
      default:
        redirectLink = orgLink;
    }

    if (history.location.pathname !== redirectLink) history.push(redirectLink);
  };

  const labels = {
    org_name: altNames.org_name === 'Main Dashboard' ? '' : 'Organization',
    site_name: 'Site',
    asset_name: 'Asset',
    device_name: 'Device',
  };

  return (
    <BreadCrumbs>
      <BackButton />
      {editMode && <EditDiv id={id} />}
      {(Object.keys(labels) as Array<keyof typeof labels>).map((label) => {
        const isOrgName = label === 'org_name';

        const additionalCrumbProps = {
          onClick: label !== 'device_name' && org_id ? () => redirect(label) : undefined,
          style: isOrgName ? { fontWeight: 500 } : {},
        };

        return altNames[label] || isOrgName ? (
          <div>
            <Label style={isOrgName ? { marginLeft: 7 } : {}}>{labels[label]}</Label>
            <Crumb demoMode={demoMode.demo} {...additionalCrumbProps}>
              {` ${!isOrgName ? '/ ' : ''}${altNames[label]}`}
            </Crumb>
          </div>
        ) : null;
      })}
    </BreadCrumbs>
  );
};

export default WidgetBreadcrumbs;
