import React, { ReactElement, useContext } from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { TooltipHeader, Row, HeaderKey, HeaderBody, MapTooltipDiv } from './styles';
import { useAppSelector } from '../../../store/hooks';
import { MapTooltipProps } from './types';
import { GlobalContext } from '../../../GlobalContext';

const MapTooltip = ({
  isCluster,
  item,
  keyAverage,
  keyCounter,
  keyValues,
  open,
}: MapTooltipProps): ReactElement | null => {
  const history = useHistory();
  // TODO: Find more info on this
  // Using assetList instead to find a valid device_id
  // const assetInfo = useAppSelector((state) => state.resources.altitude_info);
  const { assetList } = useContext(GlobalContext);

  const navigate = () => {
    // if (assetInfo) {
    const { org_id, site_id, asset_id } = item.points[0];
    const device_id = assetList.find((asset) => asset.asset_id === asset_id)?.device_id;

    history.push(`/asset${device_id ? `/${org_id}/${site_id}/${asset_id}/${device_id}` : ''}`);
    // }
  };

  const label = (obj: Record<string, string | number | undefined>) =>
    Object.keys(obj)[0]
      .replace(/(_)/g, ' ')
      .split(' ')
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(' ');

  const getTooltipHeader = () =>
    isCluster ? `Cluster Count: ${item.numPoints}` : `Asset: ${item.points[0].alt_name}`;

  const renderRow = (rowInfo: Record<string, string | number | undefined>) => (
    <Row>
      <HeaderKey>{label(rowInfo)}:</HeaderKey>
      <HeaderBody>{Object.values(rowInfo)[0]}</HeaderBody>
    </Row>
  );

  return !open ? null : (
    <MapTooltipDiv>
      <div>
        <TooltipHeader>{getTooltipHeader()}</TooltipHeader>
        {[keyAverage, keyCounter, ...(!isCluster ? [keyValues] : [])].map(
          (rows) => rows && rows.map((rowInfo) => renderRow(rowInfo)),
        )}
        {!isCluster && (
          <Row>
            <Button onClick={navigate}>View Asset</Button>
          </Row>
        )}
      </div>
    </MapTooltipDiv>
  );
};

export default MapTooltip;
