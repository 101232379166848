import { wellIdList } from 'single-asset/constants/gasLiftConstants';

const well1Fault = {
  config: {
    agg: true,
    endpoint: '/v1/axil/config/tag_values/by_tag_id',
    tags: {
      value: {
        agg_func: 'last_value',
        key: 'str_val',
        tag_id: '1770',
      },
    },
  },
  props: {
    precision: false,
    prefix: '',
    suffix: '',
    title: 'Fault',
    timestamp: false,
    wellId: wellIdList.at(0),
  },
};

const well1Alarm = {
  config: {
    agg: true,
    endpoint: '/v1/axil/config/tag_values/by_tag_id',
    tags: {
      value: {
        agg_func: 'last_value',
        key: 'str_val',
        tag_id: '1771',
      },
    },
  },
  props: {
    precision: false,
    prefix: '',
    suffix: '',
    title: 'Alarm',
    timestamp: false,
    wellId: wellIdList.at(0),
  },
};

const well2Fault = {
  config: {
    agg: true,
    endpoint: '/v1/axil/config/tag_values/by_tag_id',
    tags: {
      value: {
        agg_func: 'last_value',
        key: 'str_val',
        tag_id: '1772',
      },
    },
  },
  props: {
    precision: false,
    prefix: '',
    suffix: '',
    title: 'Fault',
    timestamp: false,
    wellId: wellIdList.at(1),
  },
};

const well2Alarm = {
  config: {
    agg: true,
    endpoint: '/v1/axil/config/tag_values/by_tag_id',
    tags: {
      value: {
        agg_func: 'last_value',
        key: 'str_val',
        tag_id: '1773',
      },
    },
  },
  props: {
    precision: false,
    prefix: '',
    suffix: '',
    title: 'Alarm',
    timestamp: false,
    wellId: wellIdList.at(1),
  },
};

const well3Fault = {
  config: {
    agg: true,
    endpoint: '/v1/axil/config/tag_values/by_tag_id',
    tags: {
      value: {
        agg_func: 'last_value',
        key: 'str_val',
        tag_id: '1774',
      },
    },
  },
  props: {
    precision: false,
    prefix: '',
    suffix: '',
    title: 'Fault',
    timestamp: false,
    wellId: wellIdList.at(2),
  },
};

const well3Alarm = {
  config: {
    agg: true,
    endpoint: '/v1/axil/config/tag_values/by_tag_id',
    tags: {
      value: {
        agg_func: 'last_value',
        key: 'str_val',
        tag_id: '1775',
      },
    },
  },
  props: {
    precision: false,
    prefix: '',
    suffix: '',
    title: 'Alarm',
    timestamp: false,
    wellId: wellIdList.at(2),
  },
};

const well4Fault = {
  config: {
    agg: true,
    endpoint: '/v1/axil/config/tag_values/by_tag_id',
    tags: {
      value: {
        agg_func: 'last_value',
        key: 'str_val',
        tag_id: '1776',
      },
    },
  },
  props: {
    precision: false,
    prefix: '',
    suffix: '',
    title: 'Fault',
    timestamp: false,
    wellId: wellIdList.at(3),
  },
};

const well4Alarm = {
  config: {
    agg: true,
    endpoint: '/v1/axil/config/tag_values/by_tag_id',
    tags: {
      value: {
        agg_func: 'last_value',
        key: 'str_val',
        tag_id: '1777',
      },
    },
  },
  props: {
    precision: false,
    prefix: '',
    suffix: '',
    title: 'Alarm',
    timestamp: false,
    wellId: wellIdList.at(3),
  },
};

export default [
  well1Fault,
  well1Alarm,
  well2Fault,
  well2Alarm,
  well3Fault,
  well3Alarm,
  well4Fault,
  well4Alarm,
];
