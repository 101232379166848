import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import { SubList, Asset } from './global.interfaces';
import { setSession } from 'shared/actions/sessionActions';
import { getUrl } from 'shared/helpers/repositoryHelpers';

interface Context {
  assetList: Asset[];
  hoverValue: string | null;
  accessToken: string | null;
  altitudeGroupId: number[] | null;
  subscriptionList: any;
  assetTypeId: number | null;
  setAssetTypeId: Function;
  setAltGroupId: Function;
  setHoverValue: Function;
}

const defaultContext: Context = {
  assetList: [],
  hoverValue: null,
  accessToken: null,
  altitudeGroupId: null,
  subscriptionList: {},
  assetTypeId: null,
  setAssetTypeId: () => null,
  setAltGroupId: () => null,
  setHoverValue: () => null,
};

export const GlobalContext = createContext(defaultContext);

export const GlobalProvider = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const [hoverValue, setHoverValue] = useState<string | null>(null);
  const [subscriptionList, setSubList] = useState<SubList>({});
  const [assetList, setAssetList] = useState<Asset[]>([]);
  const [altitudeGroupId, setAltGroupId] = useState<number[]>([]);
  const [assetTypeId, setAssetTypeId] = useState<number>(-1);
  const [accessToken, setToken] = useState<string>('');


  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        const token = await getAccessTokenSilently();
        setToken(token);
        dispatch(setSession({ user, isAuthenticated, token }));
      })();
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  useEffect(() => {
    if (accessToken) {
      (async () => {
        try {
          const base = getUrl();
          const fetchFn = async (
            endpoint: string,
            params: { filters?: number[] },
          ): Promise<any> => {
            const results = await fetch(`${base}${endpoint}`, {
              method: 'POST',
              body: JSON.stringify(params),
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
              },
            });

            return results.json();
          };

          const [assetListData, subscriptionList] = await Promise.all([
            fetchFn('/v1/axil/flux/views/asset_list', { filters: altitudeGroupId }),
            fetchFn('/v1/axil/notifications/subscribed/assets', {}),
          ]);

          setAssetList(assetListData);
          setSubList(subscriptionList?.results?.subscriptions);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [accessToken, altitudeGroupId]);

  return (
    <GlobalContext.Provider
      value={{
        assetList,
        hoverValue,
        accessToken,
        setAltGroupId,
        setHoverValue,
        altitudeGroupId,
        subscriptionList,
        assetTypeId,
        setAssetTypeId,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
