import React, { useState } from 'react';
import styled from 'styled-components';
import Breakpoint from 'react-socks';
import { NavLink, useHistory } from 'react-router-dom';
import { Menu, Drawer } from 'antd';

import { ReactComponent as Logo } from 'assets/images/FluxLogo.svg';

const { SubMenu } = Menu;

const ThemedDrawer = styled(Drawer)`
  ${(props) => `
     & .ant-drawer-header {
       background: ${props.theme.widgets.background.full};
       border-bottom: ${props.theme.widgets.border.border.full};
     }
     & .ant-drawer-title {
       color: ${props.theme.widgets.color.full};
     }
     & .ant-drawer-body {
       background: ${props.theme.widgets.background.full};
       padding: 0;
     }
     & .ant-drawer-content {
       overflow: hidden;
     }
  `};
`;

const Container = styled.div`
  background: ${(props) => props.theme.widgets.background.full};
  height: 64px;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
`;

const MenuButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${(props) => props.theme.palette.neutral['neutral-200']};
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: left;
  width: 110px;
  display: flex;
  position: absolute;
  top: 18px;

  &:focus {
    outline: none;
  }
`;

const MenuIcon = styled.i`
  display: block;
  color: ${(props) => props.theme.palette.primary.full};
  margin-left: 18px;
`;

const MenuTitle = styled.p`
  display: block;
  margin: 0 8px;
  &&& {
    color: ${(props) => props.theme.widgets.color.full};
  }
`;

const SubNavContainer = styled(Menu)`
  background: ${(props) => props.theme.widgets.background.full};
  border-right: ${(props) => props.theme.widgets.border.border.full};
  color: ${(props) => props.theme.widgets.color.full} !important;   
    
  .customclass {
    padding-left: 12px !important;
  }

  && .ant-menu-item {
    height: 50px;
    line-height: 50px;
    margin: 0;
    border-right: ${(props) => props.theme.widgets.border.border.full};
    span > a {
      color: ${(props) => props.theme.widgets.color.full};
    }
  }

  .ant-menu-submenu-title {
    padding-left: 12px !important;
  }

  .ant-menu-item-only-child {
    background: ${(props) => props.theme.widgets.background.full};
  }

  .ant-menu-item-active {
    color: ${(props) => props.theme.palette.primary.full} !important;
  }

  .ant-menu-item-selected {
    background: ${(props) => props.theme.widgets.background.full} !important;
    > * {
      color: ${(props) => props.theme.palette.primary.full} !important;
      a {
        color: ${(props) => props.theme.palette.primary.full} !important;
      }
    }
  }

  .ant-menu-submenu-active {
    > i {
      color: ${(props) => props.theme.palette.primary.full} !important;
    }
    > * { 
      color: ${(props) => props.theme.palette.primary.full} !important;
    }
  }
  
  && .ant-menu-vertical,
  .ant-menu-item::after,
  .ant-menu-vertical-left,
  .ant-menu-item::after,
  .ant-menu-vertical-right,
  .ant-menu-item::after,
  .ant-menu-inline,
  .ant-menu-item::after {
    border-color: ${(props) => props.theme.palette.primary.full};
  }

  @media (min-width: 768px) {
    bottom: 0;
    left: 66px;
    position: fixed;
    top: 51px;
    width: 190px;
  }
`;

const FluxLogo = styled(Logo)`
  && {
    height: 70%;
    width: 85px;
  }
`;

const AxilLogo = styled.a`
  display: block;
  float: right;
  margin-top: 18px;
  margin-right: 18px;

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    float: none;
    margin-right: 0;
    margin-bottom: 18px;
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  color: ${(props) => props.theme.widgets.color.full};
  background: linear-gradient(to left,
      ${(props) => props.theme.widgets.background.light} 35%,
      ${(props) => props.theme.widgets.background.dark} 80%
    )
    left bottom no-repeat;
  background-size: 100% 0.7px;

  &:hover {
    a {
      color: ${(props) => props.theme.palette.primary.full} !important;
    } 
    > * {
      color: ${(props) => props.theme.palette.primary.full} !important;
    }
  }
`;

const StyledSubMenuItem = styled(SubMenu)`
  color: ${(props) => props.theme.widgets.color.full};

  background: linear-gradient(to left,
      ${(props) => props.theme.widgets.background.light} 35%,
      ${(props) => props.theme.widgets.background.dark} 80%
    )
    left bottom no-repeat;
  background-size: 100% 0.7px;

  &:hover {
    > a {
      color: ${(props) => props.theme.palette.primary.full} !important;
    }
`;

const SubNav = ({ navConfig, ...otherProps }) => {
  const [visible, setVisible] = useState(false);

  const createRoute = (config) => config.map((route, i) => {
    if (route.hidden) { return null; }
    if (route.isNested) {
      return (
        <StyledSubMenuItem key={route.title} icon={route.icon} title={route.title}>
          {route.subRoutes.map((subroute) => (
            <StyledMenuItem key={subroute.path}>
              <NavLink to={subroute.path}>
                {subroute.title}
              </NavLink>
            </StyledMenuItem>
          ))}
        </StyledSubMenuItem>
      );
    }
    return (
      <StyledMenuItem className="customclass" key={i} icon={route.icon}>
        <NavLink to={route.path}>
          {route.title}
        </NavLink>
      </StyledMenuItem>
    );
  });

  return (
    <div {...otherProps}>
      <Breakpoint small down>
        <Container>
          <AxilLogo href="/">
            <FluxLogo />
          </AxilLogo>
          <MenuButton
            type="button"
            onClick={() => setVisible(true)}
            aria-label="Menu"
            className="Menu"
          >
            <MenuIcon className="material-icons">menu</MenuIcon>
            <MenuTitle>MENU</MenuTitle>
          </MenuButton>
        </Container>
        <ThemedDrawer
          visible={visible}
          placement="left"
          onClose={() => setVisible(false)}
          closable={false}
        >
          <SubNavContainer mode="inline">
            {createRoute(navConfig)}
          </SubNavContainer>
        </ThemedDrawer>
      </Breakpoint>
      <Breakpoint small up>
        <SubNavContainer mode="inline">
          {createRoute(navConfig)}
        </SubNavContainer>
      </Breakpoint>
    </div>
  );
};

export default SubNav;
