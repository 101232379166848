const isProd = process.env.REACT_APP_BUILD_ENV === 'production';
const devPrefix = !isProd ? 'dev-' : '';
const localSuffix = process.env.REACT_APP_IS_LOCAL === 'true' ? '/local' : '';
const baseUrl = `https://${devPrefix}api.axil.ai${localSuffix}`;
const iamPermissionsUrl = `${baseUrl}/iam/groups`;

export class IAmRepository {
  static async checkIAmPermissions(token) {
    try {
      const res = await fetch(`${iamPermissionsUrl}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      const response = await res.json();
      return response;
    } catch (error) {
      console.log('error ', error);
      return error;
    }
  }
}
