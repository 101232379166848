export const transformAssetProps = ({
  orgId: org_id,
  siteId: site_id,
  assetId: asset_id,
  deviceId: device_id,
}) => ({
  org_id,
  site_id,
  asset_id,
  device_id,
});
