import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { DeleteFilled } from '@ant-design/icons';

const DisabledDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .15);
  z-index: 20;
`;

const EditDiv = ({ id }) => {
  const dispatch = useDispatch();
  const removeWidget = string => dispatch({ type: 'SET_REMOVE_ID', payload: string });

  return (
    <DisabledDiv>
      <div style={{ 'float': 'right', 'zIndex': 25, color: 'red', margin: '6px' }}>
        <DeleteFilled onClick={() => removeWidget(id)} />
      </div>    
    </DisabledDiv>
  );
}

export default EditDiv;
