import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import Breakpoint from 'react-socks';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'antd';

import { ReactComponent as Logo } from 'assets/images/FluxLogo.svg';
import { getSingleAssetDefaultRoute } from 'shared/helpers/altitudeHelpers';
import { StyledInput, StyledSelect } from '../styled';
import SideDrawer from './SideDrawer';
import useFetch from '../hooks/useFetch';
import { GlobalContext } from '../../GlobalContext';

const HeaderContainer = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.palette.neutral['neutral-0']};
  height: 50px;
  left: 0;
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.08), 0 2px 0 0 rgba(0, 0, 0, 0.02);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
`;

// TODO: remove logo and store in S3
const FluxLogo = styled(Logo)`
  height: 70%;
  width: 85px;

  g {
    fill: ${(props) => props.theme.widgets.color.full};

    path:first-child {
      fill: ${(props) => props.theme.palette.primary.full};
    }
  }
`;

const AxilLogo = styled.a`
  display: inline-block;
  float: left;
  margin-top: 10px;
  margin-left: 18px;
`;

const RightDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 24px;
`;

const Icon = styled.i`
  color: ${(props) => props.theme.widgets.color.full};
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 24px;
  cursor: pointer;
`;

const Header = () => {
  const history = useHistory();
  const location = useLocation();

  const { assetList, setAltGroupId } = useContext(GlobalContext);

  const defaultOption = { label: 'All Assets', value: -1 };
  const dispatch = useDispatch();

  const [text, setText] = useState('');
  const [editMenuOpen, toggleEditMenu] = useState(false);
  const [selectedAltitudeGroup, setSelectedAltitudeGroup] = useState(defaultOption);

  const [loading, altitudeFilterGroups, fetchFilterGroups] = useFetch('/v1/axil/altitude/filters');

  const roundedTheme = useSelector((state) => state.resources.themeStyles.round);
  const setGlobalFilterTags = (arr) => dispatch({ type: 'SET_FILTER_TAGS', payload: arr });

  const handleEnter = () => {
    const tagName = text.trim();
    const assetFilter = assetList.filter((asset) => {
      return asset.asset_name === tagName;
    });

    // If filter matches on asset name exactly
    // push to that assets page
    if (assetFilter.length === 1) {
      const [altitude] = assetFilter;
      const route = getSingleAssetDefaultRoute(altitude);

      return history.push(route);
    }

    // If filter does not match on an asset exactly
    // make it a filter tag and push to asset page
    setGlobalFilterTags([tagName]);
    return history.push('/asset');
  };

  const toggleMenu = () => toggleEditMenu(!editMenuOpen);

  const selectUnitGroup = (val) => {
    history.push('/dashboard');
    setSelectedAltitudeGroup(val);
    setAltGroupId([val?.value]);
  };

  useEffect(() => {
    fetchFilterGroups({});
  }, []);

  return (
    <>
      <Breakpoint medium up>
        <HeaderContainer>
          <AxilLogo href="/">
            <FluxLogo />
          </AxilLogo>
          <StyledInput
            placeholder="Advanced Search"
            onChange={(e) => setText(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleEnter()}
            style={{ margin: '12px 8px', width: '240px' }}
            size="small"
            rounded={roundedTheme}
          />
          {location.search.length > 0 && (
            <Button
              onClick={() => history.push({ search: null })}
              style={{ margin: 'auto' }}
              type="primary"
              ghost
            >
              Reset Date
            </Button>
          )}
          <RightDiv>
            <StyledSelect
              placeholder="Select Unit Group"
              options={loading ? [] : [defaultOption, ...altitudeFilterGroups]}
              onChange={selectUnitGroup}
              value={selectedAltitudeGroup?.label}
              style={{ width: '240px', marginTop: '8px', zIndex: '200' }}
            />
            <Icon className="material-icons" onClick={() => toggleMenu()}>
              menu
            </Icon>
          </RightDiv>
          <SideDrawer location={location} open={editMenuOpen} toggleEditMenu={toggleEditMenu} />
        </HeaderContainer>
      </Breakpoint>
    </>
  );
};

Header.propTypes = {};

export default Header;
