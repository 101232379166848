import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'react-highcharts';
import styled from 'styled-components';
import { Card } from 'antd';
import moment from 'moment'

const Container = styled(Card)`
  ${ props => `
     background: ${props.theme.widgets.background.full};
     border: ${props.bordered ? props.theme.widgets.border.border.full : 'none'};
     border-radius: ${props.rounded ? '12px' : 'none'};
     height: 100%;
     .ant-card-body {
       padding: 12px 24px;
     }
     
     .highcharts-yaxis-grid {
       .highcharts-grid-line {
         stroke: ${props.theme.widgets.border.color}
       }
     }
     
     .highcharts-xaxis {
       .highcharts-axis-line {
         stroke: ${props.theme.widgets.border.color}
       }
     }

     h2, h3 {
       color: ${props.theme.widgets.color.full}
     }
  `}
`;


const SeriesMockup = ({ props }) => {
  const { status } = props;

  const settings = status
    ? {
      min: 190,
      max: 210,
      plot1: 196,
      plot2: 204,
      proVal: 200
    }
    : {
      min: 140,
      max: 200,
      plot1: 186,
      plot2: 198,
      proVal: 192
    }

  const successSeries = [
    ...Array.from(Array(40), _ => Math.floor(Math.random() * (210 - 204 + 1) + 204)),
    ...Array.from(Array(260), _ => Math.floor(Math.random() * (settings.plot2 - settings.plot1 + 1) + settings.plot1)) 
  ];

  const failureSeries = [
    ...Array.from(Array(100), _ => Math.floor(Math.random() * (settings.plot2 - settings.plot1 + 1) + settings.plot1)),
    ...Array.from(Array(200), _ => Math.floor(Math.random() * (155 - 145 + 1) + 145)) 
  ];

  const data = arr => arr.reverse().map((y, i) => {
    const x = moment().subtract(i, 'minutes').utc().valueOf();
    return [x, y]
  }).reverse();

  const chartConfig = {
    chart: {
      height: 300,
    },
    title: {
      text: 'VRU Rate and Process Simulator Rate (mcfd)',
      align: 'left',
      x: 60,
    },
    plotOptions: {
      series: {
        gapSize: 10,
        connectNulls: false,
        compareStart: true,
        lineWidth: 1.5,
        turboThreshold: 0,
        states: {
          hover: { 
            lineWidthPlus: 1.5
          }
        },
      },
      line: { 
        marker: { enabled: false },
        connectNulls: false,
      },
    },
    xAxis: {
      type: 'datetime',
    },
    tooltip: {
      formatter: function() {
        return `${this?.point?.series?.name}: ${this.y}`
      },
    },
    yAxis: {
      max: settings.max,
      min: settings.min,
      labels: {
        format: '{value:.2f}',
        style: {
          fontSize: '0.9em',
          color: 'orange'
        }
      },
      plotLines: [
        {
          color: 'red',
          dashStyle: 'dash',
          value: settings.plot1,
          width: 2,
        },
        {
          color: 'red',
          dashStyle: 'dash',
          width: 2,
          value: settings.plot2,
        },
      ],
    },
    legend: false,
    credits: false,
    series: [
      {
        name: 'VRU Rate',
        data: status ? data(successSeries) : data(failureSeries), 
        color: 'orange'
      },
      {
        name: 'Promax Rate',
        data: data(Array.from(Array(300), () => (settings.proVal))),
        color: 'black'
      }
    ]
  };

  return (
    <Container bordered>
      <Highcharts config={chartConfig} />
    </Container>
  );
};

export default SeriesMockup;
