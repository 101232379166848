import { getUrl } from 'shared/helpers/repositoryHelpers';

export const repositoryFetch = ({
  accessToken,
  endpoint,
  body,
  method = 'POST',
  isAxil = false,
}) => {
  const baseUrl = getUrl(isAxil);

  return fetch(`${baseUrl}${endpoint}`, {
    method,
    type: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    ...(body && { body: JSON.stringify(body) }),
  });
};
