import React from 'react';

const ReportFrequencyComponent = ({ setCurrentFrequency }) => {
  const frequencyOptions = [
    { label: 'Monthly', value: 'Monthly' },
    { label: 'Weekly', value: 'Weekly' },
    { label: 'Daily', value: 'Daily' },
  ];

  const handleSelect = (e) => {
    setCurrentFrequency(e.currentTarget.value);
  };

  const displayedFrequencyOptions = [];

  frequencyOptions.length
    ? frequencyOptions.map((option) => {
        displayedFrequencyOptions.push(
          <option key={option.value} className="frequency" value={option.value}>
            {option.label}
          </option>,
        );
      })
    : [];

  const frequencyComponent = (
    <section className="report-section">
      <p className="displayed-options-title">Frequency</p>
      <select name="frequency" className="frequency-container" onChange={(e) => handleSelect(e)}>
        {displayedFrequencyOptions}
      </select>
    </section>
  );

  return frequencyComponent;
};

export default ReportFrequencyComponent;
