import React from "react";
import "antd/dist/antd.css";
import { Table, Card, DatePicker } from "antd";
import { SettingOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const successColumns = [
    {
      title: "Unit",
      dataIndex: "name",
      align: "center"
    },
    {
      title: "AVG Temp. (F)",
      key: "data",
      dataIndex: "AvgTempNumber",
      align: "center"
    },
    {
      title: "O2 Concent. (ppm)",
      key: "data",
      dataIndex: "O2ConNumber",
      align: "center"
    },
    {
      title: "AVG Press. (oz)",
      key: "data",
      dataIndex: "AvgPress",
      align: "center",
      render(text) {
        return {
          props: {
            style: {
              background: text > 8.0 ? "gold" : "white",
              color: text > 8.0 ? "white" : 'black'
            }
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: "Time above threshold",
      children: [
        {
          title: "Hours",
          key: "data",
          dataIndex: "Hours",
          align: "center"
        },
        {
          title: "Duration (%)",
          key: "data",
          dataIndex: "Dur",
          align: "center",
          render(text) {
            return {
              props: {
                style: {
                  background: text > 1.0 ? "gold" : text < 5.0 ? "green" : "white",
                  color: text > 1.0 ? "white" : text < 5.0 ? "white" : "black"
                }
              },
              children: <div>{text}</div>
            };
          }
        }
      ]
    }
  ];
  
  const successData = [
    {
      key: "1",
      name: "TB#1(24235)",
      AvgTempNumber: "-",
      O2ConNumber: "-",
      AvgPress: 8.42,
      Hours: 0.32,
      Dur: 1.33
    },
    {
      key: "2",
      name: "TB#2(24236)",
      AvgTempNumber: "-",
      O2ConNumber: "-",
      AvgPress: 7.75,
      Hours: 0,
      Dur: 0.0
    },
    {
      key: "3",
      name: "TB#3(24237)",
      AvgTempNumber: "-",
      O2ConNumber: "-",
      AvgPress: 7.02,
      Hours: 0,
      Dur: 0.0
    },
    {
      key: "4",
      name: "HT#1(64123)",
      AvgTempNumber: 145,
      O2ConNumber: "-",
      AvgPress: "-",
      Hours: 0,
      Dur: 0.0
    },
    {
      key: "5",
      name: "VRT(34213)",
      AvgTempNumber: "-",
      O2ConNumber: "-",
      AvgPress: 51,
      Hours: 0,
      Dur: 0.0
    },
    {
      key: "6",
      name: "O2(24236)",
      AvgTempNumber: "-",
      O2ConNumber: 0.75,
      AvgPress: "-",
      Hours: 0,
      Dur: 0.0
    }
  ];

  const failedColumns = [
    {
      title: "Unit",
      dataIndex: "name",
      align: "center"
    },
    {
      title: "AVG Temp. (F)",
      key: "data",
      dataIndex: "AvgTempNumber",
      align: "center"
    },
    {
      title: "O2 Concent. (ppm)",
      key: "data",
      dataIndex: "O2ConNumber",
      align: "center",
      render(text) {
        return {
          props: {
            style: {
              background: text > 5.0 ? "red" : text < 5.0 ? "green" : "white",
              color: text > 5.0 ? "white" : text < 5.0 ? "white" : "black"
            }
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: "AVG Press. (oz)",
      key: "data",
      dataIndex: "AvgPress",
      align: "center",
      render(text) {
        return {
          props: {
            style: {
              background: text > 5.0 ? "red" : text < 5.0 ? "green" : "white",
              color: text > 5.0 ? "white" : text < 5.0 ? "white" : "black"
            }
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: "Time above threshold",
      children: [
        {
          title: "Hours",
          key: "data",
          dataIndex: "Hours",
          align: "center"
        },
        {
          title: "Duration (%)",
          key: "data",
          dataIndex: "Dur",
          align: "center",
          render(text) {
            return {
              props: {
                style: {
                  background: text > 5.0 ? "red" : text < 5.0 ? "green" : "white",
                  color: text > 5.0 ? "white" : text < 5.0 ? "white" : "black"
                }
              },
              children: <div>{text}</div>
            };
          }
        }
      ]
    }
  ];
  
  const failedData = [
    {
      key: "1",
      name: "TB#1(24235)",
      AvgTempNumber: "-",
      O2ConNumber: "-",
      AvgPress: 13.12,
      Hours: 5.92,
      Dur: 24.62
    },
    {
      key: "2",
      name: "TB#2(24236)",
      AvgTempNumber: "-",
      O2ConNumber: "-",
      AvgPress: 12.05,
      Hours: 4.24,
      Dur: 17.67
    },
    {
      key: "3",
      name: "TB#3(24237)",
      AvgTempNumber: "-",
      O2ConNumber: "-",
      AvgPress: 6.04,
      Hours: 0,
      Dur: 0
    },
    {
      key: "4",
      name: "HT#1(64123)",
      AvgTempNumber: 150,
      O2ConNumber: "-",
      AvgPress: "-",
      Hours: 0,
      Dur: 0
    },
    {
      key: "5",
      name: "VRT(34213)",
      AvgTempNumber: "-",
      O2ConNumber: "-",
      AvgPress: 57,
      Hours: 0,
      Dur: 0
    },
    {
      key: "6",
      name: "O2(24236)",
      AvgTempNumber: "-",
      O2ConNumber: 6.25,
      AvgPress: "-",
      Hours: 4.23,
      Dur: 17.63
    }
  ];

export default function ConfirmingIndicatorsPassed({props}) {
  const { status } = props;
  return (
      <Card 
        title='Confirming Indicators' 
        extra={[
        <RangePicker style={{margin: 10}} />,
        <SettingOutlined />
      ]}
      >
        <Table 
          columns={status ? successColumns : failedColumns}
          dataSource={status ? successData : failedData}
          bordered
          pagination={{hideOnSinglePage: true}}
        />
      </Card>
  );
};
