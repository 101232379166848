import { useAuth0 } from '@auth0/auth0-react';
import React, { ReactElement, useEffect, useState } from 'react';
import { BeatLoader } from 'react-spinners';
import { UrsaLeoIFrame, WrapperDiv } from './styles';

const DigitalTwin = ({ assetName }: { assetName: string | null }): ReactElement => {
  const { user } = useAuth0();
  const [jwt, setJWT] = useState<string | null>(null);

  // I'm sorry to do this
  // PROD
  const ursaIframeURL = 'https://flogistix.services.ursaleo.com'
  // DEV
  // const ursaIframeURL = 'https://flogistix-dev.services.ursaleo.com'


  useEffect(() => {
    (async () => {
      const ursaleoUrl = 'https://ursaleo.axil.ai';

      // const ursaleoUrl =
      //   process.env.REACT_APP_BUILD_ENV !== 'production'
      //     ? 'https://ursaleo.axildev-flaxil.com'
      //     : 'https://ursaleo.axil.ai';

      const fetchJWT = await fetch(`${ursaleoUrl}/generateJWT`, {
        method: 'POST',
      });

      setJWT(await fetchJWT.json());
    })();
  }, []);

  return (
    <WrapperDiv>
      {jwt && assetName ? (
        <UrsaLeoIFrame
          data-testid="ursaLeoIFrame"
          // eslint-disable-next-line max-len
          src={`${ursaIframeURL}/gemini/index.html?USER_EMAIL=${user?.email}&ASSET_ID=${assetName}&t=${jwt}`}
        />
      ) : (
        <BeatLoader color="#ff6600" />
      )}
    </WrapperDiv>
  );
};

export default DigitalTwin;
