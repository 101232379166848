import React, {
  useState,
  useEffect,
  useContext,
  ReactElement,
  CSSProperties,
  ChangeEvent,
} from 'react';
import { Card } from 'antd';
import { AutoSizer, List } from 'react-virtualized';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { GlobalContext } from '../../../GlobalContext';
import { StyledCheckbox, StyledInput } from '../../styled';
import { useListFilter } from '../../hooks/useFilter';
import ListRow from './ListRow';
import 'react-virtualized/styles.css';
import { AltitudeMatchParams, Asset } from '../../../global.interfaces';
import { getStatusType, StatusTypes } from './statusTypeUtils';

const CheckboxesDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StatusFilterCheckbox = styled(StyledCheckbox)`
  font-size: 12px;

  &.ant-checkbox-wrapper {
    margin-left: 0;
  }
`;

const Container = styled(Card)<{ rounded: boolean }>`
  ${(props) => `
    position: relative;
    box-shadow: ${props.theme.widgets.border.boxShadow};
    border: ${props.theme.widgets.border.border.full};
    box-sizing: border-box;
    border-radius: ${props.rounded ? '12px' : '0px'};
    background: ${props.theme.widgets.background.full};
    color: ${props.theme.widgets.color.full};
    height: 100%;
    overflow: hidden;

    ::-webkit-scrollbar {
      display: none
    }
    && span {
      color: ${props.theme.widgets.color.full}
    }

    && h4 {
      color: ${props.theme.widgets.color.full}
    }
  `}
`;

const statusFilterOptions = [
  { label: 'All Units', value: 'all' },
  { label: 'Running', value: 'running' },
  { label: 'Review', value: 'review' },
];

const WidgetAssetList = ({ roundedTheme = false }: { roundedTheme: boolean }): ReactElement => {
  const { params } = useRouteMatch<AltitudeMatchParams>();
  const [data, setData] = useState<Asset[]>([]);
  const [statusFilter, setStatusFilter] = useState<string>('all');
  const filters = ['asset_name', 'model', 'org_name', 'site_name', 'status'] as Array<keyof Asset>;
  const { filteredList, setFilter } = useListFilter(data, filters);
  // TODO: This component uses four different values for essentially the same data array
  // There's probably a cleaner way to handle this
  const [statusFilteredList, setStatusFilteredList] = useState<Asset[]>(filteredList);
  const { assetList } = useContext(GlobalContext);
  const itemHeight = 150;

  useEffect(() => {
    setStatusFilteredList(
      filteredList.filter((asset) => {
        switch (statusFilter) {
          case 'review':
            return getStatusType(asset.status) !== StatusTypes.OK;
          case 'running':
            return getStatusType(asset.status) === StatusTypes.OK;
          default:
            return true;
        }
      }),
    );
  }, [filteredList, statusFilter]);

  useEffect(() => {
    const isValidAsset = (asset: Asset, idType?: 'site_id' | 'org_id') =>
      asset.invalidated_at === null &&
      (!idType || asset[idType] === parseInt(params[idType] || ''));

    if (assetList.length > 0) {
      if (params.site_id) {
        setData(assetList.filter((e) => isValidAsset(e, 'site_id')));
      } else if (params.org_id) {
        setData(assetList.filter((e) => isValidAsset(e, 'org_id')));
      } else {
        setData(assetList.filter((e) => isValidAsset(e)));
      }
    }
  }, [assetList, params]);

  const rowRenderer = ({
    key,
    index,
    style = {},
  }: {
    key: string;
    index: number;
    style?: CSSProperties;
  }) => (
    <ListRow key={key} item={statusFilteredList[index]} itemHeight={itemHeight} style={style} />
  );

  return (
    <Container
      bodyStyle={{ height: '90%' }}
      loading={data.length === 0}
      rounded={roundedTheme}
      title={
        <div>
          <h4>Asset List</h4>
          <StyledInput
            placeholder="Search by organization, site name, or unit number"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setFilter(e.target.value)}
          />
          <CheckboxesDiv>
            {statusFilterOptions.map((option) => (
              <StatusFilterCheckbox
                checked={statusFilter === option.value}
                onChange={() => {
                  setStatusFilter(option.value);
                }}
              >
                {option.label}
              </StatusFilterCheckbox>
            ))}
          </CheckboxesDiv>
        </div>
      }
    >
      <AutoSizer>
        {({ height, width }) => (
          <List
            height={height}
            width={width}
            rowCount={statusFilteredList.length}
            rowRenderer={rowRenderer}
            rowHeight={itemHeight}
          />
        )}
      </AutoSizer>
    </Container>
  );
};

export default WidgetAssetList;
