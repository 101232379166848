import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GlobalContext } from '../../GlobalContext';
import AltitudeSelect from './AltitudeSelect';
import WidgetSeriesBuilder from './WidgetSeriesBuilder';
import WidgetCardBuilder from './WidgetCardBuilder';

const builders = {
  AltitudeSelect,
  WidgetSeriesBuilder,
  WidgetCardBuilder
};

const BuilderModal = ({
  match,
  dashboardId,
  layoutObject,
  activeLayout,
  setActiveLayout
}) => {
  const [org, setOrg]                   = useState({});
  const [site, setSite]                 = useState({});
  const [asset, setAsset]               = useState({});
  const [builder, setBuilder]           = useState('AltitudeSelect');
  const [templateItem, setTemplateItem] = useState(false);

  const { assetTypeId, assetList, altitudeInfo } = useContext(GlobalContext); 

  const dispatch = useDispatch();
  const setWidgetToAdd  = (widget) => dispatch({ type: 'SET_WIDGET_TO_ADD', payload: widget });
  const selectedBuilder = useSelector(state => state.layouts.adding.current_widget_to_add.builder);

  // Renders which builder is selected.
  const Component = builders[builder]

  useEffect(() => { if (!selectedBuilder) { setBuilder('AltitudeSelect'); } }, [selectedBuilder]);

  // Modal open prop is controlled by whether or not a "Builder" is selected.
  // when the builder key is set to null, the modal is closed.
  const closeModal = () => {
    setTemplateItem(false);
    setWidgetToAdd({ widget: null, builder: null, name: null, icon: null });
  }

  return (
    <Component
      open={selectedBuilder}
      closeModal={closeModal}
      match={match}
      setBuilder={setBuilder}
      selectedBuilder={selectedBuilder}
      altitudeInfo={altitudeInfo}
      dashboardId={dashboardId}
      org={org}
      site={site}
      asset={asset}
      setOrg={setOrg}
      setSite={setSite}
      setAsset={setAsset}
      layoutObject={layoutObject}
      assetTypeId={assetTypeId}
      activeLayout={activeLayout}
      templateItem={templateItem}
      setTemplateItem={setTemplateItem}
      assetList={assetList}
      setActiveLayout={setActiveLayout}
    />
  );
};

export default BuilderModal;
