import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useAppSelector } from '../../store/hooks';
import { Value } from './styles';
import { StatusTypes } from '../widgets/WidgetAssetList/statusTypeUtils';
import { Asset } from '../../global.interfaces';

export default function useColumns(filteredList: Asset[]): ColumnsType<Asset> {
  const { demo: demoMode, theme } = useAppSelector((state) => state.resources);

  const defineFilters = (key: 'driver', list: Asset[]) => {
    const distinct = list.reduce(
      (acc, val) =>
        val[key]
          ? {
              ...acc,
              [val[key]]: {},
            }
          : acc,
      {},
    );

    return Object.keys(distinct).map((f) => {
      return { text: f, value: f };
    });
  };

  const setRTPColor = (rtp: number) => StatusTypes[rtp >= 98 ? 'OK' : 'ERROR'].color;

  const setStatusColor = (status: string) => {
    switch (status) {
      case 'Running': {
        return StatusTypes.OK.color;
      }
      case 'Faulted':
      case 'Off': {
        return StatusTypes.ERROR.color;
      }
      default:
        return theme.palette.primary.full;
    }
  };

  return [
    {
      title: 'Customer',
      width: '15%',
      dataIndex: demoMode.demo ? '' : 'org_name',
      key: 'org_name',
    },
    {
      title: 'Location',
      width: '20%',
      dataIndex: demoMode.demo ? '' : 'site_name',
      key: 'site_name',
      filters: [{ text: 'Show Active Assets', value: 'invalidated_at' }],
      onFilter: (filterIndex, filterObj) => !filterObj[filterIndex as keyof Asset],
      defaultFilteredValue: ['invalidated_at'],
      sorter: (a, b) => a.site_name.localeCompare(b.site_name),
    },
    {
      title: 'Asset',
      dataIndex: 'asset_name',
      key: 'asset_name',
      sorter: (a, b) => a.asset_name.localeCompare(b.asset_name),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (props) => <Value color={setStatusColor(props)}> {props} </Value>,
      filters: [
        { text: 'Running', value: 'Running' },
        { text: 'Faulted', value: 'Faulted' },
        { text: 'Alarm', value: 'Alarm' },
        { text: 'Off', value: 'Off' },
      ],
      onFilter: (filterIndex, filterObj) => filterObj.status === filterIndex,
    },
    {
      title: 'Run Time',
      dataIndex: 'runtime',
      key: 'runtime',
      render: (props) => <Value color={setRTPColor(props)}> {props}% </Value>,
      sorter: (a, b) => a.runtime - b.runtime,
    },
    {
      title: 'Driver',
      dataIndex: 'driver',
      key: 'driver',
      filters: defineFilters('driver', filteredList),
      onFilter: (filterIndex, filterObj) => filterObj.driver === filterIndex,
    },
    {
      title: 'Last Synced',
      dataIndex: 'last_synced',
      key: 'last_synced',
      render: (timestamp) => (
        <span>{timestamp ? moment(timestamp).fromNow() : ' Asset is not synced '}</span>
      ),
    },
  ];
}
