import styled from 'styled-components';
import { Table } from 'antd';

const StyledTable = styled(Table)`
  ${(props) => `
    border-radius: 12px;
    overflow: hidden;
    .ant-table {
      background: ${props.theme.widgets.background.full};
      color: ${props.theme.widgets.color.full};
    }

    && .ant-table-title {
      && h1, h2, h3, h4, h5, h6 {
        color: ${props.theme.widgets.color.full} !important;
      }
    }

    && tr.ant-table-row.ant-table-row-level-0.default:hover {
      background: ${props.theme.widgets.background.light};
    }
    .ant-table-cell {
      background: ${props.theme.widgets.background.full};
    }

    && .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
      background: unset;
    }

    && th {
      background: ${props.theme.widgets.background.dark};
      color: ${props.theme.widgets.color.full};
      border-bottom: ${props.theme.widgets.border.border.full};
    }

    && td {
      border-bottom: ${props.theme.widgets.border.border.full};
    }

    .ant-pagination-item-link {
      background: ${props.theme.widgets.background.light};
      color: ${props.theme.widgets.color.full};
    }

    & button {
      background: ${props.theme.widgets.background.light};
      border: ${props.theme.widgets.border.border.full};
    }

    & a {
      background: ${props.theme.widgets.background.light};
      color: ${props.theme.widgets.color.full};  
    }

    && .ant-select-selector, .ant-select-item, .ant-select-dropdown {
      background: ${props.theme.widgets.background.light};
      color: ${props.theme.widgets.color.full};
    }
  `}
`;

export default StyledTable;
