export const flattenLegacyLayoutResponse = ({
  lg: [
    {
      children:
    [{
      children: statsConfig,
    }, {
      children: chartsConfig,
    }, {
      children: tablesConfig,
    }],
    }],
}) => ({
  statsConfig,
  chartsConfig,
  tablesConfig,
});
