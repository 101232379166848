import React, { useEffect, useState } from 'react';
import {
  Form,
} from 'antd';

import {
  WELL_FIELD_MIN_CHARS,
  WELL_DESCRIPTION_FIELD_MAX_CHARS,
  WELL_NAME_FIELD_MAX_CHARS,
  FORM_MODE_EDIT,
  FORM_MODE_READ_ONLY,
  defaultWellPropValue,
} from 'single-asset/constants/gasLiftConstants';
import { createFieldLengthValidator, calculateFormFieldStatusMap } from 'single-asset/helpers/gasLiftHelpers';
import WellInformationHeader from 'single-asset/components/WellInformationEditHeader';
import WellInformationEditFields from 'single-asset/components/WellInformationEditFields';
import WellInformationEditFooter from 'single-asset/components/WellInformationEditFooter';

import './WellInformationEdit.scss';

const WellInformationEdit = ({
  well = defaultWellPropValue,
  updateWell,
  onCancelEdit,
  needsConfiguration,
}) => {
  const defaultMode = needsConfiguration ? FORM_MODE_EDIT : FORM_MODE_READ_ONLY;
  const [fieldStatusMap, setFieldStatusMap] = useState(calculateFormFieldStatusMap(['title', 'description'], well));
  const [mode, setMode] = useState(defaultMode);
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [form] = Form.useForm();

  const conditionallySetToReadOnlyMode = () => (needsConfiguration
    ? onCancelEdit()
    : setMode(FORM_MODE_READ_ONLY)
  );

  const resetForm = () => {
    form.resetFields();
    setIsFormDisabled(true);
    conditionallySetToReadOnlyMode();
  };

  const setToEditMode = () => setMode(FORM_MODE_EDIT);

  const handleValidationSuccess = ({ field }) => {
    setFieldStatusMap({
      ...fieldStatusMap,
      [field]: true,
    });

    return Promise.resolve();
  };

  const handleValidationFailed = ({ field }, value, min, max) => {
    setFieldStatusMap({
      ...fieldStatusMap,
      [field]: false,
    });

    const errorMessage = !value.length
      ? 'required field'
      : `max ${max} characters`;

    return Promise.reject(errorMessage);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formFields = form.getFieldsValue();

    updateWell({
      ...formFields,
      wellId: well?.wellId,
    });
  };

  useEffect(() => {
    !needsConfiguration && setFieldStatusMap(calculateFormFieldStatusMap(['title', 'description'], well));
  }, [well, mode]);

  useEffect(() => {
    setIsFormDisabled(
      Object.values(fieldStatusMap).some((value) => value === false),
    );
  }, [fieldStatusMap]);

  return (
    <Form
      className="well-information-edit view-well-info"
      form={form}
      name="wellNameForm"
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 14,
      }}
    >
      <WellInformationHeader
        onEditClick={setToEditMode}
        mode={mode}
      />
      <WellInformationEditFields
        mode={mode}
        well={well}
        wellNameValidator={createFieldLengthValidator(WELL_FIELD_MIN_CHARS, WELL_NAME_FIELD_MAX_CHARS, handleValidationSuccess, handleValidationFailed)}
        wellDescriptionValidator={createFieldLengthValidator(WELL_FIELD_MIN_CHARS, WELL_DESCRIPTION_FIELD_MAX_CHARS, handleValidationSuccess, handleValidationFailed)}
      />
      {mode === FORM_MODE_EDIT && (
      <WellInformationEditFooter
        onCancel={resetForm}
        canSubmit={!isFormDisabled}
        onSubmit={handleSubmit}
      />
      )}
    </Form>
  );
};

export default WellInformationEdit;
