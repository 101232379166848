/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RGL, { WidthProvider } from 'react-grid-layout';
import { useSelector, useDispatch } from 'react-redux';
import { GlobalContext } from '../../GlobalContext'
import useFetch from '../hooks/useFetch';
import { findChildAndReplace } from '../../utils/helpers'

const ResponsiveReactGridLayout = WidthProvider(RGL);

// Doing this so that the resizabled handle is above our DisabledDiv
const StyledResponsiveReactGridLayout = styled(ResponsiveReactGridLayout)`
  && {
    background: ${props => props.backgroundColor};
    overflow: visible;
    .react-grid-item > .react-resizable-handle {
      z-index: 100;
    }
    .react-grid-item > .react-resizable-handle::after {
      border-right: 2px solid ${props => props.theme.widgets.color.full};
      border-bottom: 2px solid ${props => props.theme.widgets.color.full};
    }
  }
`;

const WidgetLayout = ({
  id,
  save,
  size,
  props,
  cancel,
  setSave,
  template,
  location,
  children,
  setCancel,
  assetTypeId,
  dashboardId,
  layoutObject,
  activeLayout,
  setActiveLayout,
  match: {
    params
  },
}) => {
  const { backgroundColor } = props;
  const [layoutChildren, setLayoutChildren] = useState(
    children.map(child => ({ i: child.key, ...child.props['data-grid']}))
  );

  const [childComps, updateChildComps] = useState(children);
  const [resetChildren, setResetChildren ] = useState([]);
  const [color, setColor] = useState(backgroundColor);

  const { hoverValue } = useContext(GlobalContext);

  const [_, __, saveLayout] = useFetch('/v1/axil/layouts/save');

  const editableLayout = useSelector(state => state.layouts.editing.editable_layout);
  const removeWidgetId = useSelector(state => state.layouts.removal.remove_id);

  const dispatch          = useDispatch();
  const removeWidget      = string => dispatch({ type: 'SET_REMOVE_ID', payload: string });
  const setEditableLayout = layoutId => dispatch({ type: 'SET_EDITABLE_LAYOUT', payload: layoutId });

  const editMode  = (editableLayout === id);

  // Trigger from Redux to call removeWidget function.
  useEffect(() => {
    if (editMode && removeWidgetId) {
      const newChildren = childComps.filter(c => c.key !== removeWidgetId);
      const newLayout = layoutChildren.filter(ul => ul.i !== removeWidgetId);

      setLayoutChildren(newLayout);
      updateChildComps(newChildren)
      removeWidget(null);
    }
  }, [removeWidgetId]);

  useEffect(() => {
    if (editMode) {
      if (save) {
        const newLayout = findChildAndReplace(activeLayout, layoutChildren, id);
        const saveParams = { ...params, dashboard_id: dashboardId, asset_type_id: assetTypeId };
        const templateParams = { ...saveParams, org_id: -1, site_id: -1, asset_id: -1, device_id: -1 }; 

        setActiveLayout(newLayout)
        setEditableLayout('');
        setResetChildren([]);
        setSave(false);

        if (template) {
          saveLayout({ ...templateParams, layout: { ...layoutObject, [size]: newLayout } });
        } else {
          saveLayout({ ...saveParams, layout: { ...layoutObject, [size]: newLayout } });
        }
      }
      if (cancel) {
        setLayoutChildren(activeLayout)
        updateChildComps(resetChildren);
        setEditableLayout('');
        setCancel(false);
      }
    }
  }, [save, cancel]);

  // updates grid layout on change of children
  useEffect(() => {
    if (!editMode) {
      setLayoutChildren(children.map(child => ({ i: child.key, ...child.props['data-grid']})));
      updateChildComps(children);
    }
  }, [children])

  // When entering edit mode:
  // Create a list of children to reset to if canceled
  useEffect(() => { if (editMode) { setResetChildren(childComps); }}, [editMode]);

  useEffect(() => { 
    if (hoverValue === id) setColor('#F75555');
    else setColor(backgroundColor);
  }, [hoverValue]);

  return (
    <>
      <StyledResponsiveReactGridLayout
        cols={12}
        rowHeight={48}
        className="layout"
        layout={layoutChildren}
        isResizable={editMode}
        isDraggable={editMode}
        onDragStop={setLayoutChildren}
        onResizeStop={setLayoutChildren}
        backgroundColor={color}
      >
        { childComps.map(child => React.cloneElement(child, {
          id,
          size,
          props,
          params,
          location,
          dashboardId,
          assetTypeId,
        }))}
      </StyledResponsiveReactGridLayout>
    </>
  )
};

WidgetLayout.defaultProps = {
  backgroundColor: ''
}

WidgetLayout.propTypes = {
  id: PropTypes.string.isRequired,
  save: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
  props: PropTypes.object.isRequired,
  cancel: PropTypes.bool.isRequired,
  setSave: PropTypes.func.isRequired,
  template: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  setCancel: PropTypes.func.isRequired,
  assetTypeId: PropTypes.number.isRequired,
  dashboardId: PropTypes.number.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      org_id: PropTypes.string,
      site_id: PropTypes.string,
      asset_id: PropTypes.string,
      device_id: PropTypes.string,
    })
  }).isRequired,
  backgroundColor: PropTypes.string
};

export default WidgetLayout;
