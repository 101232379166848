import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Switch, Route } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import dotenv from 'dotenv';

import { Auth0Provider } from '@auth0/auth0-react';
import authConfig from './components/Auth/authConfig';
import App from './App';
import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/video-react/dist/video-react.css';
import '../node_modules/antd/dist/antd.css';

import createStore from './store';

const store = createStore();
const history = createBrowserHistory();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const { domain, clientID, audience, scope } = authConfig;

history.listen((location) => {
  const { pathname, search, hash } = location;
  (window as any).gtag('event', 'page_view', {
    page_partial_path: pathname,
    page_path: `${pathname}${search}${hash}`,
    page_search: search,
    page_hash: hash,
  });
});

ReactDOM.render(
  <ReduxProvider store={store}>
    <Router history={history}>
      <Auth0Provider
        domain={domain}
        clientId={clientID}
        redirectUri={window.location.origin}
        scope={scope}
        audience={audience}
        useRefreshTokens
        cacheLocation="localstorage"
      >
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </Auth0Provider>
    </Router>
  </ReduxProvider>,
  document.getElementById('root'),
);
