import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import EditDiv from '../Editing/EditDiv';

const Card = styled.div`
  ${(props) => `
    background: ${props.theme.widgets.background.full};
    border: ${props.border ? props.theme.widgets.border.border : 'none'};
    box-sizing: border-box;
    border-radius: ${props.rounded ? '12px' : '0px'};
    min-width: 20%;
    height: 100%;
    box-shadow: 0 2px 6px 1px rgba(0,0,0,.08), 0 2px 0 0 rgba(0,0,0,.02);
    position: relative;
  `}
`;

const CardHeader = styled.div`
  background: #d7dbde;
  padding: 5px 5px 5px 18px;
  color: ${(props) => props.theme.widgets.color.contrast};
  min-width: 97;
  font-size: 20px;
  border-radius: ${(props) => (props.rounded ? '10px 10px 0px 0px' : '0px')};
`;

const CardBody = styled.div`
  margin: 18px;
  color: ${(props) => props.theme.widgets.color.full};
`;

const CardStat = styled.span`
  float: right;
`;

const WidgetScoreboard = ({ id, parentId, props }) => {
  const { stats, title, bordered = true } = props;
  const editableLayout = useSelector((state) => state.layouts.editing.editable_layout);
  const roundedTheme = useSelector((state) => state.resources.themeStyles.round);

  const editMode = editableLayout === parentId;

  return (
    <>
      {editMode && <EditDiv id={id} />}
      <Card bordered={bordered} rounded={roundedTheme}>
        <CardHeader rounded={roundedTheme}>{title}</CardHeader>
        <CardBody>
          {stats.map((stat, index) => {
            return (
              <div key={index}>
                <span>{stat.display_name}</span>
                <CardStat>{stat.stat}</CardStat>
                {index !== stats.length - 1 ? <hr /> : <></>}
              </div>
            );
          })}
        </CardBody>
      </Card>
    </>
  );
};

export default WidgetScoreboard;
