
// Finds the layout that has been updated and overwrites layout with new children
export const findChildAndReplace = (layout, updates, compare) => {
  return layout.map(item => {
    const { children = [] } = item; 
    if (compare === item.i) {
      return {
        ...item,
        children: updates.map(child => children.reduce((acc, val) => {
          if (child.i === val.i) {
            return ({ ...val, ...child});
          }
          return acc;
        }, {}))
      }
    }
    return { ...item, children: findChildAndReplace(children, updates, compare) }
  })
};

export const findChildAndRemove = (layout, list, parentId) => {
  return layout.map(item => {
    const { children = [] } = item; 
    if (parentId === item.i) {
      return {
        ...item,
        h: !children.filter(x => x.i !== parentId).length ? 0 : item.h,
        children: children.filter(child => !list.includes(child.i))
      }
    }
    return { ...item, children: findChildAndRemove(children, list, parentId)}
  })
}
