import React from 'react';
import { Button, Tooltip } from 'antd';
import { InfoCircleOutlined, CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';
import { StyledCheckbox } from '../styled'; 

const SaveLayoutPopup = styled.div`
  ${ props => `
      position: fixed;
      top: 0%;
      right: 50%;
      width: 350px;
      background: ${props.theme.widgets.background.light};
      border: ${props.theme.widgets.border.border.light};
      align-items: center;
      z-index: 100000;
      margin-right: -175px;
  `}
`;

const ButtonDiv = styled.div`
  display: flex;
`;

const CheckDiv = styled.div`
  margin: 4px 8px;
`;

const ThemedIcon = styled(InfoCircleOutlined)`
  color: ${props => props.theme.widgets.color.full};
`;

const EditConfirm = ({
  params,
  setSave,
  setCancel,
  setTemplate
}) => {

  return (
    <SaveLayoutPopup>
      <ButtonDiv>
        <Button
          style={{ margin: '8px' }}
          onClick={() => setCancel(true)}
          block
          danger
          ghost
        >
          <CloseCircleFilled />
          Cancel Changes
        </Button>
        <Button
          onClick={() => setSave(true)}
          style={{ margin: '8px' }}
          block
          type='primary'
          ghost
        >
          <CheckCircleFilled />
          Save Changes
        </Button>
      </ButtonDiv>
      { params.org_id && (
        <CheckDiv>
          <StyledCheckbox onClick={e => setTemplate(e.target.checked)}>
            Apply changes to template
          </StyledCheckbox>
          <Tooltip
            title='Checking this will apply the current changes to dashboards of the same type.'
            placement='bottomRight'
          >
            <ThemedIcon />
          </Tooltip>
        </CheckDiv>
      )}
    </SaveLayoutPopup>
  );
};


export default EditConfirm;
