import { Reducer } from 'redux';
import {
  FrameInitialState,
  FrameWidget,
  DispatchFunc
} from '../../store/store.interfaces';

const widgetList: FrameWidget[] = [
  {
    name: 'Time Series Chart',
    widget: 'WidgetSeries',
    builder: 'WidgetSeriesBuilder',
    icon: 'show_chart',
  },
  {
    name: 'Add A Card',
    widget: 'WidgetBasicCard',
    builder: 'WidgetCardBuilder',
    icon: 'show_chart',
  },
];

const initialState: FrameInitialState = {
  widget_list: widgetList,
  reset_layout_to_default: false,
  reset_templates: false,
  current_layout: [],
  selected_layout: null,
  altitude_group_id: [],
};

const SET_SIDE_DRAWER_LIST = 'SET_SIDE_DRAWER_LIST';
const SET_ALTITUDE_GROUP = 'SET_ALTITUDE_GROUP';
const SET_CURRENT_LAYOUT = 'SET_CURRENT_LAYOUT';
const SET_SELECTED_LAYOUT = 'SET_SELECTED_LAYOUT';
const RESET_LAYOUT_TO_DEFAULT = 'RESET_LAYOUT_TO_DEFAULT';
const RESET_TEMPLATES = 'RESET_TEMPLATES';

export const frameReducer: Reducer<FrameInitialState> = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SIDE_DRAWER_LIST:
      return state;
    case SET_CURRENT_LAYOUT:
      return {
        ...state,
        current_layout: payload
      };
    case RESET_TEMPLATES:
      return {
        ...state,
        reset_templates: payload
      }
    case SET_SELECTED_LAYOUT:
      return {
        ...state,
        selected_layout: payload,
      };
    case RESET_LAYOUT_TO_DEFAULT:
      return {
        ...state,
        reset_layout_to_default: payload
      };
    case SET_ALTITUDE_GROUP:
      return {
        ...state,
        altitude_group_id: [payload]
      }
    default:
      return state;
  }
};

export const setSideDrawerList: DispatchFunc<any[]> = arr => ({ type: SET_SIDE_DRAWER_LIST, payload: arr });
export const setSelectedLayout: DispatchFunc<any> = obj => ({ type: SET_SELECTED_LAYOUT, payload: obj });
export const setCurrentLayout: DispatchFunc<any[]> = arr => ({ type: SET_CURRENT_LAYOUT, payload: arr });
export const setAltitudeGroup: DispatchFunc<number> = number => ({ type: SET_ALTITUDE_GROUP, payload: number });