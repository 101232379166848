import React from 'react';

const ScheduledReportsComponent = ({
  setShowScheduledReport,
  showScheduledReport,
  scheduledReports,
  setDisplayedScheduledReport,
  setNameOfReportToDelete,
  setShowConfirmation,
  showConfirmation,
}) => {
  const displayedScheduledReports = [];

  if (scheduledReports && scheduledReports.length) {
    scheduledReports.map((report) => {
      const handleClick = () => {
        setDisplayedScheduledReport(report);
        return setShowScheduledReport(!showScheduledReport);
      };

      const handleDelete = () => {
        setNameOfReportToDelete(report.userReportName);
        return setShowConfirmation(!showConfirmation);
      };

      displayedScheduledReports.push(
        <div key={report.userReportName} className="displayed-report">
          <p className="displayed-report-name">{report.userReportName}</p>
          <section className="displayed-report-icons-container">
            <div
              onClick={() => handleClick()}
              className="displayed-report-icon displayed-report-info-icon"
            ></div>
            <div
              onClick={() => handleDelete()}
              className="displayed-report-icon displayed-report-delete-icon"
            ></div>
          </section>
        </div>,
      );
    });
  }

  return (
    <>
      <section className="scheduled-reports-container">
        <header className="scheduled-reports-header">
          <h3 className="scheduled-reports-header-text">Your Scheduled Reports</h3>
        </header>
        <div className="scheduled-reports-list">{displayedScheduledReports}</div>
      </section>
    </>
  );
};

export default ScheduledReportsComponent;
