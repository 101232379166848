import { wellIdList } from 'single-asset/constants/gasLiftConstants';

export const well1InjectionConfig = {
  wellId: wellIdList.at(0),
  wellName: 'Well1',
  name: 'Gas Injection Rate',
  tagList: [{
    agg: { scale: 86400000, mean: ['y'] },
    label: 'Well-1 - Gas Injection Rate',
    map: { epoch_ms: 'x', uom: 'uom', val: 'y' },
    tag_id: 1820,
    stroke: '#FF6600',
  }],
};

export const well1PressureConfig = {
  wellId: wellIdList.at(0),
  name: 'Sales Line Pressure, Surface Casing Pressure, Tubing Pressure',
  tagList: [{
    agg: { scale: 86400000, mean: ['y'] },
    label: 'Sales Line Pressure',
    map: { epoch_ms: 'x', uom: 'uom', val: 'y' },
    tag_id: 1823,
    stroke: '#6200EA',
  },
  {
    agg: { scale: 86400000, mean: ['y'] },
    label: 'Surface Casing Pressure',
    map: { epoch_ms: 'x', uom: 'uom', val: 'y' },
    tag_id: 1803,
    stroke: '#4CAF50',
  },
  {
    agg: { scale: 86400000, mean: ['y'] },
    label: 'Tubing Pressure',
    map: { epoch_ms: 'x', uom: 'uom', val: 'y' },
    tag_id: 1802,
    stroke: '#FFB300',
  }],
};

export const well2InjectionConfig = {
  wellId: wellIdList.at(1),
  wellName: 'Well2',
  name: 'Gas Injection Rate',
  tagList: [{
    agg: { scale: 86400000, mean: ['y'] },
    label: 'Well-2 - Gas Injection Rate',
    map: { epoch_ms: 'x', uom: 'uom', val: 'y' },
    tag_id: 1858,
    stroke: '#FF6600',
  }],
};

export const well2PressureConfig = {
  wellId: wellIdList.at(1),
  name: 'Sales Line Pressure, Surface Casing Pressure, Tubing Pressure',
  tagList: [{
    agg: { scale: 86400000, mean: ['y'] },
    label: 'Sales Line Pressure',
    map: { epoch_ms: 'x', uom: 'uom', val: 'y' },
    tag_id: 1861,
    stroke: '#6200EA',
  },
  {
    agg: { scale: 86400000, mean: ['y'] },
    label: 'Surface Casing Pressure',
    map: { epoch_ms: 'x', uom: 'uom', val: 'y' },
    tag_id: 1805,
    stroke: '#4CAF50',
  },
  {
    agg: { scale: 86400000, mean: ['y'] },
    label: 'Tubing Pressure',
    map: { epoch_ms: 'x', uom: 'uom', val: 'y' },
    tag_id: 1804,
    stroke: '#FFB300',
  }],
};

export const well3InjectionConfig = {
  wellId: wellIdList.at(2),
  wellName: 'Well3',
  name: 'Gas Injection Rate',
  tagList: [{
    agg: { scale: 86400000, mean: ['y'] },
    label: 'Well-3 - Gas Injection Rate',
    map: { epoch_ms: 'x', uom: 'uom', val: 'y' },
    tag_id: 1896,
    stroke: '#FF6600',
  }],
};

export const well3PressureConfig = {
  wellId: wellIdList.at(2),
  name: 'Sales Line Pressure, Surface Casing Pressure, Tubing Pressure',
  tagList: [{
    agg: { scale: 86400000, mean: ['y'] },
    label: 'Sales Line Pressure',
    map: { epoch_ms: 'x', uom: 'uom', val: 'y' },
    tag_id: 1899,
    stroke: '#6200EA',
  },
  {
    agg: { scale: 86400000, mean: ['y'] },
    label: 'Surface Casing Pressure',
    map: { epoch_ms: 'x', uom: 'uom', val: 'y' },
    tag_id: 1807,
    stroke: '#4CAF50',
  },
  {
    agg: { scale: 86400000, mean: ['y'] },
    label: 'Tubing Pressure',
    map: { epoch_ms: 'x', uom: 'uom', val: 'y' },
    tag_id: 1806,
    stroke: '#FFB300',
  }],
};

export const well4InjectionConfig = {
  wellId: wellIdList.at(3),
  wellName: 'Well4',
  name: 'Gas Injection Rate',
  tagList: [{
    agg: { scale: 86400000, mean: ['y'] },
    label: 'Well-4 - Gas Injection Rate',
    map: { epoch_ms: 'x', uom: 'uom', val: 'y' },
    tag_id: 1934,
    stroke: '#FF6600',
  }],
};

export const well4PressureConfig = {
  wellId: wellIdList.at(3),
  name: 'Sales Line Pressure, Surface Casing Pressure, Tubing Pressure',
  tagList: [{
    agg: { scale: 86400000, mean: ['y'] },
    label: 'Sales Line Pressure',
    map: { epoch_ms: 'x', uom: 'uom', val: 'y' },
    tag_id: 1937,
    stroke: '#6200EA',
    dataKey: 'Sales Line Pressure mean',
  },
  {
    agg: { scale: 86400000, mean: ['y'] },
    label: 'Surface Casing Pressure',
    map: { epoch_ms: 'x', uom: 'uom', val: 'y' },
    tag_id: 1809,
    stroke: '#4CAF50',
  },
  {
    agg: { scale: 86400000, mean: ['y'] },
    label: 'Tubing Pressure',
    map: { epoch_ms: 'x', uom: 'uom', val: 'y' },
    tag_id: 1808,
    stroke: '#FFB300',
  }],
};

export default [
  well1InjectionConfig,
  well1PressureConfig,
  well2InjectionConfig,
  well2PressureConfig,
  well3InjectionConfig,
  well3PressureConfig,
  well4InjectionConfig,
  well4PressureConfig,
];
