/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Divider, Select, Form, Button } from 'antd';
import { AutoSizer, List } from 'react-virtualized';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { StyledButton, StyledCheckbox, StyledModal, StyledInput } from '../styled';
import useWindowSize from '../hooks/useWindowSize';
import { useListFilter } from '../hooks/useFilter.tsx';
import useFetch from '../hooks/useFetch';

const { Option } = Select;

const ListRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: ${(props) => props.theme.widgets.border.border.full};
`;

const ListItem = styled.div`
  padding: 6px;
  color: ${(props) => props.theme.widgets.color.full};
`;

const ListDiv = styled.div`
  height: 300px;
  margin: 24px 0 0 0;

  @media (max-width: 768px) {
    min-width: 100%;
    font-size: 10px;
  }
`;

const TagHeader = styled.h3`
  color: ${(props) => props.theme.widgets.color.full};
`;

const WidgetSeriesBuilder = ({
  open,
  closeModal,
  assetTypeId,
  asset,
  match: { params },
  org: { value: orgValue = -1 },
  site: { value: siteValue = -1 },
  asset: { value: assetValue = -1 },
  dashboardId,
  layoutObject,
  activeLayout,
  templateItem,
  setActiveLayout,
}) => {
  const [chartables, setChartables] = useState([]);
  const [seriesProps, setSeriesProps] = useState({});
  const [styleModalOpen, toggleStyleModal] = useState(false);
  const [selectedChartables, setSelectedChartables] = useState([]);
  const [chartProps, setChartProps] = useState({
    height: 200,
    chartTitle: '',
    chartSubTitle: '',
    rounded: false,
    bordered: true,
  });

  const [loading, saveResponse, saveLayout] = useFetch('/v1/axil/layouts/save');
  const [loadingTags, fetchedTags, fetchBuilderTags] = useFetch(
    '/v2/axil/widgets/builder/custom_chart',
  );

  const { setFilter, filteredList } = useListFilter(chartables, ['name', 'level', 'type']);
  const [size] = useWindowSize();

  const addLayout = useSelector((state) => state.layouts.adding.add_layout);
  const themeProps = useSelector((state) => state.resources.theme);
  const roundedTheme = useSelector((state) => state.resources.themeStyles.round);

  const dispatch = useDispatch();
  const setAddLayout = (string) => dispatch({ type: 'SET_ADD_LAYOUT', payload: string });
  const setLayoutTrigger = (bool) => dispatch({ type: 'UPDATE_LAYOUT_TRIGGER', payload: bool });
  const defaultColors = [
    themeProps.palette.primary.full,
    themeProps.palette.secondary.full,
    '#00ff00',
    '#b0e4ea',
    '#e56a54',
  ];

  const formLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
  };

  const defaultLayout = {
    i: 'REPLACE',
    x: 0,
    y: 0,
    w: 12,
    h: 6,
    widget: 'WidgetSeries',
    options: {
      config: {
        series: [
          {
            label: 'REPLACE',
            map: {
              epoch_ms: 'x',
              val: 'y',
              uom: 'uom',
            },
            tag_id: 'TYPE ID',
          },
        ],
      },
      props: {
        ...chartProps,
      },
    },
  };

  // fetches the current layout
  // sets options in the modal
  useEffect(() => {
    fetchBuilderTags({
      org_id: orgValue || params.org_id,
      site_id: siteValue || params.site_id,
      asset_id: assetValue || params.asset_id,
    });
  }, [assetTypeId]);

  useEffect(() => {
    if (!loadingTags) {
      setChartables(fetchedTags);
    }
  }, [fetchedTags, loadingTags]);

  const handleCheck = (chartable) => {
    const exists = selectedChartables.find((c) => c.tag_id === chartable.tag_id);
    if (exists) {
      return setSelectedChartables(selectedChartables.filter((c) => c.tag_id !== chartable.tag_id));
    }
    return setSelectedChartables([...selectedChartables, chartable]);
  };

  const mapSelectedChartablesAndCreateLayouts = async (arr) => {
    const chartId = `${arr.map((t) => t.tag_id).join('_')}-${uuidv4()}`;
    const styleSize = {
      rows: 6,
      height: 200,
    };

    // adjusts height(rows) of layout item. Needs to be tall enough to fit
    // entire graph with multiple labels
    if (arr.length > 3) {
      styleSize.rows = 8;
      styleSize.height = 230;
    }

    const createMap = (chartable) => {
      let map = {
        epoch_ms: 'x',
        val: 'y',
        uom: 'uom',
      };

      if (chartable.type === 'event') {
        map = {
          epoch_ms: 'x',
          val: 'msg',
        };
      }

      return map;
    };

    const createAgg = (chartable) => {
      let agg = null;
      if (chartable.type !== 'event') {
        agg = {
          mean: ['y'],
          max: ['y'],
          min: ['y'],
        };
      }

      return agg;
    };

    // TODO: Add validation and remove uuid;
    const newLayoutItem = Object.assign({}, defaultLayout, {
      ...defaultLayout,
      i: chartId,
      h: styleSize.rows,
      y: 1,
      options: {
        ...defaultLayout.options,
        props: {
          ...defaultLayout.options.props,
          id: chartId,
          height: styleSize.height,
          removable: true,
        },
        config: {
          ...defaultLayout.options.config,
          series: arr.map((chartable, i) => {
            if (templateItem) {
              return {
                ...defaultLayout.options.config.series[0],
                label: chartable.name,
                tag_id: chartable.tag_id,
                map: createMap(chartable),
                agg: createAgg(chartable),
                seriesProps: {
                  type: 'line',
                  lineColor: defaultColors[i],
                  ...seriesProps[chartable.name],
                },
              };
            }
            return {
              ...defaultLayout.options.config.series[0],
              org_id: chartable.org_id,
              site_id: chartable.site_id,
              asset_id: chartable.asset_id,
              device_id: chartable.device_id,
              label: chartable.name,
              asset_name: asset?.label,
              tag_id: chartable.tag_id,
              map: createMap(chartable),
              agg: createAgg(chartable),
              seriesProps: {
                type: 'line',
                lineColor: defaultColors[i],
                ...seriesProps[chartable.name],
              },
            };
          }),
        },
      },
    });

    const findLayout = (lay) => {
      return lay.map((item) => {
        const { children = [] } = item;
        if (item.i === addLayout) {
          return {
            ...item,
            h: item.h + styleSize.rows,
            children: [newLayoutItem, ...children],
          };
        }
        return { ...item, children: findLayout(children) };
      });
    };

    let saveLayoutParams = {
      ...params,
      dashboard_id: dashboardId,
      asset_type_id: assetTypeId,
      layout: { ...layoutObject, [size]: findLayout(activeLayout) },
    };

    if (templateItem) {
      saveLayoutParams = {
        org_id: -1,
        site_id: -1,
        asset_id: -1,
        device_id: -1,
        dashboard_id: dashboardId,
        asset_type_id: assetTypeId,
        layout: { ...layoutObject, [size]: findLayout(activeLayout) },
      };
    }

    await saveLayout(saveLayoutParams);
    setActiveLayout(findLayout(activeLayout));
    setAddLayout('');
  };

  const handleSubmit = () => {
    mapSelectedChartablesAndCreateLayouts(selectedChartables);
    setSelectedChartables([]);
    setFilter('');
    closeModal();
  };

  const handleSeriesProps = (name, key, value) => {
    setSeriesProps({
      ...seriesProps,
      [name]: {
        ...seriesProps[name],
        [key]: value,
      },
    });
  };

  const handleChartProps = (key, value) => {
    setChartProps({ ...chartProps, [key]: value });
  };

  const ListRows = ({ index, style, key }) => {
    return (
      <ListRow style={style} key={key}>
        <ListItem style={{ width: '75%' }}>
          <div> {filteredList[index].name} </div>
        </ListItem>
        <ListItem style={{ width: '25%' }}>
          <StyledCheckbox
            onChange={() => handleCheck(filteredList[index])}
            checked={selectedChartables.find((c) => c.tag_id === filteredList[index].tag_id)}
          />
        </ListItem>
      </ListRow>
    );
  };

  return (
    <StyledModal
      visible={open}
      onCancel={closeModal}
      onOk={closeModal}
      title="Select Items for new Chart"
      footer={[
        <Button onClick={() => toggleStyleModal(!styleModalOpen)}>Advanced Styling Options</Button>,
        <StyledButton
          type="primary"
          onClick={() => handleSubmit(selectedChartables)}
          disabled={!selectedChartables.length > 0}
        >
          Add Chart
        </StyledButton>,
        <StyledButton type="link" onClick={closeModal}>
          Cancel
        </StyledButton>,
      ]}
    >
      <StyledInput
        placeholder="Filter Series Options"
        onChange={(e) => setFilter(e.target.value)}
        rounded={roundedTheme}
      />
      <ListDiv>
        <AutoSizer>
          {({ width, height }) => (
            <List
              width={width}
              height={height}
              rowCount={filteredList.length}
              rowHeight={30}
              rowRenderer={ListRows}
            />
          )}
        </AutoSizer>
      </ListDiv>
      <StyledModal
        width={800}
        bodyStyle={{ maxHeight: '600px', overflow: 'scroll' }}
        title="Chart Display Options"
        onOk={() => toggleStyleModal(false)}
        onCancel={() => toggleStyleModal(false)}
        visible={styleModalOpen}
        closable={false}
      >
        <Form {...formLayout}>
          <Form.Item label="Chart Label">
            <StyledInput
              onChange={(e) => handleChartProps('chartTitle', e.target.value)}
              rounded={roundedTheme}
            />
          </Form.Item>
          <Form.Item label="Chart Sub Label">
            <StyledInput
              onChange={(e) => handleChartProps('chartSubTitle', e.target.value)}
              rounded={roundedTheme}
            />
          </Form.Item>
          {selectedChartables.map(({ name }) => {
            return (
              <>
                <Divider />
                <TagHeader> {name} </TagHeader>
                <div style={{ padding: '12px' }}>
                  <Form layout="vertical">
                    <Form.Item label="Series Label">
                      <StyledInput
                        onChange={(e) => handleSeriesProps(name, 'label', e.target.value)}
                        defaultValue={name}
                        rounded={roundedTheme}
                      />
                    </Form.Item>
                    <Form.Item label="Chart Type">
                      <Select defaultValue="line" style={{ width: '100%' }}>
                        <Option value="line"> Line </Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="Series Line Color">
                      <StyledInput
                        style={{ width: '120px' }}
                        type="color"
                        onChange={(e) => handleSeriesProps(name, 'lineColor', e.target.value)}
                        rounded={roundedTheme}
                      />
                    </Form.Item>
                  </Form>
                </div>
              </>
            );
          })}
        </Form>
      </StyledModal>
    </StyledModal>
  );
};

WidgetSeriesBuilder.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default WidgetSeriesBuilder;
