import React, { CSSProperties, ReactElement } from 'react';
import { List as AntList, Typography } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Routes } from '../../../constants';
import { useAppSelector } from '../../../store/hooks';
import { Asset } from '../../../global.interfaces';
import { getStatusType, StatusTypes } from './statusTypeUtils';

const Row = styled.div`
  display: flex;
  padding: 6px 6px;
  width: 100%;
`;

const SiteLink = styled(Link)<{ demoMode: { demo: boolean } }>`
  color: ${(props) => (props.demoMode ? 'transparent !important' : '')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
`;

const ListItemMeta = styled(AntList.Item.Meta)`
  width: 100%;

  && .ant-list-item-meta {
    width: 100%;
  }
  && .ant-list-item-meta-content {
    width: 100%;
  }
`;

const ItemText = styled(Typography.Text)`
  padding: 0 4px;
`;

const OrgLink = styled(Link)<{ demoMode: { demo: boolean } }>`
  color: ${(props) => (props.demoMode ? 'transparent !important' : '')};
  text-shadow: ${(props) => (props.demoMode ? '0 0 12px rgba(0,0,0,0.5)' : '')};
`;

const ListRow = ({
  key,
  item,
  itemHeight,
  style = {},
}: {
  key: string;
  item: Asset;
  itemHeight: number;
  style?: CSSProperties;
}): ReactElement => {
  const {
    asset_id,
    asset_name,
    device_id,
    model,
    org_id,
    org_name,
    runtime,
    site_id,
    site_name,
    status,
  } = item;
  const demoMode = useAppSelector((state) => state.resources.demo);
  const assetLink = `/asset/${org_id}/${site_id}/${asset_id}/${device_id}`;
  const orgDashboardLink = `${Routes.DASHBOARD}/${org_id}`;

  const getRTPColor = (rtp: number) => {
    if (rtp >= 98) {
      return StatusTypes.OK.color;
    }
    return StatusTypes.ERROR.color;
  };

  return (
    <AntList.Item
      key={key}
      style={{
        ...style,
        borderBottom: '1px solid #f0f0f0',
        maxHeight: `${itemHeight}px`,
        overflow: 'hidden',
      }}
    >
      <ListItemMeta
        title={
          <h4>
            <OrgLink demoMode={demoMode.demo} to={orgDashboardLink}>
              {org_name}
            </OrgLink>
          </h4>
        }
        description={
          <div>
            <Row>
              <SiteLink demoMode={demoMode.demo} to={`${orgDashboardLink}/${site_id}`}>
                {site_name}
              </SiteLink>
            </Row>
            <Row style={{ flexWrap: 'wrap', paddingLeft: '-4px' }}>
              <ItemText>
                Unit:{' '}
                <span>
                  <Link to={assetLink}>{asset_name}</Link>
                </span>
              </ItemText>
              <ItemText>
                RTP: <span style={{ color: getRTPColor(runtime) }}>{runtime}%</span>
              </ItemText>
              {model && (
                <ItemText>
                  Model: <span>{model}</span>
                </ItemText>
              )}
              <ItemText>
                Status: <span style={{ color: getStatusType(status).color }}>{status}</span>
              </ItemText>
            </Row>
          </div>
        }
      />
    </AntList.Item>
  );
};

export default ListRow;
