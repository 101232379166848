import { AssetEvent } from '../global.interfaces';

const initialState: Array<AssetEvent> = [];

export const SET_EVENTS = 'SET_EVENTS';
export const RESET_EVENTS = 'RESET_EVENTS';

export function reducer(
  state = initialState,
  { type, payload = [] }: { type: string; payload?: Array<AssetEvent> },
): typeof initialState {
  switch (type) {
    case SET_EVENTS:
      return payload;
    case RESET_EVENTS:
      return [];
    default:
      return state;
  }
}
