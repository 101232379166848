import moment from 'moment';

import { firstSecondOfStartDateFormatter, lastSecondOfStopDateFormatter } from 'single-asset/constants/glxProductionConstants';
import { transformAssetProps } from 'single-asset/transformers/assetTransformers';

export const transformGLXSeries = (seriesConfig, dates, asset) => {
  const [startDate, stopDate] = dates;

  return {
    start: moment(startDate, firstSecondOfStartDateFormatter).utc(),
    stop: moment(stopDate, lastSecondOfStopDateFormatter).utc(),
    series: seriesConfig.tagList.map((series) => ({
      ...series,
      ...transformAssetProps(asset),
    })),
  };
};
