import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { GlobalContext } from '../../GlobalContext';
import { AssetData, ImmediateReportData, OrgTuple, ScheduledReportData } from './types';

export default (): {
  canAccessReports?: boolean;
  deleteScheduledReport: (userReportName: string) => Promise<Response>;
  fetchAreas: (customerId: number) => Promise<Array<string>>;
  fetchOrgs: () => Promise<Array<OrgTuple>>;
  postImmediateReport: (body: ImmediateReportData) => Promise<Response>;
  postScheduledReport: (body: ScheduledReportData) => Promise<Response>;
  scheduledReportData: Array<ScheduledReportData>;
} => {
  const isProd = process.env.REACT_APP_BUILD_ENV === 'production';
  const devPrefix = !isProd ? 'dev-' : '';
  const localSuffix = process.env.REACT_APP_IS_LOCAL === 'true' ? '/local' : '';
  // TODO: Put back in environmental logic once prod env vars are live
  const baseUrl = /* `https://${devPrefix}api.axil.ai${localSuffix}`; */ 'https://api.axil.ai';
  const iamUrl = `${baseUrl}/iam`;
  const reportingUrl = `${baseUrl}/reporting`;
  const orgUrl = `${iamUrl}/orgs`;
  const schedulesUrl = `${reportingUrl}/schedules`;

  const { accessToken } = useContext(GlobalContext);
  const [canAccessReports, setCanAccessReports] = useState<boolean | undefined>(undefined);
  const [scheduledReportData, setScheduledReportData] = useState<Array<ScheduledReportData>>([]);

  const defaultOptions = useMemo(
    () => ({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    }),
    [accessToken],
  );

  const fetchScheduledReports = useCallback(async () => {
    const { reports } = await (await fetch(schedulesUrl, defaultOptions)).json();

    setScheduledReportData(reports);
  }, [schedulesUrl, defaultOptions]);

  const deleteScheduledReport = useCallback(
    async (userReportName: string) => {
      const response = await fetch(`${schedulesUrl}/${userReportName}`, {
        ...defaultOptions,
        method: 'DELETE',
      });

      fetchScheduledReports();

      return response;
    },
    [defaultOptions, fetchScheduledReports, schedulesUrl],
  );

  const fetchIsReportingGroup = useCallback(async () => {
    const { id } = await (await fetch(`${iamUrl}/groups/reporting`, defaultOptions)).json();

    setCanAccessReports(!!id);
  }, [iamUrl, defaultOptions]);

  const fetchAreas = useCallback(
    async (customerId: number) => {
      const response = await fetch(`${orgUrl}/${customerId}/assets`, defaultOptions);

      const { assets } = (await response.json()) as { assets: Array<AssetData> };

      const isUniqueArea = (asset: AssetData, i: number) =>
        assets.findIndex((fAsset: AssetData) => fAsset.area === asset.area) === i;

      return assets.filter(isUniqueArea).map(({ area }: AssetData) => area);
    },
    [orgUrl, defaultOptions],
  );

  const fetchOrgs = useCallback(
    async () =>
      (
        await (await fetch(orgUrl, defaultOptions)).json()
      ).orgs.map(({ orgName, orgId }: { orgName: string; orgId: number }) => [orgId, orgName]),
    [orgUrl, defaultOptions],
  );

  const postImmediateReport = useCallback(
    (body) =>
      fetch(`${reportingUrl}/immediate/flux-fleet`, {
        ...defaultOptions,
        body: JSON.stringify(body),
        method: 'POST',
      }),
    [reportingUrl, defaultOptions],
  );

  const postScheduledReport = useCallback(
    async (body) => {
      const response = await fetch(`${schedulesUrl}`, {
        ...defaultOptions,
        body: JSON.stringify(body),
        method: 'POST',
      });

      fetchScheduledReports();

      return response;
    },
    [schedulesUrl, defaultOptions, fetchScheduledReports],
  );

  useEffect(() => {
    if (accessToken) {
      fetchIsReportingGroup();
    }
  }, [accessToken, fetchIsReportingGroup]);

  useEffect(() => {
    if (accessToken && canAccessReports) {
      fetchScheduledReports();
    }
  }, [accessToken, canAccessReports, fetchScheduledReports]);

  return {
    canAccessReports,
    deleteScheduledReport,
    fetchAreas,
    fetchOrgs,
    postImmediateReport,
    postScheduledReport,
    scheduledReportData,
  };
};
