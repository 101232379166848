import React from 'react';

const ReportOrganizationComponent = ({ handleChange, organizations }) => {
  const singleOrganizationLabelText = organizations[0]?.orgName ?? 'NO ORG NAME';
  const singleOrganizationLabel = (
    <p className="organization-label">{singleOrganizationLabelText}</p>
  );

  const renderDisplayedOrganizations = [
    <option className="select-option" key="select-option">
      Select Value
    </option>,
  ];

  if (organizations.length > 1) {
    organizations.map((organization) =>
      renderDisplayedOrganizations.push(
        <option className="select-option" key={organization.orgId} value={organization.orgId}>
          {organization.orgName}
        </option>,
      ),
    );
  }

  return (
    <section className="report-section">
      <div className="organization-row">
        <p className="displayed-options-title">Generating reports for</p>
        {organizations.length ? (
          <>
            {organizations.length === 1 ? (
              singleOrganizationLabel
            ) : (
              <select className="orgId-container" name="orgId" onChange={(e) => handleChange(e)}>
                {renderDisplayedOrganizations}
              </select>
            )}
          </>
        ) : (
          <p className="organization-label">LOADING</p>
        )}
      </div>
    </section>
  );
};
export default ReportOrganizationComponent;
