import { useState, useEffect, Dispatch, SetStateAction } from 'react';

/*
 * params { array of objects } list - list to filter on
 * params { array of strings } - keys in object to filter on
 */
export const useListFilter = <T extends Record<string, unknown>, K extends keyof T>(
  list: Array<T> = [],
  keys: Array<K> = [],
): { filteredList: Array<T>; setFilter: Dispatch<SetStateAction<string>>; filter: string } => {
  const [filteredList, setList] = useState(list);
  const [filter, setFilter] = useState('');
  const [filterKeys, setFilterKeys] = useState(keys);

  useEffect(() => {
    if (
      keys.length !== filterKeys.length ||
      keys.some((value, index) => value !== filterKeys[index])
    ) {
      setFilterKeys(keys);
    }
  }, [filterKeys, keys, setFilterKeys]);

  useEffect(() => {
    const isValueString = (value: string | T[K]): value is string => {
      return typeof value === 'string';
    };

    let updatedList = list;

    if (filter.length >= 1) {
      updatedList = list.filter((item) =>
        filterKeys.some((key) => {
          const value = item[key];
          return isValueString(value) && value.toLowerCase().includes(filter.toLowerCase());
        }),
      );
    }

    setList(updatedList);
  }, [filter, filterKeys, list]);

  return {
    filteredList,
    setFilter,
    filter,
  };
};
