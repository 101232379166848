import React, { useEffect, useRef } from 'react';
import { Row, Col, Card } from 'antd';
import styled from 'styled-components';
import useDimensions from 'react-cool-dimensions';
import { useSelector } from 'react-redux';
import useFetch from '../hooks/useFetch';
import EditDiv from '../Editing/EditDiv';

const Container = styled(Card)`
  && {
    background: ${props => props.theme.widgets.background.full};
    box-shadow: ${props => props.theme.widgets.border.boxShadow};
    border: ${props => props.theme.widgets.border.border.full};
    border-radius: ${props => props.rounded ? '12px' : 'none'};
    color: ${props => props.theme.widgets.color.full};
    height: 100%;
  }
  .ant-card-head-title {
    color: ${props => props.theme.widgets.color.full};
  }
  .ant-card-body {
    padding: 12px;
  }
`;

const StatLabel = styled.p`
  margin: 0;
`;

const StatValue = ({ color, fontSize, value }) => {
  return (
    <p style={{ color, margin: 0, fontSize }}> {value} </p>
  );
};

const { localStorage } = window;

const WidgetStatboard = ({ id, parentId, props, config, match }) => {
  const { title, columns } = props;
  const altitudeGroupId = useSelector(state => state.frame.altitude_group_id);
  const editableLayout  = useSelector(state => state.layouts.editing.editable_layout);
  const editMode = editableLayout === parentId;

  const [loading, data, fetchData] = useFetch(config.endpoint);

  useEffect(() => {
    fetchData({ ...match, ...config, filters: altitudeGroupId });
  }, []);

  const { round } = JSON.parse(localStorage.getItem('theme'));

  const ref = useRef();
  const { width } = useDimensions(ref, {});

  const findFontSize = w => {
    if (w) {
      return w / 10
    };
    return null
  };

  if (loading) { return (<div> LOADING </div>)}

  return (
    <Container rounded={round} title={title}>
      { editMode && <EditDiv id={id} /> }
      <Row justify="center" style={{ height: '100%' }}>
        { columns.map((col, i) => (
          <Col span={24 / columns.length}>
            { i === 0 ? <div ref={ref} /> : null }
            <Row justify='center'>
              <StatLabel> { col.label } </StatLabel>
            </Row>
            <Row justify='center'>
              <StatValue
                fontSize={findFontSize(width)}
                value={col.raw_value || data[0]?.[col.tag_value]}
              />
            </Row>
          </Col>
        ))}
      </Row>
    </Container>
  )
};

export default WidgetStatboard;
