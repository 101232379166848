import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Input, InputProps } from 'antd';
import { useAppSelector } from '../../store/hooks';

const SInput = styled(Input)<{ rounded: boolean }>`
  && {
    &.ant-input:hover {
      border-radius: ${(props) => (props.rounded ? '12px' : '0px')};
    }
    &.ant-input:focus {
      box-shadow: ${(props) => props.theme.palette.primary.boxShadow};
      border-radius: ${(props) => (props.rounded ? '12px' : '0px')};
    }
    &.ant-input {
      border-radius: ${(props) => (props.rounded ? '12px' : '0px')};
      background: ${(props) => props.theme.widgets.background.light};
      color: ${(props) => props.theme.widgets.color.full};
      border: ${(props) => props.theme.widgets.border.border.light};
    }
  }
`;

const StyledInput = (props: InputProps): ReactElement => {
  const rounded = useAppSelector((state) => state.resources.themeStyles.round);

  return <SInput rounded={rounded} {...props} />;
};

export default StyledInput;
