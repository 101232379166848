import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import styled from 'styled-components';
import moment from 'moment';
import { useSelector } from 'react-redux';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import useFetch from '../hooks/useFetch';
import EditDiv from '../Editing/EditDiv';

const Container = styled.div`
  ${ props => `
    && {
      background: ${props.theme.widgets.background.full};
      border: ${props.bordered ? props.theme.widgets.border.border.full : 'none'};
      border-radius: ${props.rounded ? '12px' : 'none'};
      height: 100%;
      padding: 6px;
    }
  `}
`;

const StyledCalendar = styled(Calendar)`
  ${props => `
    && {
      .rbc-time-view {
        overflow: scroll;
      }
      .rbc-event {
        background: ${props.theme.palette.primary.full};
      }
      .rbc-header {
        border-bottom: ${props.theme.widgets.border.border.full};
        border-left: ${props.theme.widgets.border.border.full};
      }
      .rbc-event:hover {
        background-color: ${({ theme }) => theme.palette.buttons.primary.background.light};
        color: ${props.theme.widgets.color.full};
        border-color: ${({ theme }) => theme.palette.buttons.primary.border.light};
      }
      .rbc-event:active {
        background-color: ${({ theme }) => theme.palette.buttons.primary.background.dark};
        color: ${({ theme }) => theme.palette.buttons.primary.color.dark};
        border-color: ${({ theme }) => theme.palette.buttons.primary.border.dark};
      }
      .rbc-day-bg {
        border-left: ${props.theme.widgets.border.border.full};
      }
      .rbc-off-range-bg {
        background-color: ${props.theme.widgets.background.light};
      }
      .rbc-month-row {
        border-top: ${props.theme.widgets.border.border.full};
      }
      .rbc-month-view {
        border: ${props.theme.widgets.border.border.full};
        color: ${props.theme.widgets.color.full}
      }
      .rbc-today {
        background-color: ${props.theme.widgets.background.light};
      }
      .rbc-toolbar-label {
        color: ${props.theme.widgets.color.full}
      }
      .rbc-time-header-cell {
        color: ${props.theme.widgets.color.full}
      }
      .rbc-label {
        color: ${props.theme.widgets.color.full}
      }
      .rbc-agenda-date-cell {
        color: ${props.theme.widgets.color.full}
      }
      .rbc-agenda-time-cell {
        color: ${props.theme.widgets.color.full}
      }
      .rbc-agenda-event-cell {
        color: ${props.theme.palette.primary.full}
      }
    }

    && button {
      background: ${props.theme.widgets.background.light};
      color: ${props.theme.widgets.color.full};
      border: ${props.theme.widgets.border.border.light};
    }

    && th {
      color: ${props.theme.widgets.color.full}
    }
 `}
`;

const WidgetCalendar = ({ id, parentId, config, match, props, roundedTheme, altitudeGroupId }) => {
  const { value, map, bordered = true } = props;
  const { rawValue = null } = config;
  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState([]);

  const params = match;
  const editableLayout = useSelector(state => state.layouts.editing.editable_layout);
  const theme = useSelector(state => state.resources.theme);
  const editMode = editableLayout === parentId;
  const [loading, data, fetchCalendarData] = useFetch(config.endpoint);

  const generateEvents = (arr) => {
    setEvents(arr.flatMap(vals => {
      if (vals[value]) {
        return vals[value].map(val => {
          // create list of values to pull off of 'vals'
          const mappings = Object.keys(map);
          return mappings.flatMap(m => {
            let timestamp = moment().toDate();
            if (m === 'start' || m === 'end') {
              timestamp = moment(val[map[m]]).toDate();
              return ({ [m]: timestamp});
            }
            return ({ [m]: val[map[m]]});
          }).reduce((acc, val) => ({ ...acc, ...val, 'allday': true }), {}); 
        });
      }
      return [];
    }));
  };

  useEffect(() => {
    fetchCalendarData({ ...params, ...config, filters: altitudeGroupId });
  }, [params, altitudeGroupId]);

  useEffect(() => {
    if (!loading && data) {
      generateEvents(data);
    }
  }, [loading, data]);

  // Generate events based on a map from layout

  return (
    <Container bordered={bordered} rounded={roundedTheme}>
      { editMode && <EditDiv id={id} /> }
      <StyledCalendar
        theme={theme}
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        events={rawValue || events}
        style={{ padding: '6px' }}
      />
    </Container>
  )
};

export default WidgetCalendar;
