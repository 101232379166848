import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Spin } from 'antd';
import { ReactComponent as Logo } from 'assets/images/FluxLogo.svg';

const FluxLogo = styled(Logo)`
  height: 100%;
  width: 240px;

  g {
    fill: ${(props) => props.theme.widgets.color.full};

    path:first-child {
      fill: ${(props) => props.theme.palette.primary.full};
    }
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    background: ${(props) => props.theme.palette.primary.background};
    opacity: 0;
  }
`;

const Fade = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  ${(props) => (props.out ? 'display: none;' : 'display: inline-block;')}
  animation: ${fadeOut} 2s linear infinite;

  && .ant-spin-text {
    color: #666666;
  }

  && .ant-spin-dot-item {
    background: #FF6600;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 30%;
  left: 40%;
`;

const FullScreenLoader = ({ loading }) => (
  <Fade out={!loading}>
    <LogoContainer>
      <FluxLogo />
      <div style={{ textAlign: 'center' }}>
        <Spin tip="Loading" size="small" />
      </div>
    </LogoContainer>
  </Fade>
);

export default FullScreenLoader;
