import styled from 'styled-components';

export const Header = styled.h2`
  color: ${(props) => props.theme.widgets.color.full};
  margin: 12px 0 0 12px;
`;

export const Container = styled.div<{ rounded: boolean }>`
  margin: 66px 12px 0 12px;
  background: ${(props) => props.theme.widgets.background.full};
  color: ${(props) => props.theme.widgets.color.full};
  border-radius: ${(props) => (props.rounded ? '12px' : 'none')};
`;

export const Value = styled.span`
  color: ${({ color }) => color};
`;

export const FilterTagsContainer = styled.div`
  display: flex;
  align-items: center;
`;
