import { createSlice } from '@reduxjs/toolkit';
import { OrgTuple, ReportType } from 'components/Report/types';

const initialCanAccessReports: boolean | null = null;

const initialState = {
  canAccessReports: initialCanAccessReports as boolean | null,
  orgs: [] as Array<OrgTuple>,
  reportTypes: [] as Array<ReportType>,
  scheduledReportData: [] as Array<any>,
};

const reportSlice = createSlice({
  name: 'reporting',
  initialState,
  reducers: {
    setCanAccessReports: (state, action) => ({ ...state, canAccessReports: action.payload }),
    setOrgs: (state, action) => ({ ...state, orgs: action.payload }),
    setReportTypes: (state, action) => ({ ...state, reportTypes: action.payload }),
    setScheduledReportData: (state, action) => ({ ...state, scheduledReportData: action.payload }),
  },
});

export const {
  setCanAccessReports,
  setOrgs,
  setReportTypes,
  setScheduledReportData,
} = reportSlice.actions;
export default reportSlice.reducer;
