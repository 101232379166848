export const StatusTypes = {
  OK: { color: '#268a44' },
  ERROR: { color: '#CD202D' },
  WARN: { color: '#eb8334' },
};

export const getStatusType = (status: string): { color: string } => {
  switch (status) {
    case 'Running':
      return StatusTypes.OK;
    case 'Faulted':
    case 'Off':
      return StatusTypes.ERROR;
    default:
      return StatusTypes.WARN;
  }
};
