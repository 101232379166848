import { wellIdList } from 'single-asset/constants/gasLiftConstants';

export const gluDailyProductionConfig = {
  wellId: wellIdList.at(0),
  name: 'Total Daily Production - Oil, Water, Gas',
  tagList: [{
    agg: { scale: 86400000, max: ['y'] },
    label: 'Instant Water Production',
    map: { epoch_ms: 'x', uom: 'uom', val: 'y' },
    tag_id: 652,
    stroke: '#2962FF',
    uom: 'BBLS',
  },
  {
    agg: { scale: 86400000, max: ['y'] },
    label: 'Instant Oil Production',
    map: { epoch_ms: 'x', uom: 'uom', val: 'y' },
    stroke: '#00C853',
    tag_id: 647,
    uom: 'BBLS',
  },
  {
    agg: { scale: 86400000, max: ['y'] },
    label: 'Instant Gas Production',
    map: { epoch_ms: 'x', uom: 'uom', val: 'y' },
    tag_id: 656,
    stroke: '#D50000',
    uom: 'MCF',
  }],
};

export const gluPressureConfig = {
  wellId: wellIdList.at(0),
  name: 'Sales Line Pressure, Surface Casing Pressure, Tubing Pressure',
  tagList: [{
    agg: { scale: 86400000, mean: ['y'] },
    label: 'Sales Line Pressure',
    map: { epoch_ms: 'x', uom: 'uom', val: 'y' },
    tag_id: 658,
    stroke: '#6200EA',
  },
  {
    agg: { scale: 86400000, mean: ['y'] },
    label: 'Surface Casing Pressure',
    map: { epoch_ms: 'x', uom: 'uom', val: 'y' },
    tag_id: 609,
    stroke: '#4CAF50',
  },
  {
    agg: { scale: 86400000, mean: ['y'] },
    label: 'Tubing Pressure',
    map: { epoch_ms: 'x', uom: 'uom', val: 'y' },
    tag_id: 608,
    stroke: '#FFB300',
  }],
};

export default [gluDailyProductionConfig, gluPressureConfig];
