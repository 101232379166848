import * as telemetryStatsActionTypes from 'single-asset/actions/telemetryStatsActionTypes';

export const defaultState = {
  isPending: false,
  statsList: [],
  failedResultList: [],
};

const telemetryStatsReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case telemetryStatsActionTypes.TELEMETRY_STATS__FETCH_STATS_LIST: {
      return {
        ...defaultState,
        isPending: true,
      };
    }

    case telemetryStatsActionTypes.TELEMETRY_STATS__FETCH_STATS_LIST_FAILED: {
      const { failedResultList } = action;

      return {
        ...defaultState,
        failedResultList,
      };
    }

    case telemetryStatsActionTypes.TELEMETRY_STATS__FETCH_STATS_LIST_SUCCEEDED: {
      return {
        ...defaultState,
        statsList: action.successfulResultList,
      };
    }

    default: {
      return state;
    }
  }
};

export default telemetryStatsReducer;
