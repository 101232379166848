// TODO: This package has no types, and it hasn't been updated since 2019
// Look into alternative?
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import supercluster from 'points-cluster';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import GoogleMapReact, { ChangeEventValue } from 'google-map-react';
import { mean } from 'ramda';
import { BeatLoader } from 'react-spinners';
import { useRouteMatch } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import { darkMode } from './darkmode';
import { Container, LoaderDiv, NoGPS } from './styles';
import EditDiv from '../../Editing/EditDiv';
import { useAppSelector } from '../../../store/hooks';
import { MapItem, MapAsset, WidgetMapProps } from './types';
import usePrevious from '../../hooks/usePrevious';
import MapPoint from './MapPoint';

const WidgetMap = ({ id, config, props }: WidgetMapProps): ReactElement => {
  const { showTooltip, singleAssetOptions, clusterOptions, zoom, center } = props;

  const { params } = useRouteMatch();

  const theme = useAppSelector((state) => state.resources.themeStyles.theme);
  const editMode = useAppSelector((state) => state.layouts.editing.editable_layout);
  const altitudeGroupId = useAppSelector((state) => state.frame.altitude_group_id);

  const [clusters, setClusters] = useState([]);
  const [centerMap, setCenterMap] = useState(center);
  const [mapOptions, setMapOptions] = useState<ChangeEventValue>();
  const [tooltipOpen, setTooltipOpen] = useState<string | null>(null);
  const [fetchParams, setFetchParams] = useState({
    ...params,
    ...config,
    filters: altitudeGroupId,
  });

  const prevFetchParams = usePrevious(fetchParams);

  const [loading, values, fetchMapData] = useFetch(config.endpoint);

  useEffect(() => {
    setFetchParams({ ...params, ...config, filters: altitudeGroupId });
  }, [altitudeGroupId, config, params]);

  useEffect(() => {
    if (fetchParams !== prevFetchParams) fetchMapData(fetchParams);
  }, [fetchMapData, fetchParams, prevFetchParams]);

  useEffect(() => {
    if (!loading && values) {
      const getMeanForCoords = (coordType: 'lat' | 'lon') =>
        mean(
          values
            .map((value: { [coordType: string]: number }) => value[coordType])
            .filter((coord: number) => !!coord),
        );

      setCenterMap({
        lat: getMeanForCoords('lat'),
        lng: getMeanForCoords('lon'),
      });
    } else setCenterMap(center);
  }, [center, loading, values]);

  const getClusters = useCallback(() => {
    const latLong = values.map((value: MapAsset) => ({
      id: value.alt_id,
      lng: value.lon,
      ...value,
    }));

    const cl = supercluster(latLong, {
      minZoom: 0,
      maxZoom: 16,
      radius: 20,
    });

    return cl(mapOptions);
  }, [mapOptions, values]);

  const mapCluster = ({
    wx,
    wy,
    numPoints,
    points,
  }: {
    wx: number;
    wy: number;
    numPoints: number;
    points: Array<MapAsset>;
  }) => ({
    lat: wy,
    lng: wx,
    numPoints,
    id: `${numPoints}_${points[0].id}`,
    points,
  });

  useEffect(() => {
    setClusters(mapOptions?.bounds ? getClusters().map(mapCluster) : []);
  }, [getClusters, mapOptions?.bounds]);

  const handleMapChange = (newMapOptions: ChangeEventValue) => {
    setMapOptions(newMapOptions);
  };

  const getIsCluster = (item: MapItem) =>
    !(item.numPoints === 1 && (!mapOptions?.zoom || mapOptions.zoom > 4));

  const sharedGoogleMapProps = {
    bootstrapURLKeys: { key: 'AIzaSyB-_Hv0w3ujV4E0Fs0OjqHDsPO9iTSrMBk' },
    defaultZoom: zoom,
    onChange: handleMapChange,
    options: theme === 'dark' ? darkMode : {},
    yesIWantToUseGoogleMapApiInternals: true,
  };

  const renderMapLoading = () => (
    <LoaderDiv>
      <BeatLoader color="#ff6600" />
    </LoaderDiv>
  );

  const renderMapContents = () => (
    <GoogleMapReact
      center={centerMap}
      onClick={() => setTooltipOpen(null)}
      {...sharedGoogleMapProps}
    >
      {clusters.length > 0 &&
        clusters.map((item: MapItem) => (
          <MapPoint
            key={item.id}
            customKey={item.id}
            // TODO: See how to resolve needed lat/lng in a way TS likes
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            lat={item.lat}
            lng={item.lng}
            item={item}
            isCluster={getIsCluster(item)}
            setCenterMap={setCenterMap}
            setTooltipOpen={setTooltipOpen}
            showTooltip={showTooltip}
            options={getIsCluster(item) ? clusterOptions : singleAssetOptions}
            tooltipOpen={tooltipOpen}
          />
        ))}
    </GoogleMapReact>
  );

  const renderNoValidMapPoints = () => (
    <GoogleMapReact center={{ lat: 39.8283, lng: -98.5795 }} {...sharedGoogleMapProps}>
      <NoGPS> No GPS Data </NoGPS>
    </GoogleMapReact>
  );

  const renderChildren = () => {
    if (loading || !values) return renderMapLoading();
    if (values.length > 0) {
      return values.filter(({ lat, lon }: MapAsset) => lat && lon).length > 0
        ? renderMapContents()
        : renderNoValidMapPoints();
    }
    return null;
  };

  return (
    <Container>
      {editMode && <EditDiv id={id} />}
      {renderChildren()}
    </Container>
  );
};

export default WidgetMap;
