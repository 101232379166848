import React from 'react';

import SingleAssetHeader from 'single-asset/SingleAssetHeader';
import SingleAssetRoutes from 'single-asset/components/SingleAssetRoutes';
import Notifications from 'single-asset/Notifications';

import './SingleAssetViewport.scss';

const SingleAssetViewport = ({
  asset,
  asset: { orgName, siteName, packageModel, driver, model, assetName },
  updatedAsset,
  setShowConfirmation,
  setConfirmation,
  showEditModal,
  setShowEditModal,
  currentPermissions,
}) => {
  const assetHeader = `${orgName} | ${siteName} | ${packageModel} | ${driver}  ${model}`;

  return (
    <div className="SingleAsset--Viewport">
      <SingleAssetHeader
        asset={asset}
        updatedAsset={updatedAsset}
        actionComp={<Notifications asset={asset} />}
        subHeader={assetName || 'Loading'}
        subText={assetHeader}
        setConfirmation={setConfirmation}
        setShowConfirmation={setShowConfirmation}
        setShowEditModal={setShowEditModal}
        showEditModal={showEditModal}
        currentPermissions={currentPermissions}
      />
      <SingleAssetRoutes asset={asset} />
    </div>
  );
};

export default SingleAssetViewport;
