import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import _ from 'lodash';

import { setAsset, clearSingleAssetState } from 'single-asset/actions/singleAssetActions';
import SingleAssetNav from 'single-asset/components/SingleAssetNav';
import SingleAssetViewport from 'single-asset/components/SingleAssetViewport';
import { GlobalContext } from 'GlobalContext';
import useFetch from 'components/hooks/useFetch';
import useAltitudeMatchParamsCallback from 'components/hooks/useAltitudeMatchParamsCallback';
import { useAppDispatch } from 'store/hooks';
import { RESET_EVENTS, SET_EVENTS } from 'store/events';
import { findAssetMatch, isNewAsset } from 'single-asset/helpers/singleAssetHelpers';

import { EnterpriseObjectsRepository } from 'shared/repositories/enterprise-objects-repository/EnterpriseObjectsRepository';
import { IAmRepository } from 'shared/repositories/iam-repository/IAmRepository';

import AlertModal from '../ip-edit-modal/AlertModal';
import IpEditModal from '../ip-edit-modal/IpEditModal';

export const SingleAssetPage = ({ singleAsset, updateAsset, clearAsset }) => {
  const { asset } = singleAsset;
  const [hasModel, setHasModel] = useState(false);
  const getAltitudeMatchParams = useAltitudeMatchParamsCallback();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [, tagData, fetchTagData] = useFetch(
    '/v1/axil/config/tag_values/by_tag_id?label=SA-header',
  );
  const [, eventsData, fetchEventsData] = useFetch('/v2/axil/events/by_altitude');
  const { assetList, accessToken } = useContext(GlobalContext);
  const { org_id, site_id, asset_id, device_id } = getAltitudeMatchParams();
  const isAssetLoaded = !_.isEmpty(asset);
  const [updatedAsset, setUpdatedAsset] = useState({});
  const [currentEnterpriseObjects, setEnterpriseObjects] = useState({});
  const [confirmationMessage, setConfirmation] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showError, setShowError] = useState(false);
  const [currentPermissions, setCurrentPermissions] = useState(null);

  const newAsset = { ...asset };

  const enterpriseObject = currentEnterpriseObjects?.assets?.[0];
  const updatedIpAddress = updatedAsset?.events?.[0]?.data?.changes?.ipAddress
    ? updatedAsset.events[0].data.changes.ipAddress
    : '';
  const updatedPort = updatedAsset?.events?.[0]?.data?.changes?.port
    ? updatedAsset.events[0].data.changes.port
    : '';

  newAsset.ipInfo = enterpriseObject?.ipInfo;
  newAsset.mechanic = enterpriseObject?.mechanic;
  newAsset.ipAddress = enterpriseObject?.ipAddress;
  newAsset.connectivityInfo = enterpriseObject?.connectivityInfo;
  newAsset.transferOrderStatus = enterpriseObject?.transferOrderStatus;

  let updatedStatus = '';

  updatedAsset?.events?.[1]?.data?.changes?.['ipAddressInfo.status'] === 'connected'
    ? (updatedStatus = 'connected')
    : (updatedStatus = 'disconnected');

  if (updatedIpAddress.length && updatedPort.length && updatedStatus.length) {
    newAsset.ipAddress = updatedIpAddress;
    newAsset.ipInfo = { ...newAsset.ipInfo, port: updatedPort };
    newAsset.connectivityInfo = { ...newAsset.connectivityInfo, status: updatedStatus };
  }

  const getDevices = (assetName) => {
    const ursaleoUrl = 'https://ursaleo.axil.ai';
    fetch(`${ursaleoUrl}/devices/${assetName}`)
      .then((res) => res.json())
      .then(setHasModel)
      .catch(() => setHasModel(false));
  };

  const getTagData = () => {
    fetchTagData({
      org_id,
      site_id,
      asset_id,
      device_id,
      tags: {
        model: { agg_func: 'last_value', key: 'value', tag_id: 112 },
        driver: { agg_func: 'last_value', key: 'value', tag_id: 108 },
      },
      filters: [],
    });
  };

  const getEventsData = () => {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    const {
      start = moment.utc().subtract(24, 'hours').format(dateFormat),
      end = moment.utc().format(dateFormat),
    } = queryString.parse(location.search);

    fetchEventsData({
      org_id,
      site_id,
      asset_id,
      device_id,
      filters: [],
      start,
      end,
    });
  };

  useEffect(() => {
    getTagData();

    return () => {
      dispatch(clearAsset());
    };
  }, []);

  useEffect(() => {
    isNewAsset(
      {
        site_id,
        asset_id,
        device_id,
      },
      asset,
    ) && dispatch(clearAsset());
  }, [org_id, site_id, asset_id, device_id]);

  useEffect(() => {
    getEventsData();
  }, [location.search]);

  useEffect(() => {
    dispatch({ type: SET_EVENTS, payload: eventsData });

    return () => {
      dispatch({ type: RESET_EVENTS });
    };
  }, [eventsData]);

  useEffect(() => {
    if (!isAssetLoaded && assetList?.length > 0) {
      const {
        asset_type_id: assetTypeId,
        asset_name: assetName,
        org_name: orgName,
        site_name: siteName,
        model,
      } = findAssetMatch(assetList, asset_id) ?? {};

      assetName && getDevices(assetName);
      updateAsset({
        assetId: asset_id,
        assetTypeId,
        deviceId: device_id,
        orgId: org_id,
        siteId: site_id,
        assetName,
        orgName,
        siteName,
        driver: tagData?.[0]?.driver || '',
        model: tagData?.[0]?.model || '',
        packageModel: model,
      });
    }
  }, [assetList, asset, tagData]);

  useEffect(() => {
    if (asset?.assetName) {
      const getEnterpriseObject = async () => {
        const enterpriseObject = await EnterpriseObjectsRepository.searchByAssetName(
          accessToken,
          asset.assetName,
        );

        setEnterpriseObjects(enterpriseObject);
      };

      const getCurrentPermissions = async () => {
        const permissions = await IAmRepository.checkIAmPermissions(accessToken);
        setCurrentPermissions(permissions);
      };

      getEnterpriseObject();
      getCurrentPermissions();
    }
  }, [accessToken, asset]);

  return (
    isAssetLoaded && (
      <div className="SingleAsset">
        {showConfirmation && (
          <AlertModal
            setShowAlert={setShowConfirmation}
            showAlert={showConfirmation}
            alertMessage={confirmationMessage}
          />
        )}
        <IpEditModal
          asset={newAsset}
          showError={showError}
          updatedAsset={updatedAsset}
          showEditModal={showEditModal}
          showConfirmation={showConfirmation}
          setShowError={setShowError}
          setConfirmation={setConfirmation}
          setUpdatedAsset={setUpdatedAsset}
          setShowEditModal={setShowEditModal}
          setShowConfirmation={setShowConfirmation}
        />
        <SingleAssetNav
          asset={newAsset}
          hasModel={hasModel}
          currentPermissions={currentPermissions}
        />
        <SingleAssetViewport
          asset={newAsset}
          updatedAsset={updatedAsset}
          showEditModal={showEditModal}
          showConfirmation={showConfirmation}
          currentPermissions={currentPermissions}
          confirmationMessage={confirmationMessage}
          setConfirmation={setConfirmation}
          setShowEditModal={setShowEditModal}
          setShowConfirmation={setShowConfirmation}
        />
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  singleAsset: state.singleAsset,
});

const mapDispatchToProps = (dispatch) => ({
  clearAsset: () => dispatch(clearSingleAssetState()),
  updateAsset: (elevation) => dispatch(setAsset(elevation)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleAssetPage);
