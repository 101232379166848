import React from 'react';
import styled from 'styled-components';
import WidgetMap from '../components/widgets/WidgetMap';
import WidgetImage from '../components/widgets/WidgetImage';
import WidgetTable from '../components/widgets/WidgetTable';
import WidgetChart from '../components/widgets/WidgetChart';
import WidgetLayout from '../components/widgets/WidgetLayout';
import WidgetSeries from '../components/widgets/WidgetSeries';
import WidgetTimeline from '../components/widgets/WidgetTimeline';
import WidgetCalendar from '../components/widgets/WidgetCalendar';
import WidgetAssetList from '../components/widgets/WidgetAssetList';
import WidgetStatboard from '../components/widgets/WidgetStatboard';
import WidgetBasicCard from '../components/widgets/WidgetBasicCard';
import WidgetDatePicker from '../components/widgets/WidgetDatePicker';
import WidgetScoreboard from '../components/widgets/WidgetScoreboard';
import EditWidgetButton from '../components/widgets/EditWidgetButton';
import WidgetVideoPlayer from '../components/widgets/WidgetVideoPlayer';
import WidgetBreadcrumbs from '../components/widgets/WidgetBreadcrumbs.tsx';
import WidgetTableWithChildren from '../components/widgets/WidgetTableWithChildren';
import FlowMeterSummary from '../components/widgets/WidgetCVRU/FlowMeterSummary';
import SeriesMockup from '../components/widgets/WidgetCVRU/SeriesMockup';
import ScoreboardMockup from '../components/widgets/WidgetCVRU/ScoreboardMockup';
import ImageMockup from '../components/widgets/WidgetCVRU/ImageMockup';
import CalculatedStats from '../components/widgets/WidgetCVRU/CalculatedStats-MOCK';
import ConfirmingIndicators from '../components/widgets/WidgetCVRU/ConfirmingIndicators-MOCK';

import ErrorBoundary from '../components/ErrorBoundary';

// BUILDER SHORTCUTS //
import SeriesBuilderShortcut from '../components/widgets/BuilderShortcuts/SeriesBuilderShortcut';

import CriticalRateChart from '../components/widgets/CriticalRateChart';

const components = {
  WidgetMap,
  WidgetTable,
  WidgetChart,
  WidgetImage,
  WidgetLayout,
  WidgetSeries,
  WidgetCalendar,
  WidgetTimeline,
  WidgetAssetList,
  WidgetBasicCard,
  WidgetStatboard,
  WidgetDatePicker,
  WidgetScoreboard,
  EditWidgetButton,
  CriticalRateChart,
  WidgetBreadcrumbs,
  WidgetVideoPlayer,
  SeriesBuilderShortcut,
  WidgetTableWithChildren,
  FlowMeterSummary,
  SeriesMockup,
  ScoreboardMockup,
  ImageMockup,
  CalculatedStats,
  ConfirmingIndicators,
};

const WidgetContainer = styled.div`
  overflow: visible;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export default function createWidget(el, parentProps) {
  const {
    i,
    widget,
    options: { props, config },
    children = [],
    ...grid
  } = el;
  const Widget = components[widget];

  return (
    <WidgetContainer key={i} data-grid={grid}>
      <ErrorBoundary info={{ ...el, ...parentProps }}>
        <Widget props={props} config={config} id={i} {...parentProps}>
          {children.map((childEl) => createWidget(childEl, { ...parentProps, parentId: i }))}
        </Widget>
      </ErrorBoundary>
    </WidgetContainer>
  );
}
