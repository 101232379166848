import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import moment, { MomentInputObject } from 'moment';
import { pick } from 'ramda';
import { LineChartOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import queryString from 'query-string';
import { StyledTable } from '../../styled';
import EditDiv from '../../Editing/EditDiv';
import {
  ChildProps,
  FetchTagValuesParams,
  RowRedirect,
  TableColumnType,
  WidgetTableWithChildrenProps,
} from './types';
import ChildChart from './ChildChart';
import { GlobalContext } from '../../../GlobalContext';
import useFetch from '../../hooks/useFetch';
import usePrevious from '../../hooks/usePrevious';
import { useAppSelector } from '../../../store/hooks';
import { Asset } from '../../../global.interfaces';

// TODO: Investigate overwriting antd types
// Also remove uses of "as" operator
// For now, passing incompatible types as "any" then using "as <actual type>"

export default ({
  id,
  parentId,
  props,
  config,
  match,
  location,
  altitudeGroupId,
}: WidgetTableWithChildrenProps): ReactElement => {
  const { endpoint, integration, tags } = config;
  const {
    pagination = {},
    verticalTable = false,
    childEndpoint,
    nestedColumns,
    timestampDataIndex,
    showHeader,
    bordered,
    columns,
    title,
    chartableProps,
  } = props;

  const [data, setData] = useState([]);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [fetchTagValuesParams, setFetchTagValuesParams] = useState<FetchTagValuesParams | null>(
    null,
  );
  const prevFetchTagValuesParams = usePrevious(fetchTagValuesParams);
  const editableLayout = useAppSelector((state) => state.layouts.editing.editable_layout);
  const { assetList } = useContext(GlobalContext);

  const { end: dateEnd, start: dateStart } = queryString.parse(location.search);

  const fetchParams = integration
    ? {
        baseUrl: 'https://dsd-dev.flogistix.com',
        headers: {
          'x-api-key': 'egioIe5Xq61pdQHPJ1jL1aCzD0XylWps6eIlOA08',
          'Content-Type': 'application/json',
        },
      }
    : {};

  const [loading, values, fetchTagValues] = useFetch(endpoint, fetchParams);
  const [childLoading, childValues, fetchChildValues] = useFetch(childEndpoint);

  useEffect(() => {
    const asset = assetList.find(
      (asset: Asset) => asset.asset_id === parseInt(match.params.asset_id || ''),
    );

    const apiParams = {
      ...config,
      end: dateEnd,
      start: dateStart,
      unit_number: asset?.asset_name || '',
    };

    setFetchTagValuesParams({ ...match.params, ...apiParams, filters: altitudeGroupId });
  }, [altitudeGroupId, assetList, config, dateEnd, dateStart, location.pathname, match.params]);

  useEffect(() => {
    if (fetchTagValuesParams && prevFetchTagValuesParams !== fetchTagValuesParams) {
      fetchTagValues(fetchTagValuesParams);
    }
  }, [fetchTagValues, fetchTagValuesParams, prevFetchTagValuesParams]);

  const mapHorizontalData = useCallback(
    () =>
      values.map((value: any, key: number) => ({
        ...value,
        key,
        timestamp: timestampDataIndex
          ? moment(value[timestampDataIndex] as MomentInputObject).format('MM/DD/YYYY hh:mm:ss')
          : undefined,
      })),
    [timestampDataIndex, values],
  );

  // TODO: This checks for existence of tags
  // but in practice they'll be present whenever verticalTable is true
  // Revisit when I know how to write that in a way that makes TS happy
  const mapVerticalData = useCallback(
    () =>
      Object.entries(pick(Object.keys(tags || {}), values[0]))
        .map((asset, index) => ({
          label: asset[0],
          value: asset[1],
          tag_id: tags ? tags[asset[0]].tag_id : '',
          index,
          order: tags ? tags[asset[0]].order : 0,
        }))
        .sort((a, b) => {
          return (a.order || 0) - (b.order || 0);
        }),
    [tags, values],
  );

  useEffect(() => {
    if (!loading && values) {
      setData(verticalTable ? mapVerticalData() : mapHorizontalData());
    }
  }, [
    loading,
    values,
    tags,
    timestampDataIndex,
    verticalTable,
    mapVerticalData,
    mapHorizontalData,
  ]);

  // TODO: This sucks
  // Can't be hard coded like this in the future. Workorder/JSA Flow will need improved
  const workorderRedirect = ({ id, hash }: RowRedirect, column: TableColumnType) =>
    // eslint-disable-next-line no-template-curly-in-string
    window.open(column.render?.redirect?.replace('${id}', id).replace('${hash}', hash), '_blank');

  const interpretColumnInfo = (columns: Array<TableColumnType>) =>
    columns.map((column: TableColumnType) => {
      if (column.render?.action === 'redirect') {
        return {
          ...column,
          render: (row: RowRedirect) => (
            <button onClick={() => workorderRedirect(row, column)} type="button">
              {column.render?.buttonTitle}
            </button>
          ),
        };
      }
      return column;
    });

  const additionalChartableProps = {
    rowKey: 'index',
    dataSource: data.map(
      // Before, it only converted 0 to 0.00
      // It was likely meant to convert everything except 0
      // Reversed the condition for now
      (x: { value?: number | string }) =>
        x.value && typeof x.value === 'number' ? { ...x, value: x.value.toFixed(2) } : x,
    ),
    columns,
    expandable: {
      expandedRowKeys: expanded,
      expandedRowRender: (childProps: any) => (
        <ChildChart
          childProps={childProps as ChildProps}
          handleCancel={() => setExpanded([])}
          isPopup={!!chartableProps?.popup}
          isVisible={expanded.includes(childProps.index)}
          props={props}
        />
      ),
      expandRowByClick: true,
      expandIcon: () => <LineChartOutlined />,
      onExpand: (_: boolean, { index }: any) => setExpanded([index as string]),
    },
  };

  const additionalNestedProps = {
    dataSource: data,
    columns,
    expandable: {
      expandedRowRender: () => (
        <Table
          loading={childLoading}
          dataSource={childValues}
          columns={nestedColumns}
          pagination={false}
        />
      ),
      onExpand: (_: boolean, { key, rule_uuid }: any) => {
        if (expanded.includes(key as string)) {
          setExpanded([]);
        } else {
          setExpanded([key as string]);
          fetchChildValues({ rule_uuid: rule_uuid as string });
        }
      },
      expandedRowKeys: expanded,
    },
  };

  const additionalOtherProps = {
    scroll: { x: 360 },
    rowKey: 'index',
    dataSource: data,
    columns: interpretColumnInfo(columns),
  };

  let additionalProps;
  if (chartableProps?.chartable) additionalProps = additionalChartableProps;
  else if (nestedColumns) additionalProps = additionalNestedProps;
  else additionalProps = additionalOtherProps;

  return (
    <>
      {editableLayout === parentId && <EditDiv id={id} />}
      <StyledTable
        bordered={bordered}
        loading={loading}
        pagination={pagination}
        rowClassName="default"
        showHeader={showHeader}
        title={title ? () => <h2>{title}</h2> : undefined}
        {...additionalProps}
      />
    </>
  );
};
