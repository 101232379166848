import styled from 'styled-components';
import { Form } from 'antd';

const StyledFormItem = styled(Form.Item)`
  &.ant-form-item-has-error {
    .ant-picker,
    .ant-picker:hover {
      background-color: ${(props) => props.theme.widgets.background.light};
    }
  }

  label {
    color: ${(props) => props.theme.widgets.color.full};

    &::after {
      color: ${(props) => props.theme.widgets.color.full};
    }
  }
`;

export default StyledFormItem;
