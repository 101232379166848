import React from 'react';

const ScheduleTimeComponent = ({ setScheduledTime }) => {
  const handleSubmit = (e) => {
    setScheduledTime(e.target.value);
  };

  return (
    <section className="report-section">
      <p className="displayed-options-title">Time</p>
      <input type="time" className="schedule-time-container" onChange={(e) => handleSubmit(e)} />
    </section>
  );
};

export default ScheduleTimeComponent;
