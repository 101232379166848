import React, { useState } from 'react';
import { Button } from 'antd';
import { SettingFilled } from '@ant-design/icons';

import GasLiftModal from 'single-asset/components/GasLiftModal';
import WellInformationEdit from 'single-asset/components/WellInformationEdit';

import './WellButtonGroup.scss';

const WellButtonGroup = ({
  wellId,
  well,
  selected,
  onWellSelected,
  saveWellConfig,
  isDisabled,
}) => {
  const [showConfiguration, setShowConfiguration] = useState(false);
  const needsConfiguration = !well;
  const baseClassName = 'WellButtonGroup';
  const className = needsConfiguration && `${baseClassName} needs-configuration`
    || selected && `${baseClassName} selected`
    || baseClassName;

  const hideConfiguration = () => {
    setShowConfiguration(false);
  };
  const revealConfiguration = () => {
    setShowConfiguration(true);
  };

  const handleButtonSelected = () => {
    !needsConfiguration && onWellSelected(wellId);
  };

  const handleUpdateWell = (config) => {
    saveWellConfig(config);
    hideConfiguration();
  };

  return (
    <>
      <div className={className}>
        <Button
          className="flo-button selectionButton"
          onClick={handleButtonSelected}
          disabled={isDisabled}
        >
          {well?.title ?? `ConfigureWell${wellId}`}
        </Button>
        <Button
          icon={<SettingFilled />}
          className="flo-button configurationButton"
          onClick={revealConfiguration}
          disabled={isDisabled}
        />
      </div>
      <GasLiftModal
        showModal={showConfiguration}
        onClose={hideConfiguration}
      >
        <WellInformationEdit
          updateWell={handleUpdateWell}
          well={well}
          onCancelEdit={hideConfiguration}
          needsConfiguration={needsConfiguration}
        />
      </GasLiftModal>
    </>
  );
};

export default WellButtonGroup;
