import styled from 'styled-components';
import { Modal } from 'antd';

const StyledModal = styled(Modal)`
  ${(props) => `
    & .ant-modal-header {
      background: ${props.theme.widgets.background.full};
      border-bottom: ${props.theme.widgets.border.border.full};

      & .ant-modal-title {
        color: ${props.theme.widgets.color.full};
        color: ${props.theme.widgets.color.full};
      }
    }
    & .ant-modal-content {
      background: ${props.theme.widgets.background.full};
    }
    & .ant-modal-body {
      background: ${props.theme.widgets.background.full};

      & .ant-select-selector {
        background: ${props.theme.widgets.background.light};
        color: ${props.theme.widgets.color.full};
        border: ${props.theme.widgets.border.border.light};
      }

      & label {
        color: ${props.theme.widgets.color.full};
      }
    }
    & .ant-modal-footer {
      background: ${props.theme.widgets.background.full};
      border-top: ${props.theme.widgets.border.border.full}; 
    }

  `}
`;

export default StyledModal;
