/* eslint-disable @typescript-eslint/ban-types */

import React, { useContext, useState, useEffect, ReactElement, useCallback } from 'react';
import { Button, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'react-router-dom';

import { getSingleAssetDefaultRoute } from 'shared/helpers/altitudeHelpers';
import { GlobalContext } from '../../GlobalContext';
import FilterComp from './FilterComp';
import { useListFilter } from '../hooks/useFilter';
import { Header, Container, FilterTagsContainer } from './styles';
import { StyledTable } from '../styled';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import useColumns from './useColumns';
import { SET_FILTER_TAGS } from '../../store/assetList';

const AssetList = (): ReactElement => {
  const history = useHistory();
  const globalFilterTags = useAppSelector((state) => state.assetList.filter_tags);
  const { round } = useAppSelector((state) => state.resources.themeStyles);

  const { assetList } = useContext(GlobalContext);

  const dispatch = useAppDispatch();
  const setGlobalFilterTags = useCallback(
    (payload: Array<string>) => dispatch({ type: SET_FILTER_TAGS, payload }),
    [dispatch],
  );

  const [filterTags, setFilterTags] = useState(globalFilterTags);
  const [taggedList, setTaggedList] = useState(assetList);
  const filters = ['asset_name', 'site_name', 'status', 'driver', 'org_name', 'site_name', 'model'];
  const { filteredList, setFilter } = useListFilter(taggedList, filters);

  // Checks for Asset List on Mount.
  // When Asset List loads in, set it to state as the { taggedList }
  useEffect(() => {
    if (assetList.length > 0) {
      setTaggedList(assetList);
    }
  }, [assetList]);

  // Checks global filters
  // if global filters exist on mount:
  // add the global filter to the useFilter to get back the { filteredList }
  useEffect(() => {
    if (globalFilterTags.length > 0) {
      setFilter(globalFilterTags[0]);
    }
  }, [globalFilterTags, setFilter]);

  // Checks for filterTags list changes
  // if there are no global or local filters: set to original Asset List
  // if filters exist: Apply the { filteredList } to our { taggedList }
  useEffect(() => {
    if (globalFilterTags.length === 0 && filterTags.length === 0) {
      setTaggedList(assetList);
    } else {
      setTaggedList(filteredList);
    }
  }, [assetList, filterTags, globalFilterTags.length, filteredList.length]);

  // Checks the { filteredList } for changes
  // Might be a better way to handle this, but it works.
  // If { globalFilters } exist: wait until they are applied and our { filteredList } is updated.
  // When the { filteredList } is updated, we want to mirror those changes to the { taggedList }
  // After the { globalFilters } are apploed to the local { filterTags }, remove them from Globals.
  useEffect(() => {
    if (globalFilterTags.length > 0) {
      if (filteredList.length !== taggedList.length) {
        setTaggedList(filteredList);
        setFilterTags(Array.from(new Set([...filterTags, ...globalFilterTags])));
        setGlobalFilterTags([]);
      }
    }
  }, [filterTags, filteredList, globalFilterTags, setGlobalFilterTags, taggedList.length]);

  // Clears all filters and sets the Tagged list back to
  // entire asset list.
  const handleFilterClear = () => {
    setFilter('');
    setFilterTags([]);
    setTaggedList(assetList);
  };

  const columns = useColumns(filteredList);

  return (
    <Container rounded={round}>
      <Header> Assets </Header>
      <FilterComp setFilterTags={setFilterTags} filterTags={filterTags} setFilter={setFilter} />
      <FilterTagsContainer>
        {filterTags.length > 0 && (
          <Button type="link" onClick={handleFilterClear}>
            {' '}
            Clear Filters{' '}
          </Button>
        )}
        {filterTags.map((t, index) => (
          <Tag color="volcano" key={index}>
            {' '}
            {t}{' '}
          </Tag>
        ))}
      </FilterTagsContainer>
      <StyledTable
        rowClassName="default"
        dataSource={filteredList}
        rowKey="asset_id"
        columns={columns as ColumnsType<object>}
        scroll={{ x: 1500 }}
        onRow={(rowProps: object) => {
          const route = getSingleAssetDefaultRoute(rowProps as any);

          return {
            onClick: () => history.push(route),
          };
        }}
      />
    </Container>
  );
};

export default AssetList;
