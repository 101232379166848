const widgetRemovalState = {
  remove_id: null,
};

const widgetEditingState = {
  editable_layout: '',
};

const widgetAddState = {
  add_layout: '',
  current_widget_to_add: {
    widget: null,
    builder: null,
  },
};

const initialState = {
  update_layout: false,
  removal: widgetRemovalState,
  editing: widgetEditingState,
  adding: widgetAddState,
};

const UPDATE_LAYOUT_TRIGGER = 'UPDATE_LAYOUT_TRIGGER';
const SET_EDITABLE_LAYOUT = 'SET_EDITABLE_LAYOUT';
const SET_WIDGET_TO_ADD = 'SET_WIDGET_TO_ADD';
const SET_ADD_LAYOUT = 'SET_ADD_LAYOUT'
const SET_REMOVE_ID = 'SET_REMOVE_ID'

export const reducer = (state = initialState, { type, payload }) => {
  switch(type) {
    case SET_EDITABLE_LAYOUT: 
      return {
        ...state,
        editing: {
          ...state.editing,
          editable_layout: payload
        }
      }
    case SET_WIDGET_TO_ADD:
      return {
        ...state,
        adding: {
          ...state.adding,
          current_widget_to_add: {
            ...state.adding.current_widget_to_add,
            ...payload
          }
        }
      }
    case SET_ADD_LAYOUT:
      return {
        ...state,
        adding: {
          ...state.adding,
          add_layout: payload
        }
      }
    case SET_REMOVE_ID:
      return {
        ...state,
        removal: {
          ...state.removal,
          remove_id: payload
        }
      }
    case UPDATE_LAYOUT_TRIGGER:
      return {
        ...state,
        update_layout: payload
      }
    default: 
      return state;
  }
};
