import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Form, message } from 'antd';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import { GlobalContext } from '../../GlobalContext';
import { ContainerDiv, ReportOptionsCheckboxGroup, StyledH1, StyledHR } from './styles';
import useReportAPI from './useReportAPI';
import {
  ImmediateReportFormValues,
  OrgTuple,
  ReportOptions,
  ScheduledReportFormValues,
  StringifiedBoolean,
} from './types';
import ScheduledFields from './ScheduledFields';
import { toCronUtc } from './cronUtils';
import { ALL_AREAS, checkboxOptions } from './constants';
import { StyledButton, StyledFormItem, StyledRangePicker, StyledSelect } from '../styled';
import { useAppSelector } from '../../store/hooks';
import FullScreenLoader from '../../FullScreenLoader';

import ReportsContainerComponent from '../reports/ReportsComponent';
import ScheduledReportsComponent from '../reports/scheduled-reports/ScheduledReportsComponent';

import '../reports/styles.scss';

const { useForm } = Form;

function Report(): ReactElement {
  const { round } = useAppSelector((state) => state.resources.themeStyles);
  const [form] = useForm();
  const noLabelWrapperCol = { span: 24 };
  const [orgs, setOrgs] = useState<Array<OrgTuple>>([]);
  const [areas, setAreas] = useState<Array<string>>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const { accessToken } = useContext(GlobalContext);
  const {
    canAccessReports,
    deleteScheduledReport,
    fetchAreas,
    fetchOrgs,
    postImmediateReport,
    postScheduledReport,
    scheduledReportData,
  } = useReportAPI();

  useEffect(() => {
    async function getOrgs() {
      setOrgs(await fetchOrgs());
    }

    if (accessToken) getOrgs();
  }, [accessToken, fetchOrgs]);

  async function handleCustomerChange(value: number) {
    const areas = await fetchAreas(value);
    areas.unshift(ALL_AREAS);
    setAreas(areas);
  }

  const mapReportOptions = (reportOptions: Array<keyof ReportOptions>) =>
    checkboxOptions.reduce((acc: any, { value: key }: any) => {
      acc[key] = reportOptions.includes(key).toString() as StringifiedBoolean;
      return acc;
    }, {} as ReportOptions);

  async function handleReportSubmit(submitCallback: () => Promise<Response>) {
    try {
      setIsSubmitting(true);
      const response = await submitCallback();

      if (!response.ok) throw new Error();

      // .ant-message-notice-content
      message.success('Your report is on its way!');
    } catch (error) {
      message.error('Error generating report.');
    }

    setIsSubmitting(false);
  }

  async function handleImmediateFinish(values: ImmediateReportFormValues) {
    const { area, dates, reportOptions, ...restOfValues } = values;
    const dateFormat = 'M-D-YYYY';

    handleReportSubmit(() =>
      postImmediateReport({
        ...restOfValues,
        ...mapReportOptions(reportOptions),
        area: area === ALL_AREAS ? '' : area,
        startTime: dates[0].format(dateFormat),
        endTime: dates[1].format(dateFormat),
      }),
    );
  }

  async function handleScheduledFinish(values: ScheduledReportFormValues) {
    const {
      area,
      orgId,
      reportOptions,
      frequency,
      time = moment('12:00 AM'),
      dayOfWeek = 0,
      userReportName,
    } = values;

    handleReportSubmit(() =>
      postScheduledReport({
        reportName: 'flux-fleet', // hard-coded until we get other report types
        userReportName,
        cronUtc: toCronUtc({ dayOfWeek, frequency, time }),
        options: {
          ...mapReportOptions(reportOptions),
          area: area === ALL_AREAS ? '' : area,
          orgId,
        },
      }),
    );
  }

  const handleFinish = (values: ImmediateReportFormValues & ScheduledReportFormValues) => {
    if (isScheduled) handleScheduledFinish(values);
    else handleImmediateFinish(values);
  };

  const areOrgsEmpty = orgs?.length === 0;
  const areAreasEmpty = areas?.length <= 1;

  const orgOptions = !areOrgsEmpty
    ? orgs.map(([orgId, orgName]) => ({ value: orgId, label: orgName }))
    : [];

  if (canAccessReports === undefined) return <FullScreenLoader loading />;

  return !canAccessReports ? <Redirect to="/" /> : <ReportsContainerComponent />;
}

export default Report;
