import React, { useEffect, useState, useContext } from 'react';
import Modal from 'react-modal';

import { GlobalContext } from '../../../GlobalContext';

import { ReportsRepository } from '../../../shared/repository/report-repository/ReportsRepository';

const ScheduledReportModal = ({
  setNameOfReportToDelete,
  showScheduledReport,
  setShowScheduledReport,
  displayedScheduledReport,
  setShowConfirmation,
  showConfirmation,
}) => {
  const { accessToken } = useContext(GlobalContext);
  const [currentToken] = useState(accessToken);
  const [currentOrganization, setOrg] = useState('');

  // DISPLAYED ORG NAME
  useEffect(() => {
    const getOrganization = async () => {
      const reportId =
        displayedScheduledReport.options && displayedScheduledReport.options.orgId
          ? displayedScheduledReport.options.orgId
          : 'no org id';
      const orgsObject = await ReportsRepository.getOrganizations(currentToken);
      const orgs = orgsObject.orgs;
      const filtered = orgs.filter((o) => o.orgId == reportId);
      filtered[0] && filtered[0].orgName && setOrg(filtered[0].orgName);
    };

    getOrganization();
  }, [displayedScheduledReport]);

  const optionEntries = displayedScheduledReport.options
    ? Object.entries(displayedScheduledReport.options)
    : [];

  const options = optionEntries.map((option) => {
    if (option[1] === true && option[0] !== 'hasAdditionalProperties') {
      return option[0];
    }
    return 'option';
  });

  const areaEntries = optionEntries.map((o) => {
    if (o[0] == 'area') {
      return o[1];
    }

    return 'option';
  });

  // TODO: need to make dynamic then add to the displayed report options - will do with IP Edit stuff - KH

  // const selectOptions = optionEntries.map((option) => {
  //   if (option[0] === 'equipmentDriver') {
  //     return option[1];
  //   }
  //   return 'option';
  // });

  const reportName =
    displayedScheduledReport.options && displayedScheduledReport.options.reportName
      ? displayedScheduledReport.options.reportName
      : 'no report name';

  const userReportName = displayedScheduledReport.userReportName || 'no user report name';

  const includedOptions = options.filter((o) => o !== 'option');
  const area =
    areaEntries.filter((o) => o !== 'option')[0] &&
    areaEntries.filter((o) => o !== 'option')[0].length
      ? areaEntries.filter((o) => o !== 'option')[0]
      : 'All';

  const splitCronData =
    displayedScheduledReport && displayedScheduledReport.cronUtc
      ? displayedScheduledReport.cronUtc.split(' ')
      : '';
  const date = new Date();
  const offsetInHours = Number(date.getTimezoneOffset() / 60);

  // doesn't like using useState on these lets - throws 'too many renders' error
  // open to suggestions on handling cronData better
  let frequency = 'Daily';
  let day = '0';
  let time = '';

  const minutes =
    splitCronData[0] > 10 ? splitCronData[0].toString().padStart(2, '0') : splitCronData[0];
  const hours = Number(splitCronData[1]) - offsetInHours || '*';
  const monthDay = splitCronData[2] || '*';
  const weekDay = splitCronData[4] || '*';

  if (weekDay !== '*') {
    frequency = 'Weekly';
    day = weekDay;
  }

  if (monthDay !== '*') {
    frequency = 'Monthly';
    day = monthDay;
  }

  if (minutes !== '*' || hours !== '*') {
    if (hours > 12) {
      time = hours - 12 + ':' + minutes + ' p.m.';
    }

    if (hours <= 12) {
      time = hours + ':' + minutes + ' a.m.';
    }
  }

  if (frequency === 'Weekly') {
    switch (day) {
      case '0':
        day = 'Sunday';
        break;
      case '1':
        day = 'Monday';
        break;
      case '2':
        day = 'Tuesday';
        break;
      case '3':
        day = 'Wednesday';
        break;
      case '4':
        day = 'Thursday';
        break;
      case '5':
        day = 'Friday';
        break;
      case '6':
        day = 'Saturday';
        break;
      default:
        break;
    }
  }

  if (frequency === 'Monthly') {
    switch (day) {
      case '1':
        day = `${day} st`;
        break;
      case '2':
        day = `${day} nd`;
        break;
      case '3':
        day = `${day} rd`;
        break;
      case '21':
        day = `${day} st`;
        break;
      case '22':
        day = `${day} nd`;
        break;
      case '23':
        day = `${day} rd`;
        break;
      default:
        day = `${day} th`;
        break;
    }
  }

  const displayedIncludedOptions = includedOptions.map((option) => {
    const splitName = option.split(/(?=[A-Z])/);
    const formattedName =
      splitName.length > 2
        ? splitName[0].charAt(0).toUpperCase() +
          splitName[0].slice(1) +
          ' ' +
          splitName[1] +
          ' ' +
          splitName[2]
        : splitName[0].charAt(0).toUpperCase() + splitName[0].slice(1) + ' ' + splitName[1];

    return (
      <p key={option} className="scheduled-report-row-content">
        {formattedName}
      </p>
    );
  });

  // NOTE: Removing the explicit " > 0 " on this length check causes visual artifact in UI
  const optionsComponent = (
    <>
      {displayedIncludedOptions.length > 0 && (
        <>
          <div className="scheduled-report-row">
            <h3 className="scheduled-report-row-title">Report Options</h3>
            <p className="scheduled-report-row-content">{displayedIncludedOptions.map((o) => o)}</p>
          </div>
          <div className="gap"></div>
        </>
      )}
    </>
  );

  const handleDelete = () => {
    setNameOfReportToDelete(userReportName);
    showScheduledReport && setShowScheduledReport(!showScheduledReport);
    return setShowConfirmation(!showConfirmation);
  };

  const closeModal = () => {
    setShowScheduledReport(!showScheduledReport);
  };

  return (
    <Modal
      overlayClassName="scheduled-report-modal-overlay"
      className="scheduled-report-modal"
      isOpen={showScheduledReport === true}
      onRequestClose={() => closeModal()}
    >
      <div className="scheduled-report-header">
        <p className="scheduled-report-header-text">Your Scheduled Report</p>
        <p className="scheduled-report-close" onClick={() => closeModal()}>
          X
        </p>
      </div>
      <p className="scheduled-report-title">{userReportName}</p>
      <div className="scheduled-report-row">
        <h3 className="scheduled-report-row-title">Customer</h3>
        <p className="scheduled-report-row-content">{currentOrganization || 'LOADING'}</p>
      </div>
      <div className="gap"></div>
      <div className="scheduled-report-row">
        <h3 className="scheduled-report-row-title">Report Type</h3>
        <p className="scheduled-report-row-content">{reportName}</p>
      </div>
      <div className="gap"></div>
      {optionsComponent}
      <div className="scheduled-report-row">
        <h3 className="scheduled-report-row-title">Area</h3>
        <p className="scheduled-report-row-content">{area}</p>
      </div>
      <div className="gap"></div>
      <div className="scheduled-report-row">
        <h3 className="scheduled-report-row-title">Frequency</h3>
        <p className="scheduled-report-row-content">{frequency}</p>
        {frequency !== 'Daily' && <p className="scheduled-report-row-content">{day}</p>}
        <p className="scheduled-report-row-content">{time.toString()}</p>
      </div>
      <div className="gap"></div>
      <div
        onClick={() => handleDelete()}
        className="displayed-report-icon displayed-report-delete-icon"
      ></div>
    </Modal>
  );
};

export default ScheduledReportModal;
