import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';

import { GlobalContext } from '../../GlobalContext';

import { ReportsRepository } from '../../shared/repository/report-repository/ReportsRepository';

import ReportOptionsModal from './report-options-modal/ReportOptionsModal';
import ReportContainerComponent from './report-form/ReportContainerComponent';
import ReportErrorModal from './report-error-modal/ReportErrorModal';
import ScheduledReportsComponent from './scheduled-reports/ScheduledReportsComponent';
import ScheduledReportModal from './scheduled-reports/ScheduledReportModal';

import ReportConfirmationModal from './report-confirmation-modal/ReportConfirmationModal';
import AlertModal from './report-alert-modal/AlertModal';

const ReportsComponent = () => {
  const { accessToken } = useContext(GlobalContext);
  const [currentToken] = useState(accessToken);
  const [organizations, setOrganizations] = useState([]);
  const [areas, setAreas] = useState([]);
  const [reports, setReports] = useState([]);
  const [defaultReports, setDefaultReports] = useState([]);
  const [scheduledReports, setScheduledReports] = useState([]);
  const [displayedScheduledReport, setDisplayedScheduledReport] = useState({});
  const [showSchedule, setShowSchedule] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showScheduledReport, setShowScheduledReport] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedType, setSelectedType] = useState('');

  const currentReport =
    reports &&
    reports.filter((r) => r.title === selectedType)[0] &&
    reports.filter((r) => r.title === selectedType)[0].length
      ? reports.filter((r) => r.title === selectedType)[0]
      : '';
  const reportOptions =
    currentReport && currentReport.options && currentReport.options.properties
      ? currentReport.options.properties
      : [];

  const allOptionEntries = Object.values(reportOptions);
  const dynamicFormData = allOptionEntries.map((entry) => {
    const newPropName = entry.name;
    const newPropFormat = entry.format;
    const newPropValue = newPropFormat === 'checkbox' || newPropFormat === 'none' ? true : '';
    return { [newPropName]: newPropValue };
  });

  const [formData, setFormData] = useState(dynamicFormData);
  const [currentFrequency, setCurrentFrequency] = useState('Monthly');
  const [currentScheduledDay, setScheduledDay] = useState('1');
  const [nameOfReportToDelete, setNameOfReportToDelete] = useState('');
  const [currentScheduledTime, setScheduledTime] = useState('');
  const [currentErrorMessage, setErrorMessage] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  // ORGANIZATIONS
  useEffect(() => {
    const getOrganizations = async () => {
      const orgsObject = await ReportsRepository.getOrganizations(currentToken);
      const orgs = orgsObject.orgs;
      const filtered = orgs.filter((o) => o.orgId != -1);
      setOrganizations(filtered);

      setFormData({ ...formData, orgId: Number(filtered[0].orgId) });
    };

    getOrganizations();
  }, []);

  // AREAS
  useEffect(() => {
    const getAreas = async () => {
      const uniqueAreas = ['All'];
      const id = Number(formData.orgId);
      const availableAreas = await ReportsRepository.getAreas(currentToken, id);

      if (availableAreas && availableAreas.length) {
        availableAreas.map((a) => {
          if (!uniqueAreas.includes(a.area)) {
            return uniqueAreas.push(a.area);
          }
          return;
        });
      }

      setAreas(uniqueAreas);
    };

    getAreas();
  }, [formData]);

  // REPORTS
  useEffect(() => {
    const getReports = async () => {
      const reportsResponse = await ReportsRepository.getReports(currentToken);
      const availableReports = reportsResponse.reports.map((report) => report);
      setReports(availableReports);
      setDefaultReports(availableReports);
    };

    getReports();
  }, [currentToken]);

  // SCHEDULED REPORTS
  useEffect(() => {
    const getScheduledReports = async () => {
      const reportsResponse = await ReportsRepository.getScheduledReports(currentToken);
      const scheduledReports = reportsResponse.reports.map((report) => report);
      setScheduledReports(scheduledReports);
    };

    getScheduledReports();
  }, [currentToken]);

  const generateCronData = ({ frequency, day, time }) => {
    const date = new Date();
    const offsetInHours = date.getTimezoneOffset() / 60;
    const timeString = time && time._i ? time._i : '1:00AM';
    const hours = Number(timeString.split(':')[0]) + offsetInHours;
    const minutes = timeString.split(':')[1];
    const cronMinutes = minutes;
    const cronHours = hours;
    const cronMonthDay = frequency === 'Monthly' ? day : '*';
    const cronMonth = '*';
    const cronWeekDay = frequency === 'Weekly' ? day : '*';

    const cronString = `${cronMinutes} ${cronHours} ${cronMonthDay} ${cronMonth} ${cronWeekDay}`;
    return cronString;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const selectReport = (report) => {
    const reportTitle = report.title;
    setSelectedType(reportTitle);
    setFormData({ ...formData, reportName: reportTitle });

    if (report.options.properties.hasAdditionalOptions) {
      setShowOptions(!showOptions);
    }
  };

  const handleReportCheckableChange = (options) => {
    let newFormData = formData;

    options.map((option) => {
      return (newFormData = { ...newFormData, [option.name]: option.value });
    });

    setFormData(newFormData);
  };

  const handleReportSelectChange = (option) => {
    setFormData({ ...formData, [option.name]: option.value });
  };

  const submitReport = async () => {
    const formattedArea = formData.area ? formData.area : '';
    const formattedOrgId = formData.orgId ? Number(formData.orgId) : '';
    const reportName = formData.reportName;

    // format dates
    const format = 'YYYY-MM-DD';
    const formattedStart = formData.startTime ? moment(formData.startTime).format(format) : '';
    const formattedEnd = formData.endTime ? moment(formData.endTime).format(format) : '';

    // check dates
    const date = new Date().toISOString().split('T')[0];

    // check organization(s)
    if (formattedOrgId === '' && organizations && organizations.length === 1) {
      dynamicFormData.orgId = organizations[0].value;
    }

    // check for errors - this process could be abstracted and improved
    if (formattedOrgId === '' && organizations && organizations.length > 1) {
      setErrorMessage('Please select an organization.');
      setShowError(!showError);
      return;
    }

    if (formattedStart === '' || formattedEnd === '') {
      setErrorMessage('Please select a start and end date for your report');
      setShowError(!showError);
      return;
    }

    if (formattedStart >= formattedEnd) {
      setErrorMessage('Start and end dates must be in valid order.');
      setShowError(!showError);
      return;
    }

    if (!reportName) {
      setErrorMessage('Please select a report to run.');
      setShowError(!showError);
      return;
    }

    // dynamically generate AWS report name - could be improved here or field added to forms with dashed name syntax
    const splitName =
      reportName && reportName.length ? reportName.toLowerCase().split(' ') : 'no report name';
    let formattedName = `${splitName[0]}-${splitName[1]}`;

    if (splitName.length > 2) {
      formattedName = splitName[0] + '-' + splitName[1] + '-' + splitName[2];
    }

    // format data
    const formattedData = {
      ...formData,
      reportName: formattedName,
      orgId: formattedOrgId,
      area: formattedArea,
      startTime: formattedStart,
      endTime: formattedEnd,
    };

    await ReportsRepository.submitImmediateReport(currentToken, formattedData, formattedName);
    const reportsResponse = await ReportsRepository.getScheduledReports(currentToken);
    const scheduledReports = reportsResponse.reports.map((report) => report);
    setScheduledReports(scheduledReports);
    setAlertMessage('Report submitted successfully!');
    setShowAlert(!showAlert);
  };

  const submitScheduledReport = async () => {
    const time = currentScheduledTime !== '' ? moment(currentScheduledTime) : '';
    const day = currentScheduledDay;
    const frequency = currentFrequency;
    const formattedArea = formData.area ? formData.area : '';
    const formattedOrgId = Number(formData.orgId);
    const reportName = formData.reportName;
    const userReportName = formData.userReportName;
    const cronData = generateCronData({ frequency, time, day });

    // format dates
    const format = 'YYYY-MM-DD';
    const formattedStart = !showSchedule ? moment(formData.startTime).format(format) : '';
    const formattedEnd = !showSchedule ? moment(formData.endTime).format(format) : '';

    // organization checks
    if (dynamicFormData.orgId === '' && organizations && organizations.length === 1) {
      dynamicFormData.orgId = organizations[0].value;
    }
    if (dynamicFormData.orgId === '' && organizations && organizations.length > 1) {
      return console.log('NO ORG SELECTED');
    }

    // dynamically generate AWS report name - could be improved here or field added to forms with dashed name syntax
    const splitName =
      reportName && reportName.length ? reportName.toLowerCase().split(' ') : 'no report name';
    let formattedName = `${splitName[0]}-${splitName[1]}`;

    if (splitName.length > 2) {
      formattedName = splitName[0] + '-' + splitName[1] + '-' + splitName[2];
    }

    // check for entry errors
    if (!userReportName) {
      setErrorMessage('Please enter a name for your report.');
      setShowError(!showError);
      return;
    }

    if (!reportName) {
      setErrorMessage('Please select a report to run.');
      setShowError(!showError);
      return;
    }

    if (frequency === '') {
      setErrorMessage('Please select a schedule frequency for your report.');
      setShowError(!showError);
      return;
    }

    if (day === '' && frequency !== 'Daily') {
      setErrorMessage('Please select a scheduled day for your report.');
      setShowError(!showError);
      return;
    }

    if (time === '') {
      setErrorMessage('Please select a scheduled time for your report.');
      setShowError(!showError);
      return;
    }

    // format data
    const formattedData = {
      reportName: formattedName,
      userReportName: userReportName,
      cronUtc: cronData,
      options: {
        ...formData,
        area: formattedArea,
        orgId: formattedOrgId,
        startTime: formattedStart,
        endTime: formattedEnd,
      },
    };

    const res = await ReportsRepository.submitScheduledReport(currentToken, formattedData);

    if (res.ok) {
      const reportsResponse = await ReportsRepository.getScheduledReports(currentToken);
      const newScheduledReports = reportsResponse.reports.map((report) => report);
      setScheduledReports(newScheduledReports);
      setShowSchedule(!showSchedule);
      setAlertMessage('Report scheduled successfully!');
      setCurrentFrequency('Monthly');
      setScheduledDay('1');
      setFormData({ ...formData, startTime: '', endTime: '' });
      setShowAlert(!showAlert);
      return;
    }

    if (!res.ok) {
      setErrorMessage('No duplicate report names allowed.');
      setShowError(!showError);
      return;
    }
  };

  const deleteScheduledReport = async (userReportName) => {
    await ReportsRepository.deleteScheduledReport(currentToken, userReportName);
    const reportsResponse = await ReportsRepository.getScheduledReports(currentToken);
    const scheduledReports = reportsResponse.reports.map((report) => report);
    setScheduledReports(scheduledReports);
    setAlertMessage('Report deleted successfully!');
    setShowAlert(!showAlert);
  };

  return (
    <section className="large-container">
      <div className="report-container">
        <ReportContainerComponent
          organizations={organizations}
          areas={areas}
          reports={reports}
          defaultReports={defaultReports}
          selectedType={selectedType}
          showSchedule={showSchedule}
          showOptions={showOptions}
          currentFrequency={currentFrequency}
          currentErrorMessage={currentErrorMessage}
          frequency={currentFrequency}
          formData={formData}
          setFormData={setFormData}
          setReports={setReports}
          handleChange={handleChange}
          submitReport={submitReport}
          selectReport={selectReport}
          setShowSchedule={setShowSchedule}
          setShowOptions={setShowOptions}
          submitScheduledReport={submitScheduledReport}
          setCurrentFrequency={setCurrentFrequency}
          setScheduledDay={setScheduledDay}
          setScheduledTime={setScheduledTime}
          setErrorMessage={setErrorMessage}
        />
        {showOptions && (
          <ReportOptionsModal
            reports={reports}
            selectedType={selectedType}
            showOptions={showOptions}
            setShowOptions={setShowOptions}
            handleReportCheckableChange={handleReportCheckableChange}
            handleReportSelectChange={handleReportSelectChange}
            setSelectedType={setSelectedType}
          />
        )}
        <ReportErrorModal
          setErrorMessage={setErrorMessage}
          setShowError={setShowError}
          currentErrorMessage={currentErrorMessage}
          showError={showError}
        />
      </div>
      <ScheduledReportsComponent
        showScheduledReport={showScheduledReport}
        scheduledReports={scheduledReports}
        showConfirmation={showConfirmation}
        setShowScheduledReport={setShowScheduledReport}
        setDisplayedScheduledReport={setDisplayedScheduledReport}
        deleteScheduledReport={deleteScheduledReport}
        setErrorMessage={setErrorMessage}
        setNameOfReportToDelete={setNameOfReportToDelete}
        setShowConfirmation={setShowConfirmation}
      />
      <ScheduledReportModal
        showConfirmation={showConfirmation}
        displayedScheduledReport={displayedScheduledReport}
        showScheduledReport={showScheduledReport}
        deleteScheduledReport={deleteScheduledReport}
        setShowScheduledReport={setShowScheduledReport}
        setNameOfReportToDelete={setNameOfReportToDelete}
        setShowConfirmation={setShowConfirmation}
      />
      <ReportConfirmationModal
        nameOfReportToDelete={nameOfReportToDelete}
        showConfirmation={showConfirmation}
        setShowConfirmation={setShowConfirmation}
        deleteScheduledReport={deleteScheduledReport}
      />
      <AlertModal alertMessage={alertMessage} showAlert={showAlert} setShowAlert={setShowAlert} />
    </section>
  );
};

export default ReportsComponent;
