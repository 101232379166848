import { ReportOptions } from './types';

export const ALL_AREAS = 'All Areas';

export const checkboxOptions: { label: string; value: keyof ReportOptions }[] = [
  { label: 'Event Detail', value: 'eventDetail' },
  { label: 'Fault Code Summary', value: 'faultCodeSummary' },
  { label: 'Hours Summary', value: 'hoursSummary' },
  { label: 'Runhour Summary', value: 'runhourSummary' },
  { label: 'Runtime Summary', value: 'runtimeSummary' },
];

// Index should match cron value for day of week
export const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export enum Frequencies {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
}


const isProd = process.env.REACT_APP_BUILD_ENV === 'production';
const devPrefix = !isProd ? 'dev-' : '';
const localSuffix = process.env.REACT_APP_IS_LOCAL === "true" ? '/local' : '';
// TODO: Put back in environmental logic once prod env vars are live
const baseUrl = /* `https://${devPrefix}api.axil.ai${localSuffix}`; */ 'https://api.axil.ai';
export const iamUrl = `${baseUrl}/iam`;
export const reportingUrl = `${baseUrl}/reporting`;
export const orgUrl = `${iamUrl}/orgs`;
export const schedulesUrl = `${reportingUrl}/schedules`;
