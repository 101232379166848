import _ from 'lodash';

import * as sessionActionTypes from 'shared/actions/sessionActionTypes';

export const defaultState = null;

const sessionReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case sessionActionTypes.FLUX_APP__SET_SESSION: {
      return _.cloneDeep(action.payload);
    }

    case sessionReducer.FLUX_APP_CLEAR_SESSION: {
      return defaultState;
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;
