import React, { ReactElement } from 'react';
import { AlertOutlined, SlidersOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import {
  LegendWrapper,
  LegendDot,
  LegendItemWrapper,
  LegendName,
  LegendStat,
  LegendYAxisToggleWrapper,
  SingleLegendItem,
  LegendToggleWrapper,
} from './styles';
import { AggregateStats, LegendProps } from './types';

const Legend = ({ toggleEvents, toggleYAxis, data, config }: LegendProps): ReactElement => {
  const legendData = data?.agg ? Object.entries(data.agg) : null;

  const statDetails: Array<[string, keyof AggregateStats]> = [
    ['Min', 'min'],
    ['Max', 'max'],
    ['Avg', 'mean'],
  ];

  return (
    <LegendWrapper>
      {legendData && (
        <>
          <LegendItemWrapper>
            {legendData.map(
              ([key, value], i) =>
                value &&
                Object.keys(value).length > 0 && (
                  <SingleLegendItem key={i}>
                    <LegendDot color={config?.series[i]?.seriesProps?.lineColor} />
                    <LegendName> {key} </LegendName>
                    {statDetails.map(([statLabel, statProp]) => (
                      <LegendStat>
                        <span>{statLabel}: </span> {value[statProp]?.y.toFixed(2)}
                      </LegendStat>
                    ))}
                  </SingleLegendItem>
                ),
            )}
          </LegendItemWrapper>
          <LegendYAxisToggleWrapper>
            {legendData.length > 1 && (
              <Button
                onClick={() => toggleYAxis((state) => !state)}
                shape="circle"
                icon={<SlidersOutlined />}
              />
            )}
          </LegendYAxisToggleWrapper>
        </>
      )}
      {toggleEvents && (
        <LegendToggleWrapper>
          <Button
            onClick={() => toggleEvents((state) => !state)}
            shape="circle"
            icon={<AlertOutlined />}
          />
        </LegendToggleWrapper>
      )}
    </LegendWrapper>
  );
};

export default Legend;
