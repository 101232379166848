import styled from 'styled-components';
import { Button } from 'antd';

const StyledButton = styled(Button)`
  && {
    //DEFAULT STYLES
    color: ${(props) => props.theme.palette.buttons.default.color.full};
    background-color: ${(props) => props.theme.palette.buttons.default.background.full};
    border-color: ${(props) => props.theme.palette.buttons.default.border.full};

    :hover {
      color: ${(props) => props.theme.palette.buttons.default.color.light};
      background-color: ${(props) => props.theme.palette.buttons.default.background.light};
      border-color: ${(props) => props.theme.palette.buttons.default.border.light};
    }

    :focus {
      color: ${(props) => props.theme.palette.buttons.default.color.light};
      background-color: ${(props) => props.theme.palette.buttons.default.background.light};
      border-color: ${(props) => props.theme.palette.buttons.default.border.light};
    }

    :active {
      color: ${(props) => props.theme.palette.buttons.default.color.dark};
      background-color: ${(props) => props.theme.palette.buttons.default.background.dark};
      border-color: ${(props) => props.theme.palette.buttons.default.border.dark};
    }

    //PRIMARY STYLES
    &.ant-btn-primary {
      color: ${(props) => props.theme.palette.buttons.primary.color.full};
      background-color: ${(props) => props.theme.palette.buttons.primary.background.full};
      border-color: ${(props) => props.theme.palette.buttons.primary.border.full};
    }

    &.ant-btn-primary:hover {
      color: ${(props) => props.theme.palette.buttons.primary.color.light};
      background-color: ${(props) => props.theme.palette.buttons.primary.background.light};
      border-color: ${(props) => props.theme.palette.buttons.primary.background.light};
    }

    &.ant-btn-primary:focus {
      color: ${(props) => props.theme.palette.buttons.primary.color.light};
      background-color: ${(props) => props.theme.palette.buttons.primary.background.light};
      border-color: ${(props) => props.theme.palette.buttons.primary.background.light};
    }

    &.ant-btn-primary:active {
      color: ${(props) => props.theme.palette.buttons.primary.color.dark};
      background-color: ${(props) => props.theme.palette.buttons.primary.background.dark};
      border-color: ${(props) => props.theme.palette.buttons.primary.background.dark};
    }

    //SUCCESS STYLES
    &.ant-btn-success {
      color: ${(props) => props.theme.palette.buttons.success.color.full};
      background-color: ${(props) => props.theme.palette.buttons.success.background.full};
      border-color: ${(props) => props.theme.palette.buttons.success.border.full};
    }

    &.ant-btn-success:hover {
      color: ${(props) => props.theme.palette.buttons.success.color.light};
      background-color: ${(props) => props.theme.palette.buttons.success.background.light};
      border-color: ${(props) => props.theme.palette.buttons.success.border.light};
    }

    &.ant-btn-success:focus {
      color: ${(props) => props.theme.palette.buttons.success.color.light};
      background-color: ${(props) => props.theme.palette.buttons.success.background.light};
      border-color: ${(props) => props.theme.palette.buttons.success.border.light};
    }

    &.ant-btn-success:active {
      color: ${(props) => props.theme.palette.buttons.success.color.dark};
      background-color: ${(props) => props.theme.palette.buttons.success.background.dark};
      border-color: ${(props) => props.theme.palette.buttons.success.border.dark};
    }

    //DANGER STYLES
    &.ant-btn-danger {
      color: ${(props) => props.theme.palette.buttons.danger.color.full};
      background-color: ${(props) => props.theme.palette.buttons.danger.background.full};
      border-color: ${(props) => props.theme.palette.buttons.danger.border.full};
    }

    &.ant-btn-danger:hover {
      color: ${(props) => props.theme.palette.buttons.danger.color.light};
      background-color: ${(props) => props.theme.palette.buttons.danger.background.light};
      border-color: ${(props) => props.theme.palette.buttons.danger.border.light};
    }

    &.ant-btn-danger:focus {
      color: ${(props) => props.theme.palette.buttons.danger.color.light};
      background-color: ${(props) => props.theme.palette.buttons.danger.background.light};
      border-color: ${(props) => props.theme.palette.buttons.danger.border.light};
    }

    &.ant-btn-danger:active {
      color: ${(props) => props.theme.palette.buttons.danger.color.dark};
      background-color: ${(props) => props.theme.palette.buttons.danger.background.dark};
      border-color: ${(props) => props.theme.palette.buttons.danger.border.dark};
    }

    //LINK STYLES
    &.ant-btn-link {
      color: ${(props) => props.theme.palette.link.base};
      background-color: none;
      border: none;
      border-color: none;
    }

    &.ant-btn-link:hover {
      color: ${(props) => props.theme.palette.link.hovered};
      background-color: none;
      border: none;
      border-color: none;
    }

    &.ant-btn-link:focus {
      color: ${(props) => props.theme.palette.link.hovered};
      background-color: none;
      border: none;
      border-color: none;
    }

    &.ant-btn-link:active {
      color: ${(props) => props.theme.palette.link.hovered};
      background-color: none;
      border: none;
      border-color: none;
    }

    &.ant-btn-link:visited {
      color: ${(props) => props.theme.palette.link.disabled};
      background-color: none;
      border: none;
      border-color: none;
    }
  }
`;

export default StyledButton;
