import React, { useRef, useContext, useState, useEffect, ReactElement } from 'react';
import { RouteComponentProps, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { GlobalContext } from '../GlobalContext';
import migrations from '../utils/migrations';

import FullScreenLoader from '../FullScreenLoader';
import useFetch from './hooks/useFetch';
import BuilderModal from './Builders/BuilderModal';
import createWidget from '../utils/widgets';
import useWindowSize from './hooks/useWindowSize';
import EditConfirm from './Editing/EditConfirm';

// TYPES
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { DispatchFunc } from '../store/store.interfaces';
import { AltitudeMatchParams, Asset } from '../global.interfaces';
import useAltitudeMatchParamsCallback from './hooks/useAltitudeMatchParamsCallback';

const Template = (): ReactElement => {
  const match = useRouteMatch<AltitudeMatchParams>();
  const location = useLocation<RouteComponentProps>();
  const history = useHistory<RouteComponentProps>();
  const getAltitudeMatch = useAltitudeMatchParamsCallback();

  const [save, setSave] = useState<boolean>(false);
  const [cancel, setCancel] = useState<boolean>(false);
  const [template, setTemplate] = useState<boolean>(false);
  const [dashboardId, setDashboardId] = useState<number | null>(null);
  const [layoutObject, setLayoutObject] = useState({});
  const [activeLayout, setActiveLayout] = useState([]);
  const [size] = useWindowSize();
  const assetTypeId = useRef<number>(-1);

  const { assetList, altitudeGroupId } = useContext(GlobalContext);

  const [layoutLoading, layoutData, fetchLayout] = useFetch('/v1/axil/layouts/by_dashboard_id');
  const [deleteLoading, layoutDeleted, deleteLayout] = useFetch('/v1/axil/layouts/delete');

  const dispatch = useAppDispatch();
  const setResetLayoutToDefault: DispatchFunc<boolean> = (bool) =>
    dispatch({ type: 'RESET_LAYOUT_TO_DEFAULT', payload: bool });
  const setCurrentLayout: DispatchFunc<any[]> = (arr) =>
    dispatch({ type: 'SET_CURRENT_LAYOUT', payload: arr });

  const editMode = useAppSelector((state) => state.layouts.editing.editable_layout);
  const roundedTheme = useAppSelector((state) => state.resources.themeStyles.round);
  const resetTemplates = useAppSelector((state) => state.frame.reset_templates);
  const resetLayoutToDefault = useAppSelector((state) => state.frame.reset_layout_to_default);

  const storeDashboard = (layout: any): void => {
    if (size) {
      const updatedLayout = migrations(layout);
      setLayoutObject(updatedLayout);
      setActiveLayout(updatedLayout[size]);

      // FOR SIDE DRAWER USE
      setCurrentLayout(updatedLayout[size]);
    }
  };

  const resetToDefaultLayout = () => {
    if (resetTemplates) {
      const templateParams = {
        ...match.params,
        org_id: -1,
        site_id: -1,
        asset_id: -1,
        device_id: -1,
        asset_type_id: assetTypeId.current,
      };
      deleteLayout({ ...templateParams, dashboard_id: dashboardId });
    } else {
      deleteLayout({
        ...match.params,
        asset_type_id: assetTypeId.current,
        dashboard_id: dashboardId,
      });
    }
  };

  // Checks Path to determine what dashboard type it should fetch.
  useEffect(() => {
    if (assetList.length > 0) {
      const { asset_id } = match.params;
      if (asset_id) {
        const asset = assetList.find((a: Asset) => a.asset_id === parseInt(asset_id));
        if (asset) {
          assetTypeId.current = asset.asset_type_id;
        }
      }

      const layoutMap = [
        { id: 1, path: 'dashboard' },
        { id: 5, path: 'asset' },
        { id: 6, path: 'information' },
        { id: 7, path: 'events' },
        { id: 8, path: 'work_orders' },
        { id: 9, path: 'configuration' },
      ];

      layoutMap.map(({ id, path }) => {
        if (location.pathname.includes(path)) {
          return setDashboardId(id);
        }
        return null;
      });
    }
  }, [assetList, location.pathname]);

  useEffect(() => {
    if (dashboardId && size) {
      setActiveLayout([]);
      fetchLayout({
        ...match.params,
        dashboard_id: dashboardId,
        asset_type_id: assetTypeId.current,
      });
    }
  }, [
    dashboardId,
    size,
    getAltitudeMatch,
    assetTypeId.current,
  ]);

  useEffect(() => {
    if (resetLayoutToDefault) resetToDefaultLayout();
  }, [resetLayoutToDefault]);

  useEffect(() => {
    if (!layoutLoading && layoutData) storeDashboard(layoutData);
  }, [layoutLoading, layoutData]);

  useEffect(() => {
    if (!deleteLoading && layoutDeleted) {
      fetchLayout({
        ...match.params,
        dashboard_id: dashboardId,
        asset_type_id: assetTypeId.current,
      });
      setResetLayoutToDefault(false);
    }
  }, [deleteLoading, layoutDeleted]);

  return (
    <>
      <FullScreenLoader loading={layoutLoading} />
      {editMode.length > 0 && (
        <EditConfirm
          params={match.params}
          setSave={setSave}
          setCancel={setCancel}
          setTemplate={setTemplate}
        />
      )}
      <BuilderModal
        match={match}
        activeLayout={activeLayout}
        dashboardId={dashboardId}
        layoutObject={layoutObject}
        setActiveLayout={setActiveLayout}
      />
      {activeLayout.length > 0 &&
        activeLayout.map((el) =>
          createWidget(el, {
            size,
            save,
            match,
            cancel,
            setSave,
            history,
            template,
            location,
            setCancel,
            dashboardId,
            roundedTheme,
            layoutObject,
            activeLayout,
            altitudeGroupId,
            setActiveLayout,
            assetTypeId: assetTypeId.current,
          }),
        )}
    </>
  );
};

export default Template;
