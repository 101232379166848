import React from 'react';

import ScheduleReportSection from '../report-schedule-section/ScheduleReportSection';
import ReportLogoComponent from './ReportLogoComponent';
import ReportOrganizationComponent from './ReportOrganizationComponent';
import ReportDateComponent from './ReportDateComponent';
import ReportAreasComponent from './ReportAreasComponent';
import SearchReportsComponent from './ReportSearchComponent';
import ReportCardsComponent from './ReportCardsComponent';

const ReportContainerComponent = ({
  formData,
  setFormData,
  organizations,
  areas,
  reports,
  defaultReports,
  selectedType,
  showSchedule,
  handleChange,
  submitReport,
  setShowSchedule,
  setReports,
  showOptions,
  selectReport,
  submitScheduledReport,
  setCurrentFrequency,
  currentFrequency,
  setScheduledTime,
  setScheduledDay,
  frequency,
}) => {
  const scheduleButtonText = showSchedule ? 'Close Scheduling' : 'Schedule Report';

  const handleShowSchedule = () => {
    setShowSchedule(!showSchedule);
    setFormData({ ...formData, startTime: '', endTime: '' });
  };

  return (
    <article className="report-form-container">
      <ReportLogoComponent />
      <ReportOrganizationComponent handleChange={handleChange} organizations={organizations} />
      <div className="form-row">
        {areas.length > 1 && <ReportAreasComponent areas={areas} handleChange={handleChange} />}
        {!showSchedule && <ReportDateComponent handleChange={handleChange} />}
      </div>
      <SearchReportsComponent
        setReports={setReports}
        reports={reports}
        defaultReports={defaultReports}
      />
      <ReportCardsComponent
        reports={reports}
        selectedType={selectedType}
        selectReport={selectReport}
      />
      {showSchedule ? (
        <ScheduleReportSection
          frequency={frequency}
          showSchedule={showSchedule}
          showOptions={showOptions}
          setShowSchedule={setShowSchedule}
          currentFrequency={currentFrequency}
          submitScheduledReport={submitScheduledReport}
          handleChange={handleChange}
          setCurrentFrequency={setCurrentFrequency}
          setScheduledDay={setScheduledDay}
          setScheduledTime={setScheduledTime}
        />
      ) : (
        <>
          <button className="report-submit-button" onClick={() => submitReport()}>
            Submit
          </button>
          <button className="report-schedule-button" onClick={() => handleShowSchedule()}>
            {scheduleButtonText}
          </button>
        </>
      )}
    </article>
  );
};

export default ReportContainerComponent;
