import React from 'react';
import Modal from 'react-modal';

const ReportErrorModal = ({ showError, setErrorMessage, currentErrorMessage, setShowError }) => {
  const closeModal = () => {
    setErrorMessage('');
    setShowError(!showError);
  };

  return (
    <Modal
      overlayClassName="error-modal-overlay"
      className="error-modal"
      isOpen={showError === true}
      onRequestClose={() => closeModal()}
    >
      <article className="error-container">
        <div className="error-icon"></div>
        <h3 className="error-message">{currentErrorMessage}</h3>
        <div className="button-container">
          <button className="error-button" onClick={() => closeModal()}>
            Ok
          </button>
        </div>
      </article>
    </Modal>
  );
};

export default ReportErrorModal;
