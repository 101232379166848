import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LineChartOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { StyledButton } from '../../styled';
import EditDiv from '../../Editing/EditDiv';

const Container = styled.div`
  position: relative;
  && button {
    background: ${(props) => props.theme.widgets.background.light};
    color: ${(props) => props.theme.widgets.color.full};
    border: ${(props) => props.theme.widgets.border.border.light};
  }
`;

const SeriesBuilderShortcut = ({ id, parentId }) => {
  const editMode = useSelector((state) => state.layouts.editing.editable_layout);

  const dispatch = useDispatch();
  const setAddLayout = (layout) => dispatch({ type: 'SET_ADD_LAYOUT', payload: layout });
  const setWidgetToAdd = (widget) => dispatch({ type: 'SET_WIDGET_TO_ADD', payload: widget });

  const handleClick = () => {
    if (editMode) {
      return;
    }
    setAddLayout(parentId);
    setWidgetToAdd({ widget: 'WidgetSeries', builder: 'WidgetSeriesBuilder' });
  };

  return (
    <Container>
      {editMode && <EditDiv id={id} />}
      <StyledButton icon={<LineChartOutlined />} size="large" block onClick={handleClick}>
        Add Series Chart
      </StyledButton>
    </Container>
  );
};

SeriesBuilderShortcut.propTypes = {
  id: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired,
};

export default SeriesBuilderShortcut;
