import React from 'react';
import { EditOutlined } from '@ant-design/icons';

import {
  FORM_MODE_READ_ONLY,
} from 'single-asset/constants/gasLiftConstants';

import './WellInformationEditFooter.scss';

const WellInformationEditHeader = ({ onEditClick, mode }) => (
  <div className="header">
    <div>
      <label>Well Information Edit</label>
      {mode === FORM_MODE_READ_ONLY && <a className="edit-button" onClick={onEditClick}><EditOutlined /></a>}
    </div>
  </div>
);

export default WellInformationEditHeader;
