export const LOADING_TELEMETRY_CONFIG_MESSAGE = 'loading telemetry configuration';
export const LOADING_TELEMETRY_STATS_MESSAGE = 'loading telemetry stats';
export const LOADING_PRODUCTION_CHARTS_MESSAGE = 'loading production charts';
export const RENDERING_PRODUCTION_CHARTS_MESSAGE = 'rendering production charts';
export const LOADING_WELL_STATS_MESSAGE = 'loading well stats';
export const LOADING_WELL_CONFIGURATIONS_MESSAGE = 'loading well configurations';
export const MILLISECONDS_TO_DELAY_CHART_UPDATE = 500;
export const WELL_FIELD_MIN_CHARS = 1;
export const WELL_NAME_FIELD_MAX_CHARS = 12;
export const WELL_DESCRIPTION_FIELD_MAX_CHARS = 163;
export const wellInformationFieldsDefaultStatusMap = {
  name: false,
  description: false,
};
export const FORM_MODE_EDIT = 'FORM_MODE_EDIT';
export const FORM_MODE_READ_ONLY = 'FORM_MODE_READ_ONLY';
export const wellIdList = ['1', '2', '3', '4'];
export const defaultWellPropValue = {
  title: '',
  description: '',
};
export const defaultInitialWellId = wellIdList.at(0);
export const GLU_HEADER = 'GLU';
export const GLU_SUB_HEADER = 'Gas Lift Unit';
export const GLX_HEADER = 'GLX';
export const GLX_SUB_HEADER = 'Multi-Well Gas Lift';
