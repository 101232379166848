import React, { useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Redirect, Switch, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { BreakpointProvider } from 'react-socks';
import { useAuth0 } from '@auth0/auth0-react';
import LogRocket from 'logrocket';

import { clearSession } from 'shared/actions/sessionActions';
import SingleAssetPage from 'single-asset/pages/SingleAssetPage';
import { GlobalProvider } from './GlobalContext';
import AssetList from './components/AssetList';
import Feedback from './components/Feedback';
import Settings from './components/Settings/Settings';
import Template from './components/Template';
import { Header, Navbar } from './components/Framework';
import Report from './components/Report';

const { REACT_APP_ENV } = process.env;

const Page = styled.div`
  margin-left: 66px;
  margin-top: 50px;

  @media (max-width: 768px) {
    padding: 48px 0px;
    margin: 3px;
  }
`;

// TODO
const defaultRoutes = [
  {
    icon: 'dashboard',
    link: '/dashboard',
    path: '/dashboard/:org_id?/:site_id?/:asset_id?/:device_id?',
    exact: true,
    title: 'Dashboard',
    mainNav: true,
    navExact: false,
    component: Template,
  },
  {
    title: 'Asset List',
    icon: 'list',
    path: '/asset',
    link: '/asset',
    exact: true,
    navExact: false,
    mainNav: true,
    component: AssetList,
  },
  {
    title: 'Single Asset',
    path: '/asset/:org_id?/:site_id?/:asset_id?/:device_id?',
    link: '/asset/:org_id?/:site_id?/:asset_id?/:device_id?',
    component: SingleAssetPage,
  },
  {
    title: 'Feedback',
    icon: 'feedback',
    path: '/feedback',
    link: '/feedback',
    mainNav: true,
    component: Feedback,
  },
  {
    title: 'Settings',
    path: '/settings',
    link: '/settings',
    mainNav: false,
    component: Settings,
  },
  {
    title: 'Report',
    icon: 'insert_chart',
    path: '/report',
    link: '/report',
    mainNav: true,
    component: Report,
  },
];

const { localStorage } = window;

const App = () => {
  const { user, isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.resources.theme);
  const demoMode = useSelector((state) => state.resources.demo);
  const themeStyles = useSelector((state) => state.resources.themeStyles);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      dispatch(clearSession());
      loginWithRedirect();
    } else if (isAuthenticated) {
      if (REACT_APP_ENV === 'production') {
        LogRocket.init('ky2tzm/mi_fe');
        LogRocket.identify(user.email);
      }
      const external_user = user.email.split('@')[1] !== 'flogistix.com';
      window.gtag('set', 'user_properties', { external_user });
      window.gtag('event', 'login', { external_user });
    }
  }, [isLoading, isAuthenticated]);

  useEffect(() => {
    localStorage.setItem('theme', JSON.stringify(themeStyles));
    document.body.style.background = theme.palette.primary.background;
  }, [themeStyles]);

  useEffect(() => {
    localStorage.setItem('demo', JSON.stringify(demoMode));
  }, [demoMode.demo]);

  return (
    <>
      <BreakpointProvider>
        <ThemeProvider theme={theme}>
          <GlobalProvider accessToken="">
            <Header />
            <Navbar routes={defaultRoutes} user={user} />
            <Page>
              <Switch>
                {defaultRoutes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    render={(props) => (
                      <route.component
                        user={user}
                        routes={defaultRoutes}
                        isAuthenticated={isAuthenticated}
                        {...props}
                      />
                    )}
                  />
                ))}
                {isAuthenticated && <Redirect from="/" to="/dashboard" />}
              </Switch>
            </Page>
          </GlobalProvider>
        </ThemeProvider>
      </BreakpointProvider>
    </>
  );
};

export default App;
