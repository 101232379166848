import styled from 'styled-components';
import { DatePicker, TimePicker } from 'antd';

// TODO: Look into sharing styles
export const StyledRangePicker = styled(DatePicker.RangePicker)<{ rounded: boolean }>`
  background-color: ${(props) => props.theme.widgets.background.light};
  border: ${(props) => props.theme.widgets.border.border.light};
  border-radius: ${(props) => (props.rounded ? '12px' : '0px')};
  width: 100%;

  &.ant-picker-disabled {
    background-color: ${(props) => props.theme.widgets.background.light};
  }

  .ant-picker-active-bar {
    background: ${(props) => props.theme.palette.buttons.primary.background.full};
  }

  .ant-picker-clear {
    background-color: ${(props) => props.theme.widgets.background.light};
  }

  input {
    color: ${(props) => props.theme.widgets.color.full};
  }
`;

export const StyledDatePicker = styled(DatePicker)<{ rounded: boolean }>`
  background-color: ${(props) => props.theme.widgets.background.light};
  border: ${(props) => props.theme.widgets.border.border.light};
  border-radius: ${(props) => (props.rounded ? '12px' : '0px')};
  height: 32px;
  width: 100%;

  &.ant-picker-disabled {
    background-color: ${(props) => props.theme.widgets.background.light};
  }

  .ant-picker-active-bar {
    background: ${(props) => props.theme.palette.buttons.primary.background.full};
  }

  .ant-picker-clear {
    background-color: ${(props) => props.theme.widgets.background.light};
  }

  input {
    color: ${(props) => props.theme.widgets.color.full};
    text-align: center;
  }
`;

export const StyledTimePicker = styled(TimePicker)<{ rounded: boolean }>`
  background-color: ${(props) => props.theme.widgets.background.light};
  border: ${(props) => props.theme.widgets.border.border.light};
  border-radius: ${(props) => (props.rounded ? '12px' : '0px')};
  height: 32px;
  width: 100%;

  &.ant-picker-disabled {
    background-color: ${(props) => props.theme.widgets.background.light};
  }

  .ant-picker-active-bar {
    background: ${(props) => props.theme.palette.buttons.primary.background.full};
  }

  .ant-picker-clear {
    background-color: ${(props) => props.theme.widgets.background.light};
  }

  input {
    color: ${(props) => props.theme.widgets.color.full};
    text-align: center;
  }
`;
