import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { DatePicker as AntDPicker} from 'antd';
import EditDiv from '../Editing/EditDiv';

const { RangePicker } = AntDPicker;

const Container = styled.div`
  overflow: visible; 
  position: relative;
  height: 100%;
  width: 100%
    
  .ant-picker-panel-container {
    background: red !important;
  }

  .wrapper {
    z-index: 1000;
    position: absolute;
    display: flex;
    border: 1px solid #CDD4DA;
    border-radius: 3px;
    box-shadow: 1px 1px 2px #cdd4da;
  }
`;

const ThemedRangePicker = styled(RangePicker)`
  ${props => `
    &.ant-picker-range {
      background: ${props.theme.widgets.background.full};
      color: ${props.theme.widgets.color.full};
      border: ${props.theme.widgets.border.border.light};
    }

    .ant-picker-clear {
      background: ${props.theme.widgets.background.full};
    }

    && input {
      color: ${props.theme.widgets.color.full};
    }
  `}
`;

const DatePicker = ({ id, history, parentId, location }) => {
  const editableLayout = useSelector(state => state.layouts.editing.editable_layout);
  const editMode = editableLayout === parentId;

  const handleDateChange = (timestamps) => {
    if (timestamps) {
      const start = moment.utc(timestamps[0]).format('YYYY-MM-DD');
      const end = moment.utc(timestamps[1]).format('YYYY-MM-DD');

      if (start === end) {
        return history.push({ search: `?start=${start}%2000:00:00&end=${end}%2023:59:59` })
      }
      return history.push({ search: `?start=${start}%2000:00:00&end=${end}%2023:59:59` })
    }

    return history.push(location.pathname)
  };
      

  return (
    <Container>
      { editMode && <EditDiv id={id} /> } 
      <ThemedRangePicker
        onChange={handleDateChange}
        size='large'
      />
    </Container>
  );
};

export default DatePicker;
