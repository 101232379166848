const { REACT_APP_ENV } = process.env;

// PROD ENVIRONMENT
const clientID = 's3o9cYFjN7UFAUSdozEUSimxTd3jS3tV';

// DEV ENVIRONMENT
// const clientID = 'owpl8IyBHCEpqbIRaQD6QmQiIytUWPHC';

// THIS IS FOR CICD BUT WE CAN"T USE IT JUST YET
// const clientID =
//   REACT_APP_ENV === 'production'
//     ? process.env.REACT_APP_PRODUCTION_CLIENTID.toString()
//     : process.env.REACT_APP_DEVELOPMENT_CLIENTID.toString();

const authConfig = {
  domain: 'axil.auth0.com',
  clientID,
  responseType: 'token id_token',
  audience: 'https://stagingapi.flaxil.com/v1',
  scope: 'openid profile email',
};

export default authConfig;
