import { getWells, addWell, updateWellById } from 'shared/repositories/gasLiftRepository';
import * as wellConfigurationActionTypes from 'single-asset/actions/wellConfigurationActionTypes';

export const fetchWellConfigurations = () => async (dispatch, getState) => {
  dispatch({
    type: wellConfigurationActionTypes.WELL_CONFIGURATION__FETCH_WELLS,
  });

  try {
    const {
      session: { token: accessToken },
      singleAsset: { asset: { assetId } },
    } = getState();
    const response = await getWells({ accessToken, assetId });

    if (response.ok) {
      const config = await response.json();

      return dispatch({
        type: wellConfigurationActionTypes.WELL_CONFIGURATION__FETCH_WELLS_SUCCEEDED,
        config,
      });
    }

    throw response;
  } catch (error) {
    const {
      url, status, statusText, type,
    } = error;

    const errorResponse = {
      ok: false,
      error: url && status && statusText && type && {
        statusText: `${status} ${type}: ${statusText} ${url}`,
      }
      || { statusText: error.toString() },
    };

    return dispatch({
      type: wellConfigurationActionTypes.WELL_CONFIGURATION__FETCH_WELLS_FAILED,
      errorResponse,
    });
  }
};

export const saveWellConfiguration = ({ wellId, title, description }) => async (dispatch, getState) => {
  dispatch({
    type: wellConfigurationActionTypes.WELL_CONFIGURATION__SAVE_WELL_CONFIGURATION,
  });

  try {
    const {
      session: { token: accessToken },
      singleAsset: { asset: { assetId } },
    } = getState();
    const body = { title, description };

    const response = await (wellId
      ? updateWellById({
        accessToken,
        assetId,
        wellId,
        body,
      })
      : addWell({
        accessToken,
        assetId,
        body,
      })
    );

    if (response.ok) {
      const config = await response.json();

      return dispatch({
        type: wellConfigurationActionTypes.WELL_CONFIGURATION__SAVE_WELL_CONFIGURATION_SUCCEEDED,
        config,
      });
    }

    throw response;
  } catch (error) {
    const {
      url, status, statusText, type,
    } = error;

    const errorResponse = {
      ok: false,
      error: url && status && statusText && type && {
        statusText: `${status} ${type}: ${statusText} ${url}`,
      }
      || { statusText: error.toString() },
    };

    return dispatch({
      type: wellConfigurationActionTypes.WELL_CONFIGURATION__SAVE_WELL_CONFIGURATION_FAILED,
      errorResponse,
    });
  }
};
