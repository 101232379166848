/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import moment from 'moment';
import styled from 'styled-components';
import { ScaleLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import { Card, Statistic } from 'antd';

import useFetch from '../hooks/useFetch';
import EditDiv from '../Editing/EditDiv';

const padding = (justify) => {
  if (justify === 'start') { return '0 0 0 5%;' }
  if (justify === 'flex-end') { return '0 5% 0 0;' }
  return '0;'
}

const Container = styled(Card)`
  ${props => `
    && {
      display: flex;
      justify-content: ${props.justify};
      align-items: center;
      background: ${props.theme.widgets.background.full};
      box-shadow: ${props.theme.widgets.border.boxShadow};
      border: ${props.theme.widgets.border.border.full};
      border-radius: ${props.rounded ? '12px' : 'none'};
      height: 100%;
    }
    .ant-statistic-content-value {
      white-space: nowrap;
      overflow-x: scroll;
      ::-webkit-scrollbar {
        display: none
      }
    }
    .ant-card-body {
      padding: 0;
      margin: auto;
      width: 50%;
    }
    .ant-statistic {
      width: 100%;
    }
    .ant-statistic-content {
      display: flex;
      justify-content: ${props.justify};
      align-items: baseline;
      color: ${props.textcolor || props.theme.widgets.color.full};
      width: 100%;
      padding: ${padding(props.justify)}
      overflow-x: scroll;
      ::-webkit-scrollbar {
        display: none
      }
    }
    .ant-statistic-title {
      display: flex;
      justify-content: ${props.justify};
      align-items: ${props.align};
      color: ${ props.textcolor || props.theme.widgets.color.light };
      width: 100%;
      padding: ${padding(props.justify)}
    }
  `}
`;

const WidgetBasicCard = ({
  id,
  match,
  config,
  props,
  parentId,
  roundedTheme,
  altitudeGroupId
}) => {
  const {
    justify='start',
    align='center',
    timestamp=false,
    timeFormat,
    textcolor,
    bordered=true,
    rounded=false,
    value,
    title,
    precision,
    prefix,
    suffix,
    cardBodyStyles,
    valueStyles: {
      styles,
      conditions: {
        greater,
        less_than,
        equals,
        not_equals,
        equals_color,
        not_equals_color,
      },
    },
  } = props;

  const { params } = match; 
  const [valueStyle, setValueStyle]       = useState({});
  const [cardBodyStyle, setCardBodyStyle] = useState({});
  const [cardValue, setCardValue] = useState(null);
  const [cardLoading, cardData, fetchCardData] = useFetch(config.endpoint);

  const themeProps      = useSelector(state => state.resources.theme);
  const editableLayout  = useSelector(state => state.layouts.editing.editable_layout);

  const editMode = editableLayout === parentId;
  const timestampValue = timestamp ? moment(cardValue).format(timeFormat) : cardValue;

  useEffect(() => {
    fetchCardData({ ...params, ...config, filters: altitudeGroupId })
  }, [params, altitudeGroupId]);

  useEffect(() => {
    if (!cardLoading && cardData) { setCardValue(cardData[0]?.[value]) }
  }, [cardLoading, cardData]);

  useEffect(() => {
    if (greater && cardValue > greater) {
      setValueStyle({ ...valueStyle, color: themeProps.palette.success.full })
    }
    if (less_than && cardValue < less_than) {
      setValueStyle({ ...valueStyle, color: themeProps.palette.danger.full })
    }
    if (equals.length > 0) {
      if (equals.includes(cardValue)) {
        setValueStyle({ ...valueStyle, color: equals_color })
      }
    }
    if (not_equals.length > 0) {
      if (not_equals.includes(cardValue)) {
        setValueStyle({ ...valueStyle, color: not_equals_color })
      }
    }
  }, [cardValue]);

  useEffect(() => {
    if (styles) { setValueStyle(valueStyle => ({ ...valueStyle, ...styles })); }
  }, [styles]);
  
  useEffect(() => {
    if (cardBodyStyles) { setCardBodyStyle(cardBodyStyle => ({ ...cardBodyStyle, ...cardBodyStyles })); }
  }, [cardBodyStyles]);

  return (
    <Container
      bodyStyle={{ width: '100%' }}
      style={cardBodyStyle}
      justify={justify}
      align={align}
      textcolor={textcolor}
      bordered={bordered}
      rounded={rounded || roundedTheme}
    >
      { editMode && <EditDiv id={id} /> }
      { cardLoading && <ScaleLoader color={themeProps.palette.primary.full} /> }
      { !cardLoading && (
        <Statistic
          title={title}
          suffix={suffix}
          prefix={prefix}
          precision={precision}
          valueStyle={valueStyle}
          value={timestampValue || ' - '}
        /> 
      )}
    </Container>
  )
}

WidgetBasicCard.defaultProps = {
  prefix: '',
  suffix: '',
  cardBodyStyles: {},
  valueStyles: {},
  precision: null,
  title: '',
  justify: 'start',
  align: 'center',
  textcolor: '',
  bordered: true,
  rounded: false,
  value: '',
};

WidgetBasicCard.propTypes = {
  id: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
  props: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  parentId: PropTypes.string.isRequired,
  justify: PropTypes.string,
  align: PropTypes.string,
  textcolor: PropTypes.string,
  bordered: PropTypes.bool,
  rounded: PropTypes.bool,
  value: PropTypes.string,
  title: PropTypes.string,
  precision: PropTypes.number,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  cardBodyStyles: PropTypes.object,
  valueStyles: PropTypes.object
};

export default WidgetBasicCard;
