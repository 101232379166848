import theme from '../styles/theme';

const { localStorage } = window;
const themeMode = JSON.parse(localStorage.getItem('theme'));
const demoMode = JSON.parse(localStorage.getItem('demo'));

const initialState = {
  altitude_info: {},
  asset_list: [],
  notification_list: [],
  theme: theme(themeMode?.theme || 'light'),
  themeStyles: {
    theme: themeMode?.theme || 'light',
    round: themeMode?.round || false,
  },
  errors: {
    errors: {}
  },
  demo: { demo: demoMode?.demo || false }
};

const SET_THEME               = 'SET_THEME';
const SET_ERRORS              = 'SET_ERRORS';
const STORE_ASSET_LIST        = 'STORE_ASSET_LIST';
const TOGGLE_DARK_MODE        = 'TOGGLE_DARK_MODE';
const TOGGLE_ROUND_MODE       = 'TOGGLE_ROUND_MODE';
const TOGGLE_DEMO_MODE        = 'TOGGLE_DEMO_MODE';
const STORE_ALTITUDE_INFO     = 'STORE_ALTITUDE_INFO';
const STORE_NOTIFICATION_LIST = 'STORE_NOTIFICATION_LIST';

export function reducer(state = initialState, { type, payload }) {
  switch(type) {
    case STORE_ALTITUDE_INFO:
      return {
        ...state,
        altitude_info: payload
      };
    case STORE_ASSET_LIST:
      return {
        ...state,
        asset_list: payload
      };
    case STORE_NOTIFICATION_LIST:
      return {
        ...state,
        notification_list: payload
      };
    case SET_THEME:
      return {
        ...state,
        theme: payload
      }
    case SET_ERRORS:
      return {
        ...state,
        errors: {
          ...payload
        }
      }
    case TOGGLE_DARK_MODE:
      return {
        ...state,
        theme: theme(payload),
        themeStyles: {
          ...state.themeStyles,
          theme: payload
        }
      }
    case TOGGLE_ROUND_MODE:
      return {
        ...state,
        themeStyles: {
          ...state.themeStyles,
          round: payload
        }
      }
    case TOGGLE_DEMO_MODE:
      return {
        ...state,
        demo: payload
      }
    default:
      return state;
  }
}

export const storeAltitudeInfo = payload => ({
  type: STORE_ALTITUDE_INFO,
  payload
});

export const setErrors = payload => ({
  type: SET_ERRORS,
  payload
})
