import { combineReducers } from 'redux';

import singleAsset from 'single-asset/reducers';
import session from 'shared/reducers/sessionReducer';
import { reducer as assetList } from './assetList';
import { reducer as events } from './events';
import { reducer as dashboardCanGoBack } from './dashboardCanGoBack';
import { reducer as layouts } from './layouts';
import reporting from './reporting';
import { reducer as resources } from './resources';
import { frameReducer as frame } from '../components/Framework/reducer';

const rootReducer = combineReducers({
  singleAsset,
  assetList,
  events,
  dashboardCanGoBack,
  frame,
  layouts,
  reporting,
  resources,
  session,
});

export default rootReducer;
