/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Checkbox, Form, Button, Radio } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { AutoSizer, List } from 'react-virtualized';
import { useSelector, useDispatch } from 'react-redux';

// TEMPORARY FIX
import { v4 as uuidv4 } from 'uuid';

import { StyledButton, StyledCheckbox, StyledModal, StyledInput } from '../styled';
import useWindowSize from '../hooks/useWindowSize';
import { useListFilter } from '../hooks/useFilter.tsx';
import useFetch from '../hooks/useFetch';

const ListRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: ${(props) => props.theme.widgets.border.border.full};
`;

const ListItem = styled.div`
  padding: 6px;
  color: ${(props) => props.theme.widgets.color.full};
`;

const ListDiv = styled.div`
  height: 300px;
  margin: 24px 0 0 0;

  @media (max-width: 768px) {
    min-width: 100%;
    font-size: 10px;
  }
`;

const WidgetCardBuilder = ({
  open,
  closeModal,
  assetTypeId,
  match: { params },
  org: { value: orgValue = -1 },
  site: { value: siteValue = -1 },
  asset: { value: assetValue = -1 },
  dashboardId,
  layoutObject,
  activeLayout,
  setActiveLayout,
  templateItem,
}) => {
  const [tags, setTags] = useState([]);
  const [styleModalOpen, toggleStyleModal] = useState(false);
  const [selectedTag, setselectedTag] = useState([]);
  const [cardConfig, setCardConfig] = useState({});
  const [cardProps, setCardProps] = useState({
    height: 200,
    align: 'center',
    title: '',
    value: 'val',
    prefix: '',
    suffix: '',
    justify: 'center',
    precision: 2,
    textcolor: '',
    valueStyles: {
      styles: {},
      conditions: {
        equals: [],
        greater: false,
        less_than: false,
        not_equals: [],
        equals_color: '',
        not_equals_color: '',
      },
    },
  });

  const [loading, saveResponse, saveLayout] = useFetch('/v1/axil/layouts/save');
  const [loadingTags, fetchedTags, fetchBuilderTags] = useFetch(
    '/v2/axil/widgets/builder/custom_chart',
  );

  const { setFilter, filteredList } = useListFilter(tags, ['name', 'level', 'type']);
  const [size] = useWindowSize();

  const addLayout = useSelector((state) => state.layouts.adding.add_layout);
  const roundedTheme = useSelector((state) => state.resources.themeStyles.round);

  const dispatch = useDispatch();
  const setAddLayout = (string) => dispatch({ type: 'SET_ADD_LAYOUT', payload: string });

  const formLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
  };

  const defaultLayout = {
    i: 'REPLACE',
    x: 10,
    y: 1,
    w: 2,
    h: 2,
    widget: 'WidgetBasicCard',
    moved: false,
    static: false,
    options: {
      config: {
        agg: true,
        tags: {
          val: {
            key: '',
            tag_id: '',
            agg_func: '',
          },
        },
      },
      props: {
        ...cardProps,
        cardBodyStyles: {},
      },
    },
  };

  useEffect(() => {
    fetchBuilderTags({
      org_id: orgValue || params.org_id,
      site_id: siteValue || params.site_id,
      asset_id: assetValue || params.asset_id,
    });
  }, [assetTypeId]);

  useEffect(() => {
    if (!loadingTags) {
      setTags(fetchedTags);
    }
  }, [fetchedTags, loadingTags]);

  const mapselectedTagAndCreateLayouts = async (arr) => {
    const cardId = `${arr.tag_id}_${uuidv4()}`;
    const styleSize = {
      rows: 2,
      height: 200,
    };
    // TODO: Add validation and remove uuid;
    const newLayoutItem = Object.assign({}, defaultLayout, {
      ...defaultLayout,
      i: cardId,
      h: styleSize.rows,
      y: 1,

      options: {
        ...defaultLayout.options,
        props: {
          ...defaultLayout.options.props,
          title: defaultLayout.options.props.title || arr.name,
          prefix: defaultLayout.options.props.prefix,
          suffix: defaultLayout.options.props.suffix,
          justify: defaultLayout.options.props.justify,
          textcolor: defaultLayout.options.props.textcolor,
          precision: defaultLayout.options.props.precision,
        },
        config: {
          ...defaultLayout.options.config,
          agg: true,
          endpoint: '/v1/axil/config/tag_values/by_tag_id',
          tags: {
            val: {
              key: cardConfig?.key || 'val',
              tag_id: arr.tag_id,
              agg_func: cardConfig?.agg_func || 'last_value',
            },
          },
        },
      },
    });
    const findLayout = (lay) => {
      return lay.map((item) => {
        const { children = [] } = item;
        if (item.i === addLayout) {
          return {
            ...item,
            h: item.h + styleSize.rows,
            children: [newLayoutItem, ...children],
          };
        }
        return { ...item, children: findLayout(children) };
      });
    };

    let saveLayoutParams = {
      ...params,
      dashboard_id: dashboardId,
      asset_type_id: assetTypeId,
      org_id: params.org_id || -1,
      site_id: params.site_id || -1,
      asset_id: params.asset_id || -1,
      device_id: params.device_id || -1,
      layout: { ...layoutObject, [size]: findLayout(activeLayout) },
    };

    if (templateItem) {
      saveLayoutParams = {
        org_id: -1,
        site_id: -1,
        asset_id: -1,
        device_id: -1,
        dashboard_id: dashboardId,
        asset_type_id: assetTypeId,
        layout: { ...layoutObject, [size]: findLayout(activeLayout) },
      };
    }
    await saveLayout(saveLayoutParams);
    setActiveLayout(findLayout(activeLayout));
    setAddLayout('');
  };

  const handleSubmit = () => {
    mapselectedTagAndCreateLayouts(selectedTag);
    closeModal();
  };

  const handleCardProps = (key, value) => {
    setCardProps({ ...cardProps, [key]: value });
  };

  const ListRows = ({ index, style, key }) => {
    return (
      <ListRow style={style} key={key}>
        <ListItem style={{ width: '75%' }}>
          <div> {filteredList[index].name} </div>
        </ListItem>
        <ListItem style={{ width: '25%' }}>
          <StyledCheckbox
            onChange={() => setselectedTag(filteredList[index])}
            checked={selectedTag.tag_id === filteredList[index].tag_id}
          />
        </ListItem>
      </ListRow>
    );
  };

  return (
    <StyledModal
      visible={open}
      onCancel={closeModal}
      onOk={closeModal}
      title="Select Items for New Card"
      footer={[
        <Button onClick={() => toggleStyleModal(!styleModalOpen)}>
          Advanced Options
          {!cardConfig.key && <ExclamationCircleOutlined style={{ color: 'red' }} />}
        </Button>,
        <StyledButton
          type="primary"
          onClick={() => handleSubmit(selectedTag)}
          disabled={!cardConfig.key}
        >
          Add Card
        </StyledButton>,
        <StyledButton type="link" onClick={closeModal}>
          Cancel
        </StyledButton>,
      ]}
    >
      <StyledInput
        placeholder="Filter Card Options"
        onChange={(e) => setFilter(e.target.value)}
        rounded={roundedTheme}
      />
      <ListDiv>
        <AutoSizer>
          {({ width, height }) => (
            <List
              width={width}
              height={height}
              rowCount={filteredList.length}
              rowHeight={30}
              rowRenderer={ListRows}
            />
          )}
        </AutoSizer>
      </ListDiv>
      <StyledModal
        width={800}
        bodyStyle={{ maxHeight: '600px', overflow: 'scroll' }}
        title="Card Display Options"
        onOk={() => toggleStyleModal(false)}
        onCancel={() => toggleStyleModal(false)}
        visible={styleModalOpen}
        closable={false}
      >
        <Form {...formLayout}>
          <Form.Item label="Is this a Label or Number?" required>
            <Radio.Group onChange={(e) => setCardConfig({ ...cardConfig, key: e.target.value })}>
              <Radio value="enum_label">Label</Radio>
              <Radio value="val">Value</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Value Display options" required>
            <Radio.Group
              onChange={(e) => setCardConfig({ ...cardConfig, agg_func: e.target.value })}
            >
              {cardConfig.key === 'val' && <Radio value="avg">Average</Radio>}
              {cardConfig.key === 'val' && <Radio value="count">Total Count</Radio>}
              <Radio value="last_value">Latest Value</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Card Title">
            <StyledInput
              onChange={(e) => handleCardProps('title', e.target.value)}
              rounded={roundedTheme}
            />
          </Form.Item>
          <Form.Item label="Card Subtitle">
            <StyledInput
              onChange={(e) => handleCardProps('cardSubTitle', e.target.value)}
              rounded={roundedTheme}
            />
          </Form.Item>
          <Form.Item label="Card Prefix">
            <StyledInput
              onChange={(e) => handleCardProps('prefix', e.target.value)}
              rounded={roundedTheme}
            />
          </Form.Item>
          <Form.Item label="Card Suffix">
            <StyledInput
              onChange={(e) => handleCardProps('suffix', e.target.value)}
              rounded={roundedTheme}
            />
          </Form.Item>
          <Form.Item label="Bordered">
            <Checkbox
              defaultChecked
              onChange={(e) => handleCardProps('bordered', e.target.checked)}
            />
          </Form.Item>
          <Form.Item label="Card Text Color">
            <StyledInput
              style={{ width: '120px' }}
              type="color"
              onChange={(e) => handleCardProps('textcolor', e.target.value)}
              rounded={roundedTheme}
            />
          </Form.Item>
          <Form.Item label="Align">
            <Radio.Group onChange={(e) => handleCardProps('align', e.target.value)}>
              <Radio value="center">Center</Radio>
              <Radio value="left">Left</Radio>
              <Radio value="right">Right</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Justify">
            <Radio.Group onChange={(e) => handleCardProps('justify', e.target.value)}>
              <Radio value="center">Center</Radio>
              <Radio value="left">Left</Radio>
              <Radio value="right">Right</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </StyledModal>
    </StyledModal>
  );
};

WidgetCardBuilder.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default WidgetCardBuilder;
