import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getWellMapListFromConfig } from 'single-asset/helpers/gasLiftHelpers';
import { fetchTelemetryStatsLists } from 'single-asset/actions/telemetryStatsActions';
import { fetchWellConfigurations, saveWellConfiguration } from 'single-asset/actions/wellConfigurationActions';
import { fetchGLXProductionSeriesList } from 'single-asset/actions/glxProductionActions';
import { getDateRange } from 'single-asset/helpers/glxProductionHelpers';
import { glxTagConfiguration, gluTagConfiguration } from 'single-asset/constants/glxProductionConstants';
import telemetryStatTagConfiguration from 'single-asset/constants/telemetryStatTagConfiguration';
import glxWellStatTagConfiguration from 'single-asset/constants/glxWellStatTagConfiguration';
import gluWellStatTagConfiguration from 'single-asset/constants/gluWellStatTagConfiguration';
import { GLX_ASSET_TYPE_ID } from 'shared/constants/altitudeConstants';
import TelemetryStatsList from 'single-asset/components/TelemetryStatsList';
import Production from 'single-asset/components/Production';
import GasLiftLoadingIndicator from 'single-asset//components/GasLiftLoadingIndicator';
import {
  LOADING_TELEMETRY_STATS_MESSAGE,
  GLU_HEADER,
  GLU_SUB_HEADER,
  GLX_HEADER,
  GLX_SUB_HEADER,
} from 'single-asset/constants/gasLiftConstants';

import 'single-asset/pages/GasLiftPage.scss';

export const GasLiftPage = ({
  stats: {
    statsList,
    isPending:
    isStatsPending,
  },
  getStats,
  getSeriesList,
  getWellConfigurationList,
  production,
  production: { seriesList },
  wellStats,
  wellStats: { wellStatList },
  wellConfiguration,
  assetTypeId,
  defaultDates,
  saveWellConfig,
}) => {
  const [gasLiftConfiguration, header, subHeader, wellStatTagConfiguration] = assetTypeId === GLX_ASSET_TYPE_ID
    ? [glxTagConfiguration, GLX_HEADER, GLX_SUB_HEADER, glxWellStatTagConfiguration]
    : [gluTagConfiguration, GLU_HEADER, GLU_SUB_HEADER, gluWellStatTagConfiguration];
  const wellMapList = getWellMapListFromConfig(gasLiftConfiguration, wellConfiguration.wells);
  const loadingMessage = isStatsPending && LOADING_TELEMETRY_STATS_MESSAGE;
  const sendDailyProductionRequest = (dateRange) => {
    getSeriesList(gasLiftConfiguration, dateRange);
  };

  useEffect(() => {
    !seriesList.length
    && sendDailyProductionRequest(defaultDates);

    (!wellStatList.length || !statsList.length)
    && getStats(telemetryStatTagConfiguration, wellStatTagConfiguration);

    !wellConfiguration.wells
      && getWellConfigurationList();
  }, []);

  return (
    <div className="GasLiftPage">
      {loadingMessage ? <GasLiftLoadingIndicator loadingMessage={loadingMessage} />
        : statsList.length && <TelemetryStatsList statsList={statsList} />}
      {!loadingMessage && (
      <Production
        header={header}
        subHeader={subHeader}
        searchDailyProduction={sendDailyProductionRequest}
        saveWellConfig={saveWellConfig}
        production={production}
        wellStats={wellStats}
        defaultDates={defaultDates}
        wellMapList={wellMapList}
        wellConfiguration={wellConfiguration}
      />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  stats: state.singleAsset.telemetry?.stats,
  production: state.singleAsset.gasLift.production,
  wellStats: state.singleAsset.gasLift.wellStats,
  wellConfiguration: state.singleAsset.gasLift.wellConfiguration,
  assetTypeId: state.singleAsset.asset.assetTypeId,
  defaultDates: getDateRange(),
});

const mapDispatchToProps = (dispatch) => ({
  getStats: (statsConfig, wellsConfig) => dispatch(fetchTelemetryStatsLists({ statsConfig, wellsConfig })),
  getSeriesList: (configList, dateRange) => dispatch(fetchGLXProductionSeriesList(configList, dateRange)),
  getWellConfigurationList: () => dispatch(fetchWellConfigurations()),
  saveWellConfig: (config) => dispatch(saveWellConfiguration(config)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GasLiftPage);
