import React, { ReactElement } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useAppSelector } from 'store/hooks';

const Container = styled.div<{
  demoMode: boolean;
}>`
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 0 24px;

  .header {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 36px;
    width: 100%;
    color: ${(props) => props.theme.widgets.color.full};

    @media (max-width: 768px) {
      font-size: 21px;
    }
  }

  .sub-header {
    font-weight: 100;
    font-size: 26px;
    line-height: 36px;
    margin-left: 6px;

    @media (max-width: 768px) {
      font-size: 21px;
    }
  }
  .sub-text {
    font-weight: 100;
    font-size: 16px;
    color: ${(props) => (props.demoMode ? 'transparent !important' : (props) => props.theme.widgets.color.full)};
    text-shadow: ${(props) => (props.demoMode ? '0 0 12px rgba(0,0,0,0.5) !important' : '')};
  }
  .header-actions {
    display: flex;
    justify-content: flex-end;
  }
`;

const SingleAssetHeader = ({
  asset,
  updatedAsset,
  setConfirmation,
  showConfirmation,
  setShowConfirmation,
  setShowEditModal,
  showEditModal,
  actionComp,
  subHeader = '',
  subText = '',
  currentPermissions,
}: {
  asset: any;
  updatedAsset: any;
  setConfirmation: Function;
  setShowEditModal: Function;
  setShowConfirmation: Function;
  showConfirmation: boolean;
  showEditModal: Boolean;
  actionComp: ReactElement;
  subHeader: string;
  subText: string;
  currentPermissions: any;
}): ReactElement => {
  const demoMode = useAppSelector((state) => state.resources.demo);
  const oldPort = asset?.ipInfo?.port || '';
  const oldIpAddress = asset?.ipAddress || '';

  let lastUpdatedBy = asset?.ipInfo?.lastUpdatedBy || '';
  let lastUpdatedTime = asset?.ipInfo?.lastUpdateTime || '';

  if (updatedAsset?.events?.[1]?.data?.time) {
    lastUpdatedTime = updatedAsset.events[1].data.time;
  }

  if (updatedAsset?.events?.[0]?.data?.changes?.updatedBy) {
    lastUpdatedBy = updatedAsset.events[0].data.changes.updatedBy;
  }

  const hasEditPermissions = currentPermissions?.groups?.map((permission: any) => {
    if (
      permission.groupId === 'decoder-customer-advanced-tech' ||
      permission.groupId === 'decoder-flogistix-programmer'
    ) {
      return true;
    } else return false;
  });

  const handleEdit = () => {
    setShowEditModal(!showEditModal);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(asset.ipAddress);
    setConfirmation('IP Address copied to clipboard.');
    setShowConfirmation(!showConfirmation);
  };

  const mainHeader = (
    <div id="asset__header" className="header">
      Asset
      <span className="sub-header">{subHeader}</span>
      <>
        {asset.ipAddress && asset?.connectivityInfo?.status === 'connected' && (
          <div className="ip-connected-logo" title="Connected" />
        )}
        {asset.ipAddress && asset?.connectivityInfo?.status === 'disconnected' && (
          <div className="ip-disconnected-logo" title="Disconnected" />
        )}
        {!asset.ipAddress && <div className="ip-unknown-logo" title="Unknown" />}
      </>
    </div>
  );

  const subTextHeader = <div className="sub-text">{subText}</div>;

  const subscribeButton = <div className="header-actions">{actionComp}</div>;

  const renderMechanicHeader = () => (
    <div className="mechanic-container">
      <p> Mechanic: </p>
      <p>{asset?.mechanic?.name ?? 'No Mechanic Name'}</p>
      <b>|</b>
      <div className="phone-icon" />
      <p>{asset?.mechanic?.phone ?? 'No Number Listed'}</p>
    </div>
  );

  const ipAddressHeader = (
    <div className="ip-container">
      <b>IP Address:</b>
      <p>{asset?.ipAddress ? `${oldIpAddress}:${oldPort}` : 'No IP found.'}</p>
      {asset?.ipAddress && (
        <>
          <div
            title={
              lastUpdatedTime !== '' && lastUpdatedBy !== ''
                ? `Last updated by: ${lastUpdatedBy} on ${new Date(lastUpdatedTime)}`
                : 'No last edit info.'
            }
            className="grey-info-icon"
          />
          <div title="Copy IP" onClick={() => handleCopy()} className="copy-address-icon" />
        </>
      )}
      {hasEditPermissions && (
        <div title="Edit IP" onClick={() => handleEdit()} className="edit-address-icon" />
      )}
    </div>
  );

  return (
    <Container className="single-asset-header-container" demoMode={demoMode.demo}>
      <div className="single-asset-header">
        {mainHeader}
        {subscribeButton}
      </div>
      {subTextHeader}
      {renderMechanicHeader()}
      {ipAddressHeader}
    </Container>
  );
};

SingleAssetHeader.defaultProps = {
  actionComp: null,
  subHeader: null,
};

SingleAssetHeader.propTypes = {
  actionComp: PropTypes.element,
  subHeader: PropTypes.string,
};

export default SingleAssetHeader;
