import React from 'react';

const DateComponent = ({ handleChange }) => {
  const dateRangeComponent = (
    <section className="report-section">
      <p className="displayed-options-title">Date Range</p>
      <div className="date-range-container">
        <input
          type="date"
          className="date-input"
          name="startTime"
          onChange={(e) => handleChange(e)}
        />
        <div className="date-range-dash" />
        <input
          type="date"
          className="date-input"
          name="endTime"
          onChange={(e) => handleChange(e)}
        />
      </div>
    </section>
  );

  return dateRangeComponent;
};

export default DateComponent;
