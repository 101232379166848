import {
  SINGLE_ASSET__SET_ASSET,
  SINGLE_ASSET__CLEAR_STATE,
} from 'single-asset/actions/singleAssetActionTypes';

export const setAsset = (payload) => ({
  type: SINGLE_ASSET__SET_ASSET,
  payload,
});

export const clearSingleAssetState = () => ({
  type: SINGLE_ASSET__CLEAR_STATE,
});
