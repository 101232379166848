import React, {
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Popover } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { MailOutlined } from '@ant-design/icons';

import useFetch from 'components/hooks/useFetch';
import { GlobalContext } from 'GlobalContext';

import { StyledCheckbox } from 'components/styled';
import { useAppDispatch } from 'store/hooks';
import { DispatchFunc } from 'store/store.interfaces';

type PopoverType = ({ className, props }: { className: string; props: any }) => ReactElement;

const StyledPopover: PopoverType = ({ className, ...props }) => (
  <Popover {...props} overlayClassName={className} />
);

const Link = styled.a<{ subscribed: boolean }>`
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  color: ${({ subscribed }) => (subscribed ? 'green' : '')};
`;

const ThemedPopover = styled(StyledPopover)<{ props: any }>`
  ${(props) => `
    && .ant-popover-inner {
      background: ${props.theme.widgets.background.full};
      color: ${props.theme.widgets.color.full};
    }
    && .ant-popover-title {
      color: ${props.theme.widgets.color.full};
    }
  `}
`;

const Notifications = ({
  asset: { assetId },
}: {
  asset: {
    assetId: string | null;
  };
}): ReactElement => {
  const [visible, setVisible] = useState(false);
  const [subscriptionState, setSubscriptionState] = useState({
    debug: false,
    info: false,
    warning: false,
    danger: false,
    success: false,
  });

  const { subscriptionList } = useContext(GlobalContext);

  const dispatch = useAppDispatch();
  const setNotificationsRedux: DispatchFunc<any[]> = (arr) => dispatch({ type: 'STORE_NOTIFICATION_LIST', payload: arr });
  const isSubscribed = Object.values(subscriptionState).some((x) => x);

  const [savingSubs, saveStatus, saveSubs] = useFetch('/v1/axil/notifications/subscribe/assets');

  const submitSubscription = () => {
    setVisible(false);
    if (assetId) {
      setNotificationsRedux({ ...subscriptionList, [assetId]: subscriptionState });
      saveSubs({ subscriptions: { [assetId]: subscriptionState } });
    }
  };

  useEffect(() => {
    if (Object.keys(subscriptionList).length > 0 && assetId) {
      if (subscriptionList[assetId]) {
        setSubscriptionState(subscriptionList[assetId]);
      }
    }
  }, [subscriptionList, assetId]);

  const handlePopCheck = (key: string) => (event: CheckboxChangeEvent): void => {
    const {
      target: { checked },
    } = event;
    setSubscriptionState({
      ...subscriptionState,
      [key]: checked,
    });
  };

  const popContent = (
    <>
      <div>
        <StyledCheckbox onChange={handlePopCheck('debug')} checked={subscriptionState.debug}>
          Debug
        </StyledCheckbox>
        <StyledCheckbox onChange={handlePopCheck('info')} checked={subscriptionState.info}>
          Information
        </StyledCheckbox>
        <StyledCheckbox onChange={handlePopCheck('warning')} checked={subscriptionState.warning}>
          Alarm
        </StyledCheckbox>
        <StyledCheckbox onChange={handlePopCheck('danger')} checked={subscriptionState.danger}>
          Fault
        </StyledCheckbox>
        <StyledCheckbox onChange={handlePopCheck('success')} checked={subscriptionState.success}>
          Running
        </StyledCheckbox>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '12px 0 3px 0' }}>
        <Button type="link" onClick={submitSubscription}>
          {' '}
          Confirm Subscription{' '}
        </Button>
      </div>
    </>
  );

  return (
    <ThemedPopover<any>
      visible={visible}
      title="Select what notifications to receive"
      content={popContent}
      placement="bottomLeft"
      trigger="click"
    >
      <Link href=" " subscribed={isSubscribed} onClick={() => setVisible(!visible)}>
        <MailOutlined style={{ padding: '0 3px' }} />
        {isSubscribed ? 'Subscribed' : 'Subscribe'}
      </Link>
    </ThemedPopover>
  );
};

Notifications.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

export default Notifications;
