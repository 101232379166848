import React from 'react';

const ReportCardsComponent = ({ reports, selectedType, selectReport }) => {
  const reportCards =
    reports && reports.length
      ? reports.map((report) => {
          const submit = () => {
            selectReport(report);
          };

          return (
            <div
              key={report.title}
              className={selectedType === report.title ? `report-card selected` : `report-card`}
              onClick={() => submit()}
            >
              <p
                onClick={() => submit()}
                className={
                  selectedType === report.title ? `report-card-title selected` : `report-card-title`
                }
              >
                {report.title}
              </p>
              <div className="card-line"></div>
              <p
                className={
                  selectedType === report.title
                    ? `report-card-description selected`
                    : `report-card-description`
                }
              >
                {report.description}
              </p>
            </div>
          );
        })
      : [];

  return (
    <section>
      <div className="report-cards">{reportCards}</div>
    </section>
  );
};

export default ReportCardsComponent;
