// REMEMBER: All other base-URLS are the "flaxil" terminology

const isProd = process.env.REACT_APP_BUILD_ENV === 'production';
const devPrefix = !isProd ? 'dev-' : '';
const localSuffix = process.env.REACT_APP_IS_LOCAL === 'true' ? '/local' : '';
const baseUrl = `https://${devPrefix}api.axil.ai${localSuffix}`;
const iamUrl = `${baseUrl}/iam`;
const reportingUrl = `${baseUrl}/reporting`;
const orgUrl = `${iamUrl}/orgs`;
const schedulesUrl = `${reportingUrl}/schedules`;

export class ReportsRepository {
  static async getReports(token) {
    try {
      const res = await fetch(reportingUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (res.ok) {
        const response = await res.json();
        return response;
      }

      return res;
    } catch (error) {
      return error;
    }
  }

  static async getScheduledReports(token) {
    try {
      const res = await fetch(schedulesUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (res.ok) {
        const response = await res.json();
        return response;
      }

      return res;
    } catch (error) {
      return error;
    }
  }

  static async getOrganizations(token) {
    try {
      const res = await fetch(orgUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        const response = await res.json();
        return response;
      }

      return res;
    } catch (error) {
      return error;
    }
  }

  static async getAreas(token, customerId) {
    try {
      const res = await fetch(`${orgUrl}/${customerId}/assets`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (res.ok) {
        const response = await res.json();
        return response.assets;
      }

      return res;
    } catch (error) {
      return error;
    }
  }

  static async submitImmediateReport(token, body, formattedName) {
    try {
      const res = await fetch(`${reportingUrl}/immediate/${formattedName}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });
      if (res.ok) {
        await res.json();
      }
      return res;
    } catch (error) {
      return error;
    }
  }

  static async submitScheduledReport(token, body) {
    try {
      const res = await fetch(`${schedulesUrl}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      if (res.ok) {
        await res.json();
      } else {
        return res.json({ msg: 'A report with that name already exists.' });
      }

      return res;
    } catch (error) {
      return error;
    }
  }

  static async deleteScheduledReport(token, reportName) {
    try {
      const res = await fetch(`${schedulesUrl}/${reportName}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        await res.json();
      }

      return res;
    } catch (error) {
      return error;
    }
  }
}
