import styled from 'styled-components';
import { Switch } from 'antd';

const StyledSwitch = styled(Switch)`
  // TODO: Probably a better color than this
  background-color: ${(props) => props.theme.widgets.background.dark};

  &.ant-switch-checked {
    background-color: ${(props) => props.theme.palette.buttons.primary.background.full};
  }
`;

export default StyledSwitch;
