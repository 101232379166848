import React from 'react';
import Modal from 'react-modal';

import AlertIcon from './AlertIcon';

import '../styles.scss';

const AlertModal = ({ showAlert, setShowAlert, alertMessage }) => {
  const closeModal = () => {
    setShowAlert(!showAlert);
  };

  setTimeout(closeModal, 3000);

  return (
    <Modal
      overlayClassName="ip-alert-modal-overlay"
      className="ip-alert-modal"
      isOpen={showAlert}
      onRequestClose={() => closeModal()}
    >
      <div className="ip-alert-container">
        <AlertIcon />
        <p>{alertMessage}</p>
      </div>
    </Modal>
  );
};

export default AlertModal;
