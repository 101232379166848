const initialState = false;

export const SET_CAN_GO_BACK_TRUE = 'SET_CAN_GO_BACK_TRUE';
export const SET_CAN_GO_BACK_FALSE = 'SET_CAN_GO_BACK_FALSE';

export const reducer = (state = initialState, { type }: { type: string }): boolean => {
  switch (type) {
    case SET_CAN_GO_BACK_TRUE:
      return true;
    case SET_CAN_GO_BACK_FALSE:
      return false;
    default:
      return state;
  }
};
