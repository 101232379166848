import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux'; 
import { Divider, Switch, Form, Button } from 'antd';
import {
  BulbFilled,
  BulbOutlined,
  CheckSquareOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';

import useFetch from '../hooks/useFetch';

const Container = styled.div`
  color: ${props => props.theme.widgets.color.full};
  & label {
    color: ${props => props.theme.widgets.color.full};
  }
  && .ant-form-item-control-input-content {
    color: ${props => props.theme.widgets.color.full};
  }
  && .ant-form-item-explain {
    color: ${props => props.theme.widgets.color.full};
  }
`;

const AccountSettings = ({ user = { sub: '', email: '' } }) => {
  const theme      = useSelector(state => state.resources.themeStyles.theme);
  const roundTheme = useSelector(state => state.resources.themeStyles.round);
  const demoMode   = useSelector(state => state.resources.demo);

  const [loading, resetStatus, resetPassword] = useFetch('/v1/axil/auth/password/reset');

  const dispatch = useDispatch();
  const toggleDarkMode  = bool => dispatch({ type: 'TOGGLE_DARK_MODE', payload: bool });
  const toggleRoundMode = bool => dispatch({ type: 'TOGGLE_ROUND_MODE', payload: bool });
  const toggleDemoMode  = bool => dispatch({ type: 'TOGGLE_DEMO_MODE', payload: bool });

  const disableReset = !user.sub.includes('auth0');
  const message = `Send password reset to: "${user.email}"`
  const helpMessage = disableReset &&
    'Password is managed by a third party. Please contact your systems administrator';
  const status = disableReset && 'error'

  return (
    <Container>
      <Form layout='vertical'>
        <Form.Item layout='horizontal' label='Theme: Light/Dark'>
          <Switch
            unCheckedChildren={<BulbOutlined />}
            checkedChildren={<BulbFilled />}
            checked={theme === 'dark'}
            onChange={() => {
              if (theme === 'dark') {
                return toggleDarkMode('light')
              }
              return toggleDarkMode('dark')
            }}
          />
        </Form.Item>
        <Form.Item label="Edges: Squared/Rounded">
          <Switch
            checkedChildren={<CheckCircleOutlined />}
            unCheckedChildren={<CheckSquareOutlined />}
            checked={roundTheme}
            onChange={() => toggleRoundMode(!roundTheme)}
          />
        </Form.Item>
        <Form.Item label={message} help={helpMessage} validateStatus={status}>
          <Button
            ghost
            danger
            disabled={disableReset}
            onClick={() => resetPassword({ email: user.email })}
          >
            Reset Password
          </Button>
        </Form.Item>
        <Divider />
        <Form.Item label="Enable Demo Mode">
          <Switch
            checked={demoMode.demo}
            onChange={() => toggleDemoMode({ demo: !demoMode.demo })}
          />
        </Form.Item>
      </Form>
    </Container>
  )
};

export default AccountSettings;
