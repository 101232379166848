import { GLU_ASSET_TYPE_ID, GLX_ASSET_TYPE_ID } from 'shared/constants/altitudeConstants';

export const isGasLiftAltitude = ({ asset_type_id, assetTypeId }) => [GLU_ASSET_TYPE_ID, GLX_ASSET_TYPE_ID].includes(asset_type_id || assetTypeId);

export const getSingleAssetBaseRoute = ({
  org_id,
  orgId,
  site_id,
  siteId,
  asset_id,
  assetId,
  device_id,
  deviceId,
}) => `/asset/${(org_id || orgId)}/${(site_id || siteId)}/${(asset_id || assetId)}/${(device_id || deviceId)}`;

export const getSingleAssetDefaultRoute = (asset) => `${getSingleAssetBaseRoute(asset)}${isGasLiftAltitude(asset) ? '/gas_lift' : ''}`;
