import React from 'react';

import './GLXConfigurationPage.scss';

export const GLXConfigurationPage = () => (
  <div className="GLXConfigurationPage">
    GLX ConfigurationPage content todo
  </div>
);

export default GLXConfigurationPage;
