import React, { ReactElement } from 'react';
import { mean } from 'ramda';
import moment from 'moment';
import MapTooltip from './MapTooltip';
import { MapAsset, MapPointProps } from './types';
import { ClusterPoint, SingleAssetButton } from './styles';

const MapPoint = ({
  isCluster,
  item,
  customKey,
  options,
  showTooltip,
  setCenterMap,
  tooltipOpen,
  setTooltipOpen,
}: MapPointProps): ReactElement => {
  const {
    colorOptions: { type, key, gradient },
    getAverages,
    getCounts,
    getValues,
  } = options;

  // TODO: Is this the best way to handle key check?
  const isValidMapAssetKey = (key: string): key is keyof MapAsset =>
    !!item.points[0][key as keyof MapAsset];

  const isValidNumber = (value?: string | number): value is number =>
    !!value && Number.isFinite(value);

  const keyCounter = (Object.keys(getCounts) as Array<keyof typeof getCounts>)
    .map((key) =>
      (getCounts[key] || []).map((value) => ({
        [value]: item.points.filter((assetList) => {
          const valueOfKey = assetList[key];

          return typeof valueOfKey === 'string'
            ? valueOfKey.toLowerCase() === value
            : !!valueOfKey && !!value;
        }).length,
      })),
    )
    .flat();

  // TODO: Revisit
  // Does a couple extra checks, maybe use this instead of Cluster's version?
  const keyAverage = getAverages.map((key) => {
    const averageKeyValue = {
      [key]: mean(item.points.map((point) => point[key]).filter(isValidNumber)).toFixed(2),
    };

    if (averageKeyValue[key] && averageKeyValue[key] !== 'NaN') {
      return averageKeyValue;
    }

    return { [key]: 'N/A' };
  });

  const getKeyValues = () =>
    getValues.map((obj) => {
      const { format = null, type, key } = obj;

      const assetKeyValue = isValidMapAssetKey(key) ? item.points[0][key] : undefined;

      return {
        [key]:
          type === 'timestamp' && !!format ? moment(assetKeyValue).format(format) : assetKeyValue,
      };
    });

  const pointColor = () => {
    let color = 'black';
    const green = '#2B8A3E';
    const red = '#C92A2A';
    const getColor = (color: typeof green | typeof red) => `${color}${isCluster ? '88' : ''}`;

    if (type === 'avg') {
      // TODO: Is it meant to do anything beyond setting the color to be the last non-warn item?
      // Would something like most common status make more sense?
      keyAverage.forEach((k) => {
        if (k) {
          if (parseFloat(k[key]) >= gradient.success) {
            color = getColor(green);
          } else if (parseFloat(k[key]) <= gradient.danger) {
            color = getColor(red);
          }
        }
      });
    }

    return color;
  };

  // TODO: Revisit
  // same as cluster except showTooltip check
  // is showTooltip always true?
  const onPointClick = () => {
    if (showTooltip) {
      const { lat, lng } = item;
      setCenterMap({ lat, lng });
      setTooltipOpen(tooltipOpen === customKey ? null : customKey);
    }
  };

  const singleAssetProps = { keyValues: getKeyValues() };

  return (
    <>
      {isCluster ? (
        <ClusterPoint
          size={Math.log(item.points.length) * 7}
          color={pointColor()}
          onClick={onPointClick}
        />
      ) : (
        <SingleAssetButton color={pointColor()} onClick={onPointClick} type="button">
          <i className="material-icons">room</i>
        </SingleAssetButton>
      )}
      <MapTooltip
        isCluster={isCluster}
        item={item}
        open={tooltipOpen === customKey}
        keyCounter={keyCounter}
        keyAverage={keyAverage}
        {...(!isCluster ? singleAssetProps : {})}
      />
    </>
  );
};

export default MapPoint;
