import { Modal } from 'antd';
import React, { ReactElement } from 'react';
import { useAppSelector } from '../../../store/hooks';
import WidgetSeries from '../WidgetSeries';
import { ChildChartProps } from './types';

export default ({
  childProps,
  handleCancel,
  isPopup,
  isVisible,
  props,
}: ChildChartProps): ReactElement => {
  const { id, parentId, roundedTheme } = props;

  const themeProps = useAppSelector((state) => state.resources.theme);

  const chartParams = {
    series: [
      {
        map: {
          epoch_ms: 'x',
          uom: 'uom',
          val: 'y',
        },
        tag_id: childProps.tag_id,
        label: childProps.label,
      },
    ],
  };

  const ChildWidgetSeries = () => (
    <WidgetSeries
      config={chartParams}
      containerHeight={200}
      id={id}
      parentId={parentId}
      props={{ ...props }}
      roundedTheme={roundedTheme}
    />
  );

  if (isPopup)
    return (
      <Modal
        visible={isVisible}
        onCancel={handleCancel}
        width="50%"
        footer={null}
        closable={false}
        bodyStyle={{ background: themeProps.widgets.background.full }}
      >
        <ChildWidgetSeries />
      </Modal>
    );
  return <ChildWidgetSeries />;
};
