import React from "react";
import "antd/dist/antd.css";
import { Table, Card, DatePicker } from "antd";
import { SettingOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const columns = [
  {
    title: "Calculated Statistics",
    dataIndex: "name",
    align: "center"
  },
  {
    title: "Flow Rate (mcfd)",
    className: "data",
    dataIndex: "text",
    align: "center"
  }
];

const data = [
  {
    key: "1",
    name: "VRU #1 (11234)",
    text: "92"
  },
  {
    key: "2",
    name: "VRU #2 (11283)",
    text: "81"
  },
  {
    key: "3",
    name: "Flare (87542)",
    text: "24"
  },
  {
    key: "4",
    name: "Total",
    text: "197"
  }
];

export default function FlowSummary() {
  return (
      <Card 
        title='Flow Meter Summary' 
        extra={[
        <RangePicker style={{margin: 10}} />,
        <SettingOutlined />
      ]}
      >
        <Table columns={columns} dataSource={data} bordered pagination={{hideOnSinglePage: true}} />
      </Card>
  );
};