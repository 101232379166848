import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  TableHead,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableTitle,
  StyledTable,
  HeaderRow
} from '../shared/Table';
import useFetch from '../hooks/useFetch';
import EditDiv from '../Editing/EditDiv';

const WidgetTable = ({ id, config, match, props, parentId, altitudeGroupId }) => {
  const editableLayout  = useSelector(state => state.layouts.editing.editable_layout);
  const { rows, width, headers, borderless, title, background } = props;
  const editMode = editableLayout === parentId;

  const [loading, data, fetchData] = useFetch(config.endpoint);

  useEffect(() => {
    fetchData({ ...match.params, ...config, filters: altitudeGroupId });
  }, [])

  const generateRows = (row, i, arr) => {
    const itemsInRow = Object.values(row).map(r => {
      return arr[0][r];
    }).filter(x => x);

    if (itemsInRow.length > 0) {
      return (
        <TableRow key={i}>
          <TableCell> { row.label } </TableCell>
          { itemsInRow.map((rowItem, index) => (
            <TableCell key={index}> {rowItem} </TableCell>
          ))}
        </TableRow>
      )
    }

    return null;
  }

  return (
    <div>
      { editMode && <EditDiv id={id} /> }
      <TableTitle>{title}</TableTitle>
      <StyledTable width={width} borderless={borderless} background={background}>
        <TableHead>
          { headers && (
            <HeaderRow>
              { headers.map(header => (
                <TableHeader key={header}>{header}</TableHeader>
              ))}
            </HeaderRow>
          )}
        </TableHead>
        <TableBody>
          { loading ? (<div> LOADING </div>) : rows.map((rows, index) => generateRows(rows, index, data)) }
        </TableBody>
      </StyledTable>
    </div>
  );
}

WidgetTable.propTypes = {
};

export default WidgetTable;
