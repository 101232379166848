import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Highcharts from 'react-highcharts';
import styled from 'styled-components';
import EditDiv from '../Editing/EditDiv';
import useFetch from '../hooks/useFetch';

const NoDataChart = styled.div`
  color: ${props => props.theme.widgets.color.full};
  width: 100%;
  text-align: center;
  padding: 89px 0;
`;

const Container = styled.div`
  height: 99%;
`;

const colors = [
  '#37B24D',
  '#4C6EF5',
  '#5F3DC4',
];

const WidgetChart = ({ id, parentId, props, config, match }) => {
  const [ chartSeries, setSeries ] = useState([]);
  const [ offset ] = useState(new Date().getTimezoneOffset())
  const [loading, data, fetchData] = useFetch(config.endpoint);
  const {
    type,
    chartTitle,
    chartSubTitle,
    height,
    yAxis,
  } = props;

  const editableLayout = useSelector (state => state.layouts.editing.editable_layout);
  const editMode = editableLayout === parentId;

  useEffect(() => {
    fetchData({ ...match.params, ...config, filters: -1 });
  }, []);

  useEffect(() => {
    if (!loading && data.length > 0) {
      if (data[0].chart) {
        if (data[0].chart.line_plot.data) {
          setSeries(data[0].chart.line_plot.data);
        }
      }
    }
  }, [loading, data])

  const formatSeriesData = (data, index) => {
    if (!yAxis) {
      return ({
        name: data.label,
        data: data.series,
        color: data.color || colors[index],
        zIndex: data.zIndex
      })
    };

    return ({
      name: data.label,
      data: data.series,
      color: data.color || colors[index],
      yAxis: index,
      zIndex: data.zIndex
    });
  };
  
  const decorateYAxis = (opposite) => chartSeries.map ((s, i) => {
    return {
      plotLines: [{
        color: 'red',
        width: 1,
        dashStyle: 'Dash',
        zIndex: 2
      }],
      opposite: opposite || i > 0,
      labels: {
        format: '{value:.2f}',
        style: {
          fontSize: '0.8em',
          color: colors[i]
        }
      },
      title: {
        text: null
      }
    };
  });

  const chartConfig = {
    chart: {
      type,
      height,
      zoomType: 'x',
    },
    scrollbar: {
      enabled: false
    },
    navigator: { 
      enabled: false
    },
    rangeSelector: {
      enabled: false,
    },
    time: {
      timezoneOffset: offset,
    },
    title:{
      text: chartTitle
    },
    subtitle: {
      text: chartSubTitle
    },
    yAxis: [
      ...decorateYAxis(),
    ],
    xAxis: {
      title: { text: 'Pressure(psi)' }
    },
    tooltip: {
      formatter: function() {
        let s = `<b>Production Rate: ${this.x}</b>`
        this.points.map(p => {
          return s += `<br/> ${p.series.name}: ${this.y}(psi)`
        });
        return s;
      },
      backgroundColor: 'rgba(255, 255, 255, 0.94)',
      borderColor: '#CCD1D9',
      borderRadius: 6,
      split: false,
      shared: true,
      valueDecimals: 2,
    },
    series: [
      ...chartSeries.map((s, i) => formatSeriesData(s, i))
    ],
    credits: false,
    responsive: {
      rules: [{
        condition: {
          maxWidth: 750
        },
        chartOptions: {
          yAxis: chartSeries.map((s, i) => ({
            labels: {
              rotation: -90,
              format: '{value}',
              style: { fontSize: 10 }
            }
          })),
        }
      }]
    }
  };

  return (
    <Container>
      { editMode && <EditDiv id={id} /> }
      { chartSeries.length > 0
        ? ( <Highcharts config={chartConfig} /> )
        : ( <NoDataChart>No Data for the current time range</NoDataChart> )
      }
    </Container>
  );
};

WidgetChart.propTypes = {
};

export default WidgetChart;
