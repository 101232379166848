import React from 'react';

const TitleComponent = ({ handleChange }) => {
  const scheduleReportTitleComponent = (
    <section className="report-section">
      <p className="displayed-options-title">Report Title</p>
      <div className="report-name-container">
        <input
          autoComplete="off"
          maxLength={24}
          type="text"
          placeholder="Name..."
          className="report-name-input"
          name="userReportName"
          onChange={(e) => handleChange(e)}
        ></input>
      </div>
    </section>
  );

  return scheduleReportTitleComponent;
};

export default TitleComponent;
