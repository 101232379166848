import * as glxProductionActionTypes from 'single-asset/actions/glxProductionActionTypes';

export const defaultState = {
  isPending: false,
  seriesList: [],
  failedResultList: [],
  dates: null,
};

const glxProductionReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case glxProductionActionTypes.GLX_PRODUCTION__FETCH_SERIES_LIST: {
      return {
        ...defaultState,
        isPending: true,
        dates: action.dates,
      };
    }

    case glxProductionActionTypes.GLX_PRODUCTION__FETCH_SERIES_LIST_FAILED: {
      const { failedResultList } = action;

      return {
        ...defaultState,
        failedResultList,
        dates: state.dates,
      };
    }

    case glxProductionActionTypes.GLX_PRODUCTION__FETCH_SERIES_LIST_SUCCEEDED: {
      return {
        ...defaultState,
        seriesList: action.successfulResultList,
        dates: state.dates,
      };
    }

    default: {
      return state;
    }
  }
};

export default glxProductionReducer;
