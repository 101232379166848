import React from 'react';
import Modal from 'react-modal';

const ReportConfirmationModal = ({
  nameOfReportToDelete,
  deleteScheduledReport,
  showConfirmation,
  setShowConfirmation,
}) => {
  const closeModal = () => {
    setShowConfirmation(!showConfirmation);
  };

  const deleteReport = () => {
    deleteScheduledReport(nameOfReportToDelete);
    closeModal();
  };

  return (
    <Modal
      overlayClassName="error-modal-overlay"
      className="error-modal"
      isOpen={showConfirmation === true}
      onRequestClose={() => closeModal()}
    >
      <article className="error-container">
        <div className="error-icon"></div>
        <h3 className="error-message">You are about to delete:</h3>
        <h3 className="error-message">{nameOfReportToDelete}</h3>
        <div className="buttons-container">
          <button className="error-button" onClick={() => deleteReport()}>
            Continue
          </button>
          <button className="error-button" onClick={() => closeModal()}>
            Cancel
          </button>
        </div>
      </article>
    </Modal>
  );
};

export default ReportConfirmationModal;
