import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
  background-color: rgba(33, 37, 41, 1);
  margin-left: -24px;
  margin-right: -24px;
`;

const IFrame = styled.iframe`
  border: 0;
  width: 600px;
  height: 1000px;

  @media (max-width: 768px) {
    width: 300px;
    height: 1000px;
  }
`;

export default function Feedback() {
  return (
    <>
      <Container>
        <IFrame
          src="https://flogistix.formstack.com/forms/axil_feedback_form"
          title="Flux Feedback Form"
        />
      </Container>
    </>
  );
}
