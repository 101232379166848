/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { StyledCheckbox, StyledButton, StyledModal, StyledSelect } from '../styled';

const AltitudeSelect = ({
  match: {
    params: { org_id, site_id, asset_id },
  },
  open,
  closeModal,
  setBuilder,
  assetTypeId,
  altitudeInfo,
  templateItem,
  selectedBuilder,
  org,
  site,
  asset,
  setOrg,
  setSite,
  setAsset,
  setTemplateItem,
  assetList,
}) => {
  const [orgSelectOptions, setOrgSelectOptions] = useState([]);
  const [siteSelectOptions, setSiteSelectOptions] = useState([]);
  const [assetSelectOptions, setAssetSelectOptions] = useState([]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const disableButton = !org?.value || !site?.value || !asset?.value;

  const reduceToAltitude = (arr, altLevel) => {
    return arr.reduce((acc, val) => {
      const findDupe = acc.find((x) => x[`${altLevel}_id`] === val[`${altLevel}_id`]);
      if (findDupe) {
        return acc;
      }
      return [...acc, { ...val, label: val[`${altLevel}_name`], value: val[`${altLevel}_id`] }];
    }, []);
  };

  useEffect(() => {
    if (assetList) {
      setOrgSelectOptions(reduceToAltitude(assetList, 'org'));
    }
  }, [assetList]);

  useEffect(() => {
    if (org && orgSelectOptions.length > 0) {
      const orgList = assetList.filter((a) => parseInt(a.org_id) === parseInt(org.value));
      setSiteSelectOptions(reduceToAltitude(orgList, 'site'));
    } else {
      setSiteSelectOptions([]);
    }
  }, [org, orgSelectOptions.length]);

  useEffect(() => {
    if (site && siteSelectOptions.length > 0) {
      const siteList = siteSelectOptions.filter(
        (s) => parseInt(s.site_id) === parseInt(site.value),
      );
      setAssetSelectOptions(reduceToAltitude(siteList, 'asset'));
    } else {
      setAssetSelectOptions([]);
    }
  }, [site, siteSelectOptions.length]);

  useEffect(() => {
    if (assetList.length > 0) {
      if (assetList.find((x) => x.org_id === parseInt(org_id))) {
        const { org_name } = assetList.find((x) => x.org_id === parseInt(org_id));
        setOrg({ label: org_name, value: org_id });
      }
      if (assetList.find((x) => x.site_id === parseInt(site_id))) {
        const { site_name } = assetList.find((x) => x.site_id === parseInt(site_id));
        setSite({ label: site_name, value: site_id });
      }
      if (assetList.find((x) => x.asset_id === parseInt(asset_id))) {
        const { asset_name } = assetList.find((x) => x.asset_id === parseInt(asset_id));
        setAsset({ label: asset_name, value: asset_id });
      }
      if (!org_id && !site_id && !asset_id) {
        setOrg({});
        setSite({});
        setAsset({});
      }
    }
  }, [assetList, org_id, site_id, asset_id]);

  useEffect(() => {
    if (assetTypeId > 0 && templateItem) {
      setBuilder(selectedBuilder);
    }
  }, [assetTypeId, templateItem]);

  return (
    <StyledModal
      visible={open}
      onCancel={closeModal}
      bodyStyle={{ paddingBottom: 0 }}
      footer={[
        <div style={{ display: 'flex' }}>
          <StyledCheckbox
            onClick={(e) => setTemplateItem(e.target.checked)}
            style={{ marginRight: 'auto' }}
          >
            Add widget to default template
          </StyledCheckbox>
          <StyledButton
            disabled={disableButton}
            type="primary"
            onClick={() => setBuilder(selectedBuilder)}
          >
            Next
          </StyledButton>
          <StyledButton type="link" onClick={closeModal}>
            Cancel
          </StyledButton>
        </div>,
      ]}
    >
      <Form {...formItemLayout}>
        <Form.Item label="Org">
          <StyledSelect
            options={orgSelectOptions}
            value={org?.label}
            onChange={(op) => {
              setOrg(op);
              setSite({});
              setAsset({});
            }}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item label="Site">
          <StyledSelect
            options={siteSelectOptions}
            value={site?.label}
            onChange={(op) => {
              setSite(op);
              setAsset({});
            }}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item label="Asset">
          <StyledSelect
            options={assetSelectOptions}
            value={asset?.label}
            onChange={setAsset}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>
    </StyledModal>
  );
};

AltitudeSelect.defaultProps = {
  open: null,
  selectedBuilder: null,
};

AltitudeSelect.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      org_id: PropTypes.string,
      site_id: PropTypes.string,
      asset_id: PropTypes.string,
    }),
  }).isRequired,
  open: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  setBuilder: PropTypes.func.isRequired,
  altitudeInfo: PropTypes.array.isRequired,
  selectedBuilder: PropTypes.string,
  org: PropTypes.object.isRequired,
  site: PropTypes.object.isRequired,
  asset: PropTypes.object.isRequired,
  setOrg: PropTypes.func.isRequired,
  setSite: PropTypes.func.isRequired,
  setAsset: PropTypes.func.isRequired,
  setTemplateItem: PropTypes.func.isRequired,
};

export default AltitudeSelect;
