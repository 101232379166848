import React, { useState } from 'react';
import Modal from 'react-modal';

const ReportOptionsModal = ({
  reports,
  selectedType,
  showOptions,
  setShowOptions,
  handleReportCheckableChange,
  handleReportSelectChange,
  setSelectedType,
}) => {
  const [currentSelectOption, setSelectedOption] = useState({});
  const currentReport = reports.filter((r) => r.title === selectedType)[0];
  const reportOptions =
    currentReport.options && currentReport.options.properties
      ? currentReport.options.properties
      : [];
  const allOptionEntries = Object.entries(reportOptions);
  const optionEntries = allOptionEntries.map((a) => a[1]);
  const booleanOptions = optionEntries.length
    ? optionEntries.filter((e) => (e.format === 'checkbox' || e.format === 'none') && e.name !== 'blowdownSummary')
    : [];

  // handles one select option property
  // will need to change to handle multiple select options if a new report calls for it
  const selectOptions = optionEntries ? optionEntries.filter((e) => e.format === 'select') : [];
  const selectPropertyName = selectOptions[0].name;

  const displayedSelectOptions = [
    <option key="placeholder-select-value" className="select-option">
      Select Value
    </option>,
  ];

  if (selectOptions.length) {
    selectOptions.map((option) => {
      if (option.options && option.options.length) {
        option.options.map((o) => {
          const newOption = (
            <option key={o} className="select-option" value={o}>
              {o}
            </option>
          );

          displayedSelectOptions.push(newOption);
          return;
        });
      }
    });
  }

  const displayedSelectComponent = (
    <select
      onChange={(e) => setSelectedOption(e.target)}
      className="additional-option-select"
      name={selectPropertyName}
    >
      {displayedSelectOptions}
    </select>
  );

  const formattedCheckboxOptions = booleanOptions.map((o) => {
    return { name: o.name, value: true };
  });

  const [finalOptions, setOptions] = useState(formattedCheckboxOptions);

  const displayedCheckboxOptions = booleanOptions.map((booleanOption) => {
    const [showCheck, setShowCheck] = useState(true);
    const toggle = () => {
      if (showCheck === true) {
        const newOptions = [];

        finalOptions.map((finalOption) => {
          if (finalOption.name === booleanOption.name) {
            return newOptions.push({ name: finalOption.name, value: false });
          }
          return newOptions.push(finalOption);
        });

        setOptions(newOptions);
        setShowCheck(!showCheck);
      } else {
        const newOptions = [];

        finalOptions.map((finalOption) => {
          if (finalOption.name === booleanOption.name) {
            return newOptions.push({ name: finalOption.name, value: true });
          }
          return newOptions.push(finalOption);
        });

        setOptions(newOptions);
        setShowCheck(!showCheck);
      }
    };

    if (!booleanOption.label) return;

    return (
      <div
        key={`${booleanOption.label} ${Date.now()}`}
        className={showCheck ? 'additional-boolean-option' : 'additional-boolean-option-deselected'}
        onClick={() => toggle()}
      >
        <p className="option-title">{booleanOption.label}</p>
      </div>
    );
  });

  const displayedCheckboxComponent = (
    <div className="displayed-checkboxes">{displayedCheckboxOptions}</div>
  );

  const handleCheckableSubmit = () => {
    handleReportCheckableChange(finalOptions);
    setShowOptions(!showOptions);
  };

  const handleSelectSubmit = () => {
    handleReportSelectChange(currentSelectOption);
    setShowOptions(!showOptions);
  };

  const handleSubmit = () => {
    if (displayedSelectOptions.length) {
      handleSelectSubmit();
    }

    if (displayedCheckboxOptions.length > 1) {
      handleCheckableSubmit();
    }
  };

  const closeModal = () => {
    setSelectedType('');
    setShowOptions(!showOptions);
  };

  return (
    <Modal
      overlayClassName="options-modal-overlay"
      className="options-modal"
      isOpen={showOptions}
      onRequestClose={() => closeModal()}
    >
      <div className="options-header">
        <p className="options-header-text">Your Report</p>
        <p className="options-close" onClick={() => closeModal()}>
          X
        </p>
      </div>
      <div className="options-container">
        {booleanOptions.length > 2 ? (
          <>
            <p className="options-details-info">
              deselect all unwanted report details and then click submit
            </p>
            {displayedCheckboxComponent}
          </>
        ) : (
          displayedSelectComponent
        )}
        <div className="gap"></div>
        <div className="buttons-container">
          <button className="report-submit-button" onClick={() => handleSubmit()}>
            Submit
          </button>
          <button className="report-submit-button" onClick={() => closeModal()}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ReportOptionsModal;
