export const singleAssetBaseRoutePath = '/asset/:org_id/:site_id/:asset_id/:device_id';

export const singleAssetRouteNames = {
  GAS_LIFT: 'gas_lift',
  EVENTS: 'events',
  WORK_ORDERS: 'work_orders',
  DIGITAL_TWIN: 'digital_twin',
  MORE_INFO: 'more_info',
  INFORMATION: 'information',
  CONFIGURATION: 'configuration',
};

export const singleAssetRoutePaths = {
  GAS_LIFT: `${singleAssetBaseRoutePath}/${singleAssetRouteNames.GAS_LIFT}`,
  EVENTS: `${singleAssetBaseRoutePath}/${singleAssetRouteNames.EVENTS}`,
  WORK_ORDERS: `${singleAssetBaseRoutePath}/${singleAssetRouteNames.WORK_ORDERS}`,
  DIGITAL_TWIN: `${singleAssetBaseRoutePath}/${singleAssetRouteNames.DIGITAL_TWIN}`,
  MORE_INFO: `${singleAssetBaseRoutePath}/${singleAssetRouteNames.MORE_INFO}`,
  INFORMATION: `${singleAssetBaseRoutePath}/${singleAssetRouteNames.INFORMATION}`,
  CONFIGURATION: `${singleAssetBaseRoutePath}/${singleAssetRouteNames.CONFIGURATION}`,
};
