import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Divider, Collapse, DatePicker } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { Player } from 'video-react'
import { BarLoader } from 'react-spinners';
import moment from 'moment';
import ReactHlsPlayer from 'react-hls-player';
import { pathOr } from 'ramda'; 

import useFetch from '../hooks/useFetch';


// THIS IS FOR TESTING AND DEMO PURPOSES
// WE WILL BE REMOVING THE TWITCH PLAYER
// AND REPLACING IT WITH AN HLS PLAYER.
//
// WE WILL NEED TO REMOVE THE DEPENDENCY
// FOR "ReactTwitchEmbededVideo" AFTER. 

const { Panel } = Collapse;

const Container = styled.div`
  ${props => `
    background: ${props.theme.widgets.background.full};
    box-shadow: ${props.theme.widgets.border.boxShadow};
    border-radius: ${props.rounded ? '12px' : 'none' };
    height: 100%;
    overflow: hidden;

    && p {
      color: ${props.theme.widgets.color.full}
    }
  `}
`;

const Center = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, .65);
  z-index: 1000;
`;

const StyledDatePicker = styled(DatePicker)`
  ${props => `
     background: ${props.theme.widgets.background.full};
     color: ${props.theme.widgets.color.full};
     width: 100%;

     & .ant-picker-input > input {
       color: ${props.theme.widgets.color.full};
     }
  `}
`

const Body = styled.div`
  display: flex;
  height: 100%;

  .video-react-controls-enabled, .video-react-paused,
  .video-react-fluid, .video-react-user-inactive,
  .video-react-workinghover, .video-react {
    height: 100%;
  }

  & .video-react, .video-react-video: {
    border-radius: ${props => props.rounded ? '12px' : 'none' };
  }
`;

const VideoList = styled.div`
  max-width: 25%;
  flex: 1 1 25%;
`;

const DateContainer = styled.div`
  padding: 12px;
  minHeight: 12%;
`;

const VideoPlayerContainer = styled.div`
  flex: 2 2 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
`;

const DownloadLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #3bb1f7;
  margin-bottom: 12px;

  && p {
    color: #3bb1f7;
    font-size: 12px;
    margin: 0px;
    cursor: pointer;
  }

  && a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #3bb1f7;
  }
`;

const StyledCollapse = styled(Collapse)`
  ${props => `
    &&& {
      border: none;

      .ant-collapse-header {
         background: ${props.theme.widgets.background.full};
         color: ${props.theme.widgets.color.full};
      }
      .ant-collapse-content-box {
         background: ${props.theme.widgets.background.light};
         color: ${props.theme.widgets.color.full};

         > h4, h5 {
           color: ${props.theme.widgets.color.full};
         }
      }
    }
  `}
`;

const WidgetVideoPlayer = ({ 
  config, 
  match, 
  props, 
  roundedTheme,
 }) => {
  const defaultStart = moment().startOf('month').format('YYYY-MM-DD');
  const defaultStop = moment().endOf('month').format('YYYY-MM-DD');

  const [dates, setDates]      = useState({ start: defaultStart, stop: defaultStop });
  const [stream, setStream]    = useState(false);
  const [active, setActive]    = useState('');
  const [activeTab, setActiveTab] = useState([0]);

  const [loading, videoData, fetchVideoData] = useFetch('/v2/axil/series/hot/by_date');

  useEffect(() => {
    fetchVideoData({
      ...config,
      ...dates,
      series: config.series.map(s => ({ ...s, ...match.params, device_id: -1, asset_id: -1 }))
    }
  )}, [dates]);

  useEffect(() => {
    setActiveTab(pathOr([1], [0, 'data'], videoData).length -1);
  }, [videoData]);

  const handleDatePicker = dateObj => {
    const start = moment(dateObj).startOf('month').format('YYYY-MM-DD');
    const stop = moment(dateObj).endOf('month').format('YYYY-MM-DD');
    return setDates({ start, stop });
  };

  const viewVideoOrStream = (type, url) => {
    switch(type) {
      case 'stream':
        setStream(true);
        setActive(url)
        break;
      case 'video':
        setStream(false);
        setActive(url)
        break;
      default:
        return null;
    }
  }

  const hasData = ({ stream_urls = [] , document_urls = [], image_urls = [], video_urls =[] }) => {
    if (stream_urls.length == 0 && document_urls == 0 && image_urls.length == 0 && video_urls == 0) {
      return <h5> There is currently no data for this inspection </h5>
    }
    return null;
  };

  return(
    <Container rounded={roundedTheme}>  
      { loading && (<Center><BarLoader color='#FF6600' /></Center>) }
      <Body rounded={roundedTheme}>
        <VideoList>
          <DateContainer>
            <StyledDatePicker allowClear={false} onChange={handleDatePicker} picker="month" />
          </DateContainer>
          <Divider style={{ margin: '8px auto 0 auto'}} />
          <div style={{ height: '400px', overflowY: 'scroll'}}>
          <StyledCollapse defaultActiveKey={activeTab} ghost accordion>
            { !loading && videoData[0].data.map((x, index) => (
              <Panel header={moment(x.epoch_ms).format('MMMM Do YYYY')} key={index}>
                <h4>{x.title}</h4>
                { hasData(x) }
                { x?.stream_urls && x.stream_urls.length > 0 && (
                  <>
                    <Divider style={{ margin: '8px auto'}} />
                    <h5>Current Live Streams</h5>
                    { x.stream_urls.map(stream => (
                      <DownloadLink>
                        <p onClick={() => viewVideoOrStream('stream', stream)}> {moment(x.epoch_ms).format('LTS')} </p>
                      </DownloadLink>
                    ))}
                  </>
                )}
                { x?.video_urls && x.video_urls.length > 0 && (
                  <>
                    <Divider style={{ margin: '8px auto'}} />
                    <h5>Videos</h5>
                    { x.video_urls.map(video => (
                      <DownloadLink>
                        <p onClick={() => viewVideoOrStream('video', video)}> { moment(x.epoch_ms).format('LTS')} </p>
                        <a href={video} target='_blank'>
                          <CloudDownloadOutlined style={{ cursor: 'pointer'}} />
                        </a>
                      </DownloadLink>
                    ))}
                  </>
                )}
                { x?.document_urls && x.document_urls.length > 0 && (
                  <>
                    <Divider style={{ margin: '8px auto'}} />
                    <h5>Documents</h5>
                    { x.document_urls.map(file => (
                      <DownloadLink>
                        <a style={{ width: '100%'}} href={file} target='_blank'>
                          <p> { moment(x.epoch_ms).format('LTS')} </p>
                          <CloudDownloadOutlined style={{ cursor: 'pointer'}} />
                        </a>
                      </DownloadLink>
                    ))}
                  </>
                )}
                { x?.image_urls && x.image_urls.length > 0 && (
                  <>
                    <Divider style={{ margin: '8px auto'}} />
                    <h5>Images</h5>
                    { x.image_urls.map(image => (
                      <DownloadLink>
                        <a style={{ width: '100%'}} href={image} target='_blank'>
                          <p> { moment(x.epoch_ms).format('LTS')} </p>
                          <CloudDownloadOutlined style={{ cursor: 'pointer'}} />
                        </a>
                      </DownloadLink>
                    ))}
                  </>
                )}
              </Panel>
            ))}
          </StyledCollapse>
          </div>
        </VideoList>
        <VideoPlayerContainer>
          { stream ? (
            <ReactHlsPlayer
              url={active}
              autoplay={false}
              controls
            />
            ) : (
              <div style={{ width: '690px'}}>
                <Player playsInLine src={active} />
              </div>
          )}
        </VideoPlayerContainer>
      </Body>
    </Container>  
  )
};

export default WidgetVideoPlayer;
