import React from 'react';
import { Col } from 'antd';

import ProductionChart from 'single-asset/components/ProductionChart';

const ProductionChartList = (({ chartData, wellName }) => chartData.map((series) => (
  <Col span={24} key={series.id}>
    <ProductionChart
      series={series}
      wellName={wellName}
    />
  </Col>
)));

export default ProductionChartList;
