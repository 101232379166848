import styled from 'styled-components';

export const LegendWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const LegendToggleWrapper = styled.div`
  padding: 0 12px;
`;

export const LegendYAxisToggleWrapper = styled(LegendToggleWrapper)`
  width: 10%;
  display: flex;
  justify-content: flex-end;
`;

export const LegendItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 90%;
`;

export const SingleLegendItem = styled.div`
  display: flex;
  align-items: center;
`;

export const LegendDot = styled.div`
  border-radius: 50%;
  height: 8px;
  min-width: 8px;
  background: ${({ color }) => color || 'black'};
  margin-right: 6px;
`;

export const LegendName = styled.div`
  font-weight: 600;
  color: ${(props) => props.theme.widgets.color.full};
`;

export const LegendStat = styled.span`
  margin-left: 6px;
  padding-right: 6px;
  color: ${(props) => props.theme.widgets.color.full};
`;

export const Container = styled.div<{ containerHeight?: number; rounded: boolean }>`
  ${(props) => `
    background: ${props.theme.widgets.background.full};
    box-shadow: ${props.theme.widgets.border.boxShadow};
    border: ${props.theme.widgets.border.border.full};
    border-radius: ${props.rounded ? '12px' : 'none'};
    padding-left: 8px;
    height: ${props.containerHeight ? `${props.containerHeight}px` : '100%'};
    width: 100%;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    h2, h3 {
      color: ${props.theme.widgets.color.full}
      margin-bottom: 0;
    }
  `}
`;

export const ChartContainer = styled.div<{ legendHeight: number }>`
  height: calc(100% - ${(props) => props.legendHeight}px - 24px);
  width: 100%;
`;

export const Footer = styled.div`
  ${(props) => `
     color: ${props.theme.widgets.color.full};
     font-size: 10px;
     font-weight: 200;
     font-style: italic;
  `}
`;
