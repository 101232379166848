import React, { Component } from 'react';
import { Result, Button } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'; 

import { setErrors } from '../store/resources';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch() {
    const {
      setErrors,
      info,
      info: {
        location: {
          pathname
        },
        match: {
          params
        }
      }
    } = this.props;

    const fullErrorMessage = {
      errors: {
        [`error_boundary_${pathname}`]: {
          widget_id: info.i,
          parent_id: info.parentId
        },
      },
      ...params,
      dashboard_id: info.dashboardId
    };

    setErrors(fullErrorMessage); 
  }
  
  render() { 
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Result
          status='warning'
          title='There was an error with your operation. Please try refreshing the page.'
          extra={
            <Button type='secondary' onClick={() => window.location.reload()}> Refresh </Button>
          }
        />
      )
    }
    return children;
  }
}

const mapDispatchToProps = dispatch => ({
  setErrors: bindActionCreators(setErrors, dispatch)
});

export default connect(null, mapDispatchToProps)(ErrorBoundary);
