/* eslint-disable radix */

// TODO: Changed the verbage from Warning -> Alarm, Danger -> Faulted, Success -> Running
// This needs to be dynamic in the future

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Switch, Form, message } from 'antd';
import styled from 'styled-components';

import { useListFilter } from '../hooks/useFilter.tsx';
import { StyledTable, StyledInput, StyledButton, StyledSelect, StyledCheckbox } from '../styled';

const timeOptions = [
  { value: 0, label: '12am' },
  { value: 1, label: '1am' },
  { value: 2, label: '2am' },
  { value: 3, label: '3am' },
  { value: 4, label: '4am' },
  { value: 5, label: '5am' },
  { value: 6, label: '6am' },
  { value: 7, label: '7am' },
  { value: 8, label: '8am' },
  { value: 9, label: '9am' },
  { value: 10, label: '10am' },
  { value: 11, label: '11am' },
  { value: 12, label: '12pm' },
  { value: 13, label: '1pm' },
  { value: 14, label: '2pm' },
  { value: 15, label: '3pm' },
  { value: 16, label: '4pm' },
  { value: 17, label: '5pm' },
  { value: 18, label: '6pm' },
  { value: 19, label: '7pm' },
  { value: 20, label: '8pm' },
  { value: 21, label: '9pm' },
  { value: 22, label: '10pm' },
  { value: 23, label: '11pm' },
];

const Container = styled.div`
  color: ${(props) => props.theme.widgets.color.full};
  & label {
    color: ${(props) => props.theme.widgets.color.full};
  }
  && .ant-form-item-control-input-content {
    color: ${(props) => props.theme.widgets.color.full};
  }
  && .ant-form-item-explain {
    color: ${(props) => props.theme.widgets.color.full};
  }
`;

const SubText = styled.div`
  font-size: 13px;
  color: #adadad;
  margin: 10px 0 6px 0;
`;

const TableFilterDiv = styled.div`
  display: flex;

  .toggle {
    color: ${(props) => props.theme.widgets.color.full};
    display: flex;
    width: 90%;
    justify-content: flex-end;
  }
`;

const NewNotificationSettings = ({
  user: { email = '' },
  userSettings,
  setUserSettings,
  selectedRows,
  setSelectedRows,
  updatePreferences,
  assets,
  savingSubs,
  saveSubs,
  saveStatus,
}) => {
  const [changes, setChanges] = useState([]);
  const [filterBySelect, toggleFilterBySelected] = useState(false);
  const [filteredSelectList, setFilteredSelectList] = useState([]);

  const { setFilter, filteredList } = useListFilter(assets, [
    'asset_name',
    'driver',
    'model',
    'org_name',
    'site_name',
  ]);

  const dispatch = useDispatch();
  const setNotificationsRedux = (arr) =>
    dispatch({ type: 'STORE_NOTIFICATION_LIST', payload: arr });
  const roundedTheme = useSelector((state) => state.resources.themeStyles.round);

  const handleNotificationChange = ({ settings, send_sms }) => {
    if (userSettings && !userSettings.phone_number) {
      return alert('You must enter a phone number to receive text notifications.');
    }

    setUserSettings({ ...userSettings, send_sms });
  };

  const customCheckboxRow = (row, type) => (
    <StyledCheckbox
      checked={selectedRows?.[row.asset_id]?.[type]}
      onClick={(e) => {
        setChanges([...changes, row.asset_id]);
        if (e.target.checked) {
          setSelectedRows({
            ...selectedRows,
            [row.asset_id]: {
              ...selectedRows[row.asset_id],
              [type]: true,
            },
          });
        } else {
          setSelectedRows({
            ...selectedRows,
            [row.asset_id]: {
              ...selectedRows[row.asset_id],
              [type]: false,
            },
          });
        }
      }}
    />
  );

  const filterBySelected = (checked) => {
    if (checked) {
      const rowsToShow = Object.entries(selectedRows)
        .filter((row) => {
          const values = Object.values(row[1]);
          return values.includes(true);
        })
        .map((row) => parseInt(row[0]));

      const newList = filteredList.filter((item) => rowsToShow.includes(item.asset_id));
      setFilteredSelectList(newList);
      toggleFilterBySelected(true);
    } else {
      setFilteredSelectList([]);
      toggleFilterBySelected(false);
    }
  };

  const handleChangesAndSubmit = () => {
    const uniqueValues = [...new Set(changes.map((x) => parseInt(x)))];
    const changedRows = uniqueValues.reduce((acc, val) => {
      return { ...acc, [val]: selectedRows[val] };
    }, {});

    updatePreferences(userSettings);
    saveSubs({ subscriptions: changedRows });
    setNotificationsRedux({ ...selectedRows, changedRows });
  };

  const columns = [
    { title: 'Asset Name', dataIndex: 'asset_name' },
    { title: 'Lease Name', dataIndex: 'site_name' },
    { title: 'Org Name', dataIndex: 'org_name' },
    { title: 'Debug', render: (row) => customCheckboxRow(row, 'debug') },
    { title: 'Info', render: (row) => customCheckboxRow(row, 'info') },
    { title: 'Alarms', render: (row) => customCheckboxRow(row, 'warning') },
    { title: 'Faults', render: (row) => customCheckboxRow(row, 'danger') },
    { title: 'Running', render: (row) => customCheckboxRow(row, 'success') },
  ];

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  useEffect(() => {
    if (!savingSubs) {
      if (saveStatus.results === 'OK') {
        return message.success('Your changes have been saved!');
      }
      // useFetch will eventually give error message
      return message.error('Oops..there was an error: Error Saving Subs');
    }
  }, [savingSubs]);

  return (
    <Container>
      <SubText>Manage how you want to receive notifications</SubText>
      <Form {...layout}>
        <Form.Item label="Send an email to">
          {email}
          <StyledCheckbox
            style={{ marginLeft: '6px ' }}
            checked={userSettings && userSettings.send_email}
            onChange={(e) =>
              setUserSettings({
                ...userSettings,
                send_email: e.target.checked,
              })
            }
          />
        </Form.Item>
        {userSettings && userSettings.phone_number && (
          <Form.Item label="Send a text to">
            <>
              {userSettings && userSettings.phone_number ? userSettings.phone_number : ''}
              <StyledCheckbox
                style={{ marginLeft: '6px ' }}
                checked={userSettings?.send_sms}
                onChange={(e) =>
                  handleNotificationChange({
                    ...userSettings,
                    send_sms: e.target.checked,
                  })
                }
              />
            </>
          </Form.Item>
        )}
        <Form.Item label="Do Not Disturb">
          <StyledCheckbox
            checked={userSettings?.dnd}
            onChange={(e) => setUserSettings({ ...userSettings, dnd: e.target.checked })}
          />
        </Form.Item>
        <Form.Item label="Set Do Not Disturb Hours">
          <StyledSelect
            onChange={(val) => setUserSettings({ ...userSettings, dnd_start: val.value })}
            value={userSettings?.dnd_start}
            style={{ width: '200px' }}
            options={timeOptions}
          />
          <span style={{ padding: '0 6px' }}> to </span>
          <StyledSelect
            onChange={(val) => setUserSettings({ ...userSettings, dnd_stop: val.value })}
            value={userSettings?.dnd_stop}
            style={{ width: '200px' }}
            options={timeOptions}
          />
        </Form.Item>
        <div style={{ paddingTop: '12px', width: '100%' }}>
          <div>
            <SubText> Select all assets you would like notifications from </SubText>
            <TableFilterDiv>
              <StyledInput
                className="filter"
                placeholder="Filter"
                style={{ width: '200px', margin: '12px 0' }}
                onChange={(e) => setFilter(e.target.value)}
                rounded={roundedTheme}
              />
              <span className="toggle">
                Filter by Selected
                <Switch onChange={(checked) => filterBySelected(checked)} />
              </span>
            </TableFilterDiv>
          </div>
          <div>
            <StyledTable
              rowKey="asset_id"
              columns={columns}
              dataSource={filterBySelect ? filteredSelectList : filteredList}
            />
          </div>
        </div>
        <Form.Item>
          <StyledButton onClick={handleChangesAndSubmit} type="primary">
            Save Changes
          </StyledButton>
        </Form.Item>
      </Form>
    </Container>
  );
};

export default NewNotificationSettings;
