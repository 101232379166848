import React from 'react';
import {
  AreaChartOutlined,
  BellOutlined,
  ContainerOutlined,
  ControlOutlined,
  BlockOutlined,
  RadarChartOutlined,
} from '@ant-design/icons';

import { singleAssetRouteNames } from 'single-asset/constants/singleAssetConstants';
import { getSingleAssetBaseRoute, isGasLiftAltitude } from 'shared/helpers/altitudeHelpers';
import Template from 'components/Template';
import SubNav from 'components/Framework/SubNav';

const SingleAssetNav = ({
  asset,
  hasModel,
}) => {
  const baseRoute = getSingleAssetBaseRoute(asset);

  return (
    <SubNav
      className="SingleAsset-Nav"
      navConfig={[{
        title: 'SMART Gas Lift',
        hidden: !isGasLiftAltitude(asset),
        icon: <ControlOutlined style={{ paddingRight: '8px', fontSize: 18 }} />,
        path: `${baseRoute}/${singleAssetRouteNames.GAS_LIFT}`,
      },
      {
        title: 'Telemetry',
        path: `${baseRoute}`,
        icon: <AreaChartOutlined style={{ paddingRight: '8px', fontSize: 18 }} />,
        navExact: true,
        component: Template,
      },
      {
        title: 'Events',
        path: `${baseRoute}/${singleAssetRouteNames.EVENTS}`,
        icon: <BellOutlined style={{ paddingRight: '8px', fontSize: 18 }} />,
        subNav: true,
        component: Template,
      },
      {
        title: 'Work Orders',
        path: `${baseRoute}/${singleAssetRouteNames.WORK_ORDERS}`,
        icon: <ContainerOutlined style={{ paddingRight: '8px', fontSize: 18 }} />,
      },
      {
        title: '3D Digital Twin',
        hidden: !hasModel,
        path: `${baseRoute}/${singleAssetRouteNames.DIGITAL_TWIN}`,
        icon: <RadarChartOutlined style={{ paddingRight: '8px', fontSize: 18 }} />,
      },
      {
        title: 'More Information',
        path: `${baseRoute}/${singleAssetRouteNames.MORE_INFO}`,
        icon: <BlockOutlined style={{ paddingRight: '8px', fontSize: 18 }} />,
      }]}
    />
  );
};

export default SingleAssetNav;
