import React from 'react';
import {
  Row, Col, DatePicker,
} from 'antd';

const ProductionForm = ({
  getWellProductionByDate,
  defaultDates,
  dates,
}) => {
  const defaultDatePickerValue = dates || defaultDates;

  const handleDateRangeChange = (newDateRange) => {
    getWellProductionByDate(newDateRange);
  };

  return (
    <form className="ProductionForm">
      <Row gutter={[0, 16]} justify="center">
        <Col span={24}>
          <DatePicker.RangePicker
            defaultValue={defaultDatePickerValue}
            onChange={handleDateRangeChange}

          />
        </Col>
      </Row>
    </form>
  );
};

export default ProductionForm;
