import React from 'react';
import { ScaleLoader } from 'react-spinners';

import './GasLiftLoadingIndicator.scss';

const GasLiftLoadingIndicator = ({ loadingMessage }) => (
  <div className="GasLiftLoadingIndicator">
    <ScaleLoader />
    <div className="GasLiftLoadingIndicator--loadingMessage">{loadingMessage}</div>
  </div>
);

export default GasLiftLoadingIndicator;
