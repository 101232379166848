import React from 'react';
import {
  Button,
  Form,
} from 'antd';

import './WellInformationEditFooter.scss';

const WellInformationEditFooter = ({
  onSubmit,
  canSubmit,
  onCancel,
}) => {
  const submitButtonProps = {
    ...(canSubmit
      ? { onClick: onSubmit }
      : { disabled: true }
    ),
  };

  return (
    <Form.Item label=" " colon={false}>
      <div
        className="well-information-edit-footer"
      >
        <Button
          className="flo-button"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          className="flo-button"
          {...submitButtonProps}
        >
          Save
        </Button>
      </div>
    </Form.Item>
  );
};

export default WellInformationEditFooter;
