import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';
import { StyledButton } from '../styled';

const Container = styled.div`
  overflow: visible;
  position: relative;

  && button {
    background: ${(props) => props.theme.widgets.background.light};
    color: ${(props) => props.theme.widgets.color.full};
    border: ${(props) => props.theme.widgets.border.border.light};
  }
`;

const EditWidgetButton = ({ match, id, parentId }) => {
  const editableLayout = useSelector((state) => state.layouts.editing.editable_layout);

  const dispatch = useDispatch();
  const setEditableLayout = (id) => dispatch({ type: 'SET_EDITABLE_LAYOUT', payload: id });
  const setConfirmEdits = (bool) => dispatch({ type: 'CONFIRM_EDITS', payload: bool });

  // 1. Toggles edit mode
  // 2. When leaving edit mode submit the changed layout
  const handleClick = () => {
    if (editableLayout === '') {
      setEditableLayout(parentId);
    } else {
      setConfirmEdits(true);
      setEditableLayout('');
    }
  };

  return (
    <Container>
      <StyledButton
        icon={<EditOutlined />}
        size="large"
        block
        onClick={handleClick}
        disabled={editableLayout}
      >
        {editableLayout ? 'You are in Edit Mode' : `Edit ${parentId}`}
      </StyledButton>
    </Container>
  );
};

EditWidgetButton.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
  id: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired,
};

export default EditWidgetButton;
