import React from 'react';

import TelemetryStat from 'single-asset/components/TelemetryStat';

import './TelemetryStatsList.scss';

const TelemetryStatList = ({ statsList }) => (
  <div className="TelemetryStatsList">
    {statsList.map((stat) => (
      <TelemetryStat
        key={stat.props.title}
        stat={stat}
      />
    ))}
  </div>
);

export default TelemetryStatList;
