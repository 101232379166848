import React from 'react';
import { Card } from 'antd';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import { calculatePropertyName, formatXAxisPropertyToDatestring } from 'single-asset/helpers/glxProductionHelpers';

const ProductionChart = ({
  series: {
    seriesConfig: { name, tagList },
    seriesResult: { data },
  },
  wellName,
}) => {
  const xAxisPropertyName = 'x';
  const formatttedChartData = formatXAxisPropertyToDatestring(data, xAxisPropertyName);

  return (
    <Card style={{ borderRadius: '12px', marginBottom: '18px' }} title={`${wellName} - ${name}`}>
      <ResponsiveContainer width="100%" height={220}>
        <LineChart
          data={formatXAxisPropertyToDatestring(formatttedChartData, xAxisPropertyName)}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <XAxis dataKey={xAxisPropertyName} />
          {tagList.map((chart, i) => (
            <YAxis
              key={chart.label}
              orientation={i > 0 ? 'right' : 'left'}
              stroke={chart.stroke}
              yAxisId={chart.label}
            />
          ))}
          {tagList.map((chart) => (
            <Line
              strokeWidth={2}
              dot
              type="linear"
              stroke={chart.stroke}
              dataKey={calculatePropertyName(chart)}
              connectNulls
              yAxisId={chart.label}
              unit={chart.uom}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default ProductionChart;
