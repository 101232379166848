export const tableConfig = {
  tags: {
    lease: {
      key: 'value',
      order: 1,
      tag_id: 58,
      agg_func: 'last_value',
    },
    driver: {
      key: 'str_val',
      order: 2,
      tag_id: 31,
      agg_func: 'last_value',
    },
    latitude: {
      key: 'lat',
      order: 8,
      tag_id: 30,
      agg_func: 'last_value',
    },
    longitude: {
      key: 'lon',
      order: 9,
      tag_id: 30,
      agg_func: 'last_value',
    },
    last_pm_date: {
      key: 'str_val',
      order: 7,
      tag_id: 33,
      agg_func: 'last_value',
    },
    next_pm_date: {
      key: 'str_val',
      order: 6,
      tag_id: 32,
      agg_func: 'last_value',
    },
    driver_rated_hp: {
      key: 'value',
      order: 5,
      tag_id: 107,
      agg_func: 'last_value',
    },
    application_type: {
      key: 'str_val',
      order: 3,
      tag_id: 34,
      agg_func: 'last_value',
    },
    compressor_serial: {
      key: 'value',
      order: 4,
      tag_id: 43,
      agg_func: 'last_value',
    },
  },
};

export const mapConfig = {
  tags: {
    lat: {
      key: 'lat',
      tag_id: 30,
      agg_func: 'last_value',
    },
    lon: {
      key: 'lon',
      tag_id: 30,
      agg_func: 'last_value',
    },
  },
};

export const tableColumns = [
  { title: 'Name', dataIndex: 'name', key: 'name' },
  { title: 'Value', dataIndex: 'value', key: 'value' },
];

export const ursaLeoModels = ['FX17', 'FX10', 'FX12', 'FX12v125', 'FX17v150'];