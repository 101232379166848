import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import useFetch from '../hooks/useFetch';
import EditDiv from '../Editing/EditDiv';

const Container = styled.div`
  ${props => `
     padding: 12px;
    `}
`;

const Image = styled.img`
  display: inline-block;
  cursor: zoom-in;
  max-width: ${props => props.maxWidth || '200px'};
  max-height: ${props => props.maxHeight || '200px'};
  margin: 10px;
`;

const WidgetImage = ({id, parentId, config, match, props}) => {
  const { rawURL } = props; 
  const editableLayout = useSelector (state => state.layouts.editing.editable_layout);
  const [loading, data, fetchData] = useFetch(config.endpoint);
  const editMode = editableLayout === parentId;

  useEffect(() => {
    fetchData({ ...match.params, ...config })
  }, []);

  if (loading) { return (<div>LOADING...</div>); }

  const { source } = data.length ? data[0] : props;
  return (
    <Container>
      { editMode && <EditDiv id={id} /> }
      <Image
        maxWidth='100%'
        maxHeight='100%'
        src={rawURL || source}
        onClick={() => { window.open (source, '_blank');}}
      />
    </Container>
  );
};

export default WidgetImage;
