import React, { useState, useContext, useEffect } from 'react';
import { DebounceInput } from 'react-debounce-input';
import Modal from 'react-modal';

import { EnterpriseObjectsRepository } from 'shared/repositories/enterprise-objects-repository/EnterpriseObjectsRepository';

import { GlobalContext } from 'GlobalContext';

const IpEditModal = ({
  asset,
  updatedAsset,
  showEditModal,
  setConfirmation,
  setUpdatedAsset,
  setShowEditModal,
  setShowConfirmation,
}) => {
  const DEFAULT_PORT_VALUE = null;
  const DEFAULT_IP_ADDRESS_VALUE = null;
  const PORT_OPTIONS = ['502', '9600'];

  const { accessToken } = useContext(GlobalContext);
  const [newPort, setNewPort] = useState(DEFAULT_PORT_VALUE);
  const [newIpAddress, setNewIpAddress] = useState(DEFAULT_IP_ADDRESS_VALUE);
  const [currentApiErrorMessage, setApiErrorMessage] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [showIpError, setShowIpError] = useState(false);
  const [showApiError, setShowApiError] = useState(false);
  const [showPortError, setShowPortError] = useState(false);

  const currentAsset = updatedAsset?.ipInfo?.port ? updatedAsset : asset;
  const supportPhone = '405-536-0015';
  const supportEmail = 'support@flogistix.com';
  const submitButtonText = showApiError ? 'Override Error and Save' : 'Save';
  const submitButtonClass = showApiError
    ? 'button primary-button large-button'
    : 'button primary-button';
  const portErrorMessage = 'Please select a port number.';
  const ipAddressErrorMessage = 'Invalid Entry: valid IP addresses are formatted such as: 10.20.30.40';
  const ipHeaderText = `${currentAsset?.ipAddress ?? 'No IP Found'}:${currentAsset?.ipInfo?.port}`;

  const isValidPort = (port) => PORT_OPTIONS.includes(port);
  const isValidIpAddress = (address) => !!/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi.test(address);
  const canSubmit = isValidPort(newPort) && isValidIpAddress(newIpAddress);

  const clearModal = () => {
    setShowApiError(false);
    setShowIpError(false);
    setShowPortError(false);
    setNewPort(DEFAULT_PORT_VALUE);
    setNewIpAddress(DEFAULT_IP_ADDRESS_VALUE);
    unloadAndEnableSubmit();
  };

  const closeModal = () => {
    clearModal();
    setShowEditModal(false);
  };

  const loadAndDisableSubmit = () => {
    document.querySelector('#ip-submit-button').setAttribute('disabled', 'true');
    setShowLoading(true);
  };

  const unloadAndEnableSubmit = () => {
    document.querySelector('#ip-submit-button').removeAttribute('disabled');
    setShowLoading(false);
  };

  const handleApiError = () => {
    setShowApiError(true);
    unloadAndEnableSubmit();
  };

  const connectNewIPAddress = async () => {
    if (!showLoading) {
      loadAndDisableSubmit();
    }

    if (showApiError) {
      setShowApiError(!showApiError);
    }

    const body = {
      data: {
        identifiers: {
          altitude: `orgs/${asset.orgId}/sites/${asset.siteId}/assets/${asset.assetId}`,
        },
        changes: {
          ipAddress: newIpAddress,
          port: newPort,
        },
      },
      source: 'Axil.IpAddressChange',
      eventType: 'edit',
      dataType: 'IpAddress',
      time: Date.now().toString(),
    };

    const updatedEnterpriseObject = await EnterpriseObjectsRepository.connectAssetIpAddress(
      accessToken,
      body,
    );

    setUpdatedAsset(updatedEnterpriseObject);

    if (updatedEnterpriseObject && updatedEnterpriseObject.events) {
      setConfirmation('IP address updated!');
      setShowConfirmation(true);
      closeModal();
    } else {
      setApiErrorMessage(`${updatedEnterpriseObject?.message}.`);
      handleApiError();
    }
  };

  const checkIpAddress = async () => {
    loadAndDisableSubmit();

    if (showApiError) {
      setShowApiError(false);
    }

    const body = {
      ipAddress: newIpAddress,
      port: newPort,
      orgId: asset.orgId,
      siteId: asset.siteId,
      assetId: asset.assetId,
    };

    const updatedEnterpriseObject = await EnterpriseObjectsRepository.checkIpAddress(
      accessToken,
      body,
    );

    if (updatedEnterpriseObject?.valid) {
      connectNewIPAddress();
    } else {
      setApiErrorMessage(`${updatedEnterpriseObject?.message}.`);
      handleApiError();
    }
  };

  const handleIpChange = (input) => {
    setNewIpAddress(input);
    setShowIpError(!isValidIpAddress(input));
  };

  const handlePortChange = (input) => {
    setNewPort(input);
    setShowPortError(!isValidPort(input));
  };

  const submitButtonFunction = showApiError ? () => connectNewIPAddress() : () => checkIpAddress();

  useEffect(() => {
    !newPort && setNewPort(currentAsset?.ipInfo?.port);
    !newIpAddress && setNewIpAddress(currentAsset?.ipAddress);
  }, [currentAsset]);

  const gappedLine = (
    <>
      <div className="gap" />
      <div className="ip-edit-modal-line" />
      <div className="gap" />
    </>
  );

  const header = (
    <section className="ip-edit-modal-header">
      <p className="ip-edit-modal-header-text">Edit IP Address</p>
      <p className="ip-edit-modal-close" onClick={() => closeModal()}>
        X
      </p>
    </section>
  );

  const unitNumberRow = (
    <section className="ip-edit-modal-row">
      <p className="ip-edit-modal-label">Unit Number:</p>
      <p className="ip-edit-modal-value">{asset.assetName}</p>
    </section>
  );

  const ipAddressRow = (
    <section className="ip-edit-modal-row">
      <p className="ip-edit-modal-label">IP Address:</p>
      {!showLoading && (
        <p className="ip-edit-modal-value">{ipHeaderText}</p>
      )}
    </section>
  );

  const ipStatusRow = (
    <section className="ip-edit-modal-row">
      <p className="ip-edit-modal-label">IP Status:</p>
      {!showLoading && (
        <>
          <p className="ip-edit-modal-value">
            {currentAsset?.connectivityInfo?.status === 'connected' && 'Connected'}
            {currentAsset?.connectivityInfo?.status === 'disconnected' && 'Disconnected'}
            {(!currentAsset?.ipAddress || !currentAsset?.connectivityInfo) && 'Unknown'}
          </p>
          {currentAsset?.connectivityInfo?.status === 'connected' && (
            <div className="ip-connected-logo" title="Connected" />
          )}
          {currentAsset?.connectivityInfo?.status === 'disconnected' && (
            <div className="ip-disconnected-logo" title="Disconnected" />
          )}
          {(!currentAsset?.ipAddress || !currentAsset?.connectivityInfo) && (
            <div className="ip-unknown-logo" title="Unknown"></div>
          )}
        </>
      )}
    </section>
  );

  const ipInputRow = (
    <section className="ip-edit-modal-text-input-row">
      <p className="ip-edit-modal-input-label">New IP Address:</p>
      <DebounceInput
        value={newIpAddress || ''}
        type="text"
        autoComplete="off"
        debounceTimeout={1000}
        disabled={showApiError}
        placeHolder={newIpAddress}
        className="ip-edit-modal-text-input"
        onChange={(e) => handleIpChange(e.target.value)}
      />
    </section>
  );

  const portInputRow = (
    <section className="ip-edit-modal-text-input-row">
      <p className="ip-edit-modal-input-label">Port:</p>
      <select
        disabled={showApiError}
        className="ip-edit-modal-select-input"
        onChange={(e) => handlePortChange(e.currentTarget.value)}
        value={newPort || ''}
      >
        <option
          value={0}
          key="option-key-default"
          style={{ color: '#666' }}
          selected={!isValidPort(newPort)}
        >
          Select Port
        </option>
        {PORT_OPTIONS.map((portOption) => (
          <option value={portOption} selected={newPort === portOption} key={`option-key-${portOption}`}>
            {portOption}
          </option>
        ))}
      </select>
    </section>
  );

  const loadingComponent = (
    <div className="ip-loading-animation-container">
      <div className="ip-loading-animation" />
    </div>
  );

  const retryMessage = (
    <span className="link" onClick={() => clearModal()}>
      Start Over?
    </span>
  );

  const buttonRow = (
    <div className="buttons-container">
      <button className="button secondary-button" onClick={() => closeModal()}>
        Cancel
      </button>
      <button
        id="ip-submit-button"
        className={submitButtonClass}
        onClick={submitButtonFunction}
        disabled={!canSubmit}
      >
        {submitButtonText}
      </button>
    </div>
  );

  const contactSupportRow = (
    <div className="ip-error-contact-row">
      <p className="ip-error-contact-row-label">Customer Support:</p>
      <div className="ip-error-contact-info">
        <div className="ip-error-contact-row-email">
          <div className="email-icon" />
          <a className="link" href={`mailto:${supportEmail}`}>
            {supportEmail}
          </a>
        </div>
        <div className="ip-error-contact-row-phone">
          <div className="phone-icon" />
          <p>{supportPhone}</p>
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      overlayClassName="ip-edit-modal-overlay"
      className="ip-edit-modal"
      isOpen={showEditModal}
      onRequestClose={() => closeModal()}
    >
      <div className="ip-edit-modal-container">
        {header}
        {unitNumberRow}
        {ipAddressRow}
        {ipStatusRow}
        {gappedLine}
        {showLoading ? (
          loadingComponent
        ) : (
          <>
            {ipInputRow}
            {showIpError ? (
              <span className="ip-error-message">{ipAddressErrorMessage}</span>
            ) : (
              <span className="ip-error-message-placeholder" />
            )}
            {portInputRow}
            {showPortError ? (
              <span className="ip-error-message">{portErrorMessage}</span>
            ) : (
              <span className="ip-error-message-placeholder" />
            )}
          </>
        )}
        {showApiError ? (
          <div className="api-error-message">
            {currentApiErrorMessage}
            {retryMessage}
          </div>
        ) : (
          <div className="api-error-message-placeholder" />
        )}
        {buttonRow}
        {showApiError ? contactSupportRow : <div className="contact-support-placeholder" />}
      </div>
    </Modal>
  );
};

export default IpEditModal;
