import React from 'react';

import WellButtonGroup from 'single-asset/components/WellButtonGroup';

import './WellButtonGroupList.scss';

const WellButtonGroupList = ({
  wellMapList,
  currentWellId,
  onWellSelected,
  saveWellConfig,
  groupIsDisabled,
}) => (
  <div className="WellButtonGroupList">
    {wellMapList.map(
      (wellMap) => (
        <WellButtonGroup
          key={wellMap.wellId}
          wellId={wellMap.wellId}
          onWellSelected={onWellSelected}
          selected={wellMap.wellId === currentWellId}
          well={wellMap.well}
          saveWellConfig={saveWellConfig}
          isDisabled={groupIsDisabled}
        />
      ),
    )}
  </div>
);
export default WellButtonGroupList;
