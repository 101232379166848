import React from 'react';
import Modal from 'react-modal';

const AlertModal = ({ showAlert, setShowAlert, alertMessage }) => {
  const closeModal = () => {
    setShowAlert(!showAlert);
  };

  const autoDismiss = () => {
    setTimeout(closeModal, 2000);
  };

  showAlert && autoDismiss();

  return (
    <Modal
      overlayClassName="alert-modal-overlay"
      className="alert-modal"
      isOpen={showAlert}
      onRequestClose={() => closeModal()}
    >
      <p className="alert-message">{alertMessage}</p>
    </Modal>
  );
};

export default AlertModal;
