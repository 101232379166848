import _ from 'lodash';

import { convertListToSet } from 'single-asset/helpers/gasLiftHelpers';
import * as wellConfigurationActionTypes from 'single-asset/actions/wellConfigurationActionTypes';

export const defaultState = {
  wellConfigurationLoadPending: false,
  wellConfigurationUpdatePending: false,
  wells: null,
};

const wellConfigurationReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case wellConfigurationActionTypes.WELL_CONFIGURATION__FETCH_WELLS_FAILED:
    case wellConfigurationActionTypes.WELL_CONFIGURATION__FETCH_WELLS_SUCCEEDED:
    case wellConfigurationActionTypes.WELL_CONFIGURATION__FETCH_WELLS: {
      return fetchWellsReducer(state, action);
    }
    case wellConfigurationActionTypes.WELL_CONFIGURATION__SAVE_WELL_CONFIGURATION:
    case wellConfigurationActionTypes.WELL_CONFIGURATION__SAVE_WELL_CONFIGURATION_SUCCEEDED:
    case wellConfigurationActionTypes.WELL_CONFIGURATION__SAVE_WELL_CONFIGURATION_FAILED: {
      return saveWellReducer(state, action);
    }
    default: {
      return state;
    }
  }
};

const fetchWellsReducer = (state, action) => {
  switch (action.type) {
    case wellConfigurationActionTypes.WELL_CONFIGURATION__FETCH_WELLS: {
      return {
        ...defaultState,
        wellConfigurationLoadPending: true,
      };
    }
    case wellConfigurationActionTypes.WELL_CONFIGURATION__FETCH_WELLS_SUCCEEDED: {
      const wells = convertListToSet('wellId', action.config.resources);

      return {
        ...defaultState,
        wells,
      };
    }
    case wellConfigurationActionTypes.WELL_CONFIGURATION__FETCH_WELLS_FAILED: {
      return {
        ...defaultState,
        errorResponse: action.errorResponse,
      };
    }

    default: {
      return state;
    }
  }
};

const saveWellReducer = (state, action) => {
  switch (action.type) {
    case wellConfigurationActionTypes.WELL_CONFIGURATION__SAVE_WELL_CONFIGURATION: {
      return {
        ...state,
        wellConfigurationUpdatePending: true,
      };
    }

    case wellConfigurationActionTypes.WELL_CONFIGURATION__SAVE_WELL_CONFIGURATION_SUCCEEDED: {
      const wells = {
        ...state.wells,
        [action.config.wellId]: _.omit(action.config, 'executionTime'),
      };

      return {
        ...defaultState,
        wells,
      };
    }

    case wellConfigurationActionTypes.WELL_CONFIGURATION__SAVE_WELL_CONFIGURATION_FAILED: {
      return {
        ...defaultState,
        errorResponse: action.errorResponse,
      };
    }

    default: {
      return state;
    }
  }
};

export default wellConfigurationReducer;
