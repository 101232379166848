import * as singleAssetActionTypes from 'single-asset/actions/singleAssetActionTypes';
import assetReducer from 'single-asset/reducers/assetReducer';
import glxProductionReducer, { defaultState as glxProduction } from 'single-asset/reducers/glxProductionReducer';
import telemetryConfigReducer, { defaultState as config } from 'single-asset/reducers/telemetryConfigReducer';
import telemetryStatsReducer, { defaultState as stats } from 'single-asset/reducers/telemetryStatsReducer';
import wellStatsReducer, { defaultState as wellStats } from 'single-asset/reducers/wellStatsReducer';
import wellConfigurationReducer, { defaultState as wellConfiguration } from 'single-asset/reducers/wellConfigurationReducer';

export const defaultState = {
  asset: {},
  gasLift: {
    production: glxProduction,
    wellStats,
    wellConfiguration,
  },
  telemetry: {
    config,
    stats,
  },
};

const singleAssetReducer = (state = defaultState, action = {}) => (
  action.type === singleAssetActionTypes.SINGLE_ASSET__CLEAR_STATE
    ? defaultState
    : {
      asset: assetReducer(state.asset, action),
      gasLift: {
        production: glxProductionReducer(state?.gasLift?.production, action),
        wellStats: wellStatsReducer(state?.gasLift?.wellStats, action),
        wellConfiguration: wellConfigurationReducer(state?.gasLift?.wellConfiguration, action),
      },
      telemetry: {
        config: telemetryConfigReducer(state?.telemetry?.config, action),
        stats: telemetryStatsReducer(state?.telemetry?.stats, action),
      },
    });

export default singleAssetReducer;
