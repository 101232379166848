import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { AltitudeMatchParams } from '../../global.interfaces';

// params and match.params cause infinite re-renders when in dependency arrays
// use this callback to get up-to-date params inside other hooks
export default (): (() => AltitudeMatchParams) => {
  const { device_id, asset_id, site_id, org_id } = useParams<AltitudeMatchParams>();

  return useCallback((): AltitudeMatchParams => ({ device_id, asset_id, site_id, org_id }), [
    asset_id,
    device_id,
    org_id,
    site_id,
  ]);
};
