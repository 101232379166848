import React from 'react';
import moment from 'moment';
import { Card, Statistic } from 'antd';

import { displayedDateFormat } from 'single-asset/constants/glxProductionConstants';

import './TelemetryStat.scss';

const TelemetryStat = ({
  stat: {
    statsResult: [result],
    props: {
      timestamp = false,
      title,
      precision,
      prefix = '',
      suffix = '',
    },
  },
}) => {
  const defaultDisplayedValue = '_';
  const cardValue = result?.value;
  const displayedValue = cardValue !== undefined && timestamp && moment(cardValue).format(displayedDateFormat)
  || cardValue
  || defaultDisplayedValue;

  return (
    <Card
      className="TelemetryStat"
    >
      <Statistic
        title={title}
        suffix={suffix}
        prefix={prefix}
        precision={precision}
        value={displayedValue}
      />

    </Card>
  );
};

export default TelemetryStat;
