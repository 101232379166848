import React from 'react';
import styled from 'styled-components';
import { Card } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

const Container = styled(Card)`
  ${props => `
     border: ${props.theme.widgets.border.border.full};
     height: 100%;
  `} 
`;
const Image = styled.img`
  display: inline-block;
  cursor: zoom-in;
  max-width: ${props => props.maxWidth || '200px'};
  max-height: ${props => props.maxHeight || '200px'};
  margin: 10px;
`;

const WidgetMockup = () => {

  const source = 'https://s3.amazonaws.com/app.axil.ai/Flogistix_test_model.png'

  return (
    <Container title='Site Schematics' extra={<SettingOutlined />}>
      <Image
        maxWidth='100%'
        maxHeight='100%'
        src={source}
      />
    </Container>
  );
};

export default WidgetMockup;
