import { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { getUrl } from 'shared/helpers/repositoryHelpers';
import { GlobalContext } from '../../GlobalContext';

const defaults = {
  baseUrl: getUrl(),
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
};

type FetchReturnFunc = (params: any) => void;
type FetchReturnData = any;
type FetchError = any;

type UseFetch = (
  endpoint: string,
  options?: {
    baseUrl?: string;
    method?: string;
    headers?: any;
  },
) => [boolean, FetchReturnData, FetchReturnFunc, FetchError];

type GenerateDebugEndpoint = () => string;

const useFetch: UseFetch = (endpoint: string, inOptions = defaults) => {
  const options = { ...defaults, ...inOptions };
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [params, setParams] = useState<any>({});
  const location = useLocation();
  const impersonate = queryString.parse(location?.search)?.impersonate;

  const { baseUrl, method, headers } = options;

  const { accessToken }: any = useContext(GlobalContext);

  const generateDebugEndpoint: GenerateDebugEndpoint = () => {
    if (endpoint.includes('by_tag_id')) {
      const generateLabel = Object.keys(params.tags).map((l) =>
        l.toLowerCase().split(' ').join('-'),
      );

      return `${endpoint}?debug=${generateLabel}`;
    }

    if (endpoint.includes('by_date')) {
      const generateLabel = params.series.map((s: { label: string }) =>
        s.label.toLowerCase().split(' ').join('-'),
      );

      return `${endpoint}?label=${generateLabel}`;
    }

    return endpoint;
  };

  useEffect(() => {
    if (trigger && accessToken) {
      (async () => {
        try {
          const request = await fetch(`${baseUrl}${generateDebugEndpoint()}`, {
            method,
            body: method === 'GET' ? null : JSON.stringify(params),
            headers: {
              ...headers,
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const results = await request.json();

          setData(results);
          setLoading(false);
          setTrigger(false);
        } catch (err) {
          setError(err as any);
          setLoading(false);
          setTrigger(false);
        }
      })();
    }
  }, [trigger, accessToken]);

  const lazyFunc: FetchReturnFunc = (params = {}) => {
    setParams({ ...params, impersonate });
    setLoading(true);
    setTrigger(true);
  };

  return [loading, data, lazyFunc, error];
};

export default useFetch;
