import { repositoryFetch } from 'shared/repositories/repositoryFetch';

export const baseGasLiftAssetsEndpoint = '/flux/meta/gas-lift/assets/';

const wellFetch = ({
  accessToken,
  body,
  assetId,
  method = 'GET',
  wellId = null,
}) => repositoryFetch({
  accessToken,
  ...(method !== 'GET' && body && { body }),
  endpoint: `${baseGasLiftAssetsEndpoint}${assetId}/wells/${wellId ?? ''}`,
  isAxil: true,
  method,
});

export const getWells = ({
  wellId = null,
  ...baseProps
}) => wellFetch({
  ...baseProps,
  method: 'GET',
  wellId,
});

export const updateWellById = ({
  wellId,
  ...baseProps
}) => wellFetch({
  ...baseProps,
  method:
  'PATCH',
  wellId,
});

export const addWell = ({
  ...baseProps
}) => wellFetch({ ...baseProps, method: 'POST' });
