import { v4 as uuidv4 } from 'uuid';

///////////////////////////////////////////////
///////// LAYOUT MANIPULATION TOOLS ///////////
///////////////////////////////////////////////

const applyToAllLayoutSizes = (layout, fn) => {
  const split = Object.entries(layout);
  const x = split.map((ls) => {
    const size = ls[0];
    if (Array.isArray(ls[1])) {
      const y = ls[1].flatMap((l) => fn(l));
      return { [size]: y };
    }
    return Object.fromEntries([ls]);
  });

  return x.reduce((acc, val) => {
    return {
      ...acc,
      ...val,
    };
  }, {});
};

const updateCardWidgetTagValue = (layout, oldTagValue, newTagValue) => {
  const updateFunction = (l) => {
    const updateChildren = (child) =>
      child.map((c) => {
        const { children = [] } = c;
        if (c.widget === 'WidgetBasicCard') {
          const tags = c?.options?.config?.tags;
          const tagId = Object.values(tags)[0].tag_id;
          const tagLabel = Object.keys(tags)[0];
          if (parseInt(tagId) === parseInt(oldTagValue)) {
            c.options.config.tags[tagLabel].tag_id = newTagValue;
          }
          return c;
        }
        return updateChildren(children);
      });

    return updateChildren(l.children);
  };

  return applyToAllLayoutSizes(layout, updateFunction);
};

///////////////////////////////////////////////
//////////// MIGRATION FUNCTIONS //////////////
///////////////////////////////////////////////

// Version 0 -> Version 1 Layout Migration
// Adds a version number to Layouts
const migrateV0toV1 = (layout) => {
  // If Layout already has a version, we can skip this step
  if (layout.version) {
    return {
      layout,
      errors: {},
    };
  }
  return {
    layout: { version: 1, ...layout },
    errors: {},
  };
};

// if coordinates on layout are missing, add a 0 value to each key.
// if coordinates exist, they will overwrite default 0 values.
const confirmCoordinatesExist = (layout) => {
  const addCoordinateToLayout = (l) => {
    const { children = [] } = l;
    const fixChildren = children.map((c) => {
      if (c.x === 'Infinity' || c.y === 'Infinity') {
        c.y = 0;
      }
      return { h: 0, w: 0, x: 0, y: 0, ...c };
    });

    return { ...l, children: fixChildren };
  };

  const layoutWithCoordinates = applyToAllLayoutSizes(layout, addCoordinateToLayout);

  return {
    layout: layoutWithCoordinates,
    errors: {},
  };
};

// update Critical Rate Hunt Solution Tag Value
// 779 -> 498
const updateCriticalRateHuntSolution = (layout) => {
  updateCardWidgetTagValue(layout, 779, 498);
  return { layout, errors: {} };
};

// update id to a unique id.
const updateWidgetIdsWithUUIDs = (layout) => {
  if (layout.version <= 2) {
    const updateId = (l) => {
      const { children = [] } = l;
      if (l.widget !== 'WidgetLayout') {
        l.i = `${l.i}-${uuidv4()}`;
      }
      children.map(updateId);
      return l;
    };

    const layoutWithUpdatedIds = applyToAllLayoutSizes(layout, updateId);
    return {
      layout: { ...layoutWithUpdatedIds, version: 2 },
      errors: {},
    };
  }

  return { layout, errors: {} };
};

// To add a migration function to the pipeline, just append it here.
const migrationFunctions = [
  migrateV0toV1,
  confirmCoordinatesExist,
  updateCriticalRateHuntSolution,
  updateWidgetIdsWithUUIDs,
];

const migrations = (lay, params) => {
  const applyMigrations = migrationFunctions.reduce((acc, fn) => {
    const { layout, errors } = fn(acc);
    if (Object.values(errors).length > 0) {
      // Make API call to submit errors here
      // If there is an error, return the original layout { lay }

      fetch('https://api.flaxil.com/v1/axil/layouts/save/errors', {
        method: 'POST',
        body: JSON.stringify({ errors: { ...errors }, ...params }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          'x-api-key': 'XgiZVX0nuOjoBe419uen8CSgi5otNPx7sMIQhJI3',
          'Content-Type': 'application/json',
        },
      });

      return lay;
    }
    return layout;
  }, lay);

  return applyMigrations;
};

export default migrations;
