import React, { useState, useEffect } from 'react';

import {
  LOADING_PRODUCTION_CHARTS_MESSAGE,
  MILLISECONDS_TO_DELAY_CHART_UPDATE,
  LOADING_WELL_STATS_MESSAGE,
  RENDERING_PRODUCTION_CHARTS_MESSAGE,
  LOADING_WELL_CONFIGURATIONS_MESSAGE,
  defaultInitialWellId,
} from 'single-asset/constants/gasLiftConstants';
import ProductionForm from 'single-asset/components/ProductionForm';
import ProductionChartList from 'single-asset/components/ProductionChartList';
import GasLiftLoadingIndicator from 'single-asset/components/GasLiftLoadingIndicator';
import TelemetryStatsList from 'single-asset/components/TelemetryStatsList';
import WellButtonGroupList from 'single-asset/components/WellButtonGroupList';

import './Production.scss';

const Production = ({
  header,
  subHeader,
  production: { seriesList, dates, isPending: productionIsPending },
  wellConfiguration: {
    wellConfigurationLoadPending,
    wellConfigurationUpdatePending,
  },
  wellStats: { wellStatList },
  defaultDates,
  searchDailyProduction,
  saveWellConfig,
  wellMapList,
}) => {
  const [currentWellName, setCurrentWellName] = useState();
  const [currentWellId, setCurrentWellId] = useState(defaultInitialWellId);
  const [currentWellStats, setCurrentWellStats] = useState([]);
  const [currentChartData, setCurrentChartData] = useState([]);

  const updateCurrentWellId = (newWellId) => newWellId !== currentWellId && setCurrentWellId(newWellId);
  const filterDataByWellId = (data) => data.filter(({ wellId }) => wellId === currentWellId);
  const delayedSetCurrentChartData = (milliseconds, newChartData) => setTimeout(setCurrentChartData, milliseconds, newChartData);
  const getWellProductionByDate = (dateRange) => searchDailyProduction(dateRange);

  useEffect(() => {
    const chartDataFilteredByWell = filterDataByWellId(seriesList);

    delayedSetCurrentChartData(MILLISECONDS_TO_DELAY_CHART_UPDATE, chartDataFilteredByWell);
  }, [seriesList, currentWellId]);

  useEffect(() => {
    const statDataFilteredByWell = filterDataByWellId(wellStatList);

    setCurrentWellStats(statDataFilteredByWell);
  }, [wellStatList, currentWellId]);

  useEffect(() => {
    setCurrentWellName(
      filterDataByWellId(wellMapList).at(0)?.well?.title
      ?? `Welld-${currentWellId}`,
    );
  }, [wellMapList, currentWellId]);

  const loadingMessage = productionIsPending && LOADING_PRODUCTION_CHARTS_MESSAGE
    || seriesList.length && !currentChartData.length && RENDERING_PRODUCTION_CHARTS_MESSAGE
    || !wellStatList.length && LOADING_WELL_STATS_MESSAGE
    || wellConfigurationLoadPending && LOADING_WELL_CONFIGURATIONS_MESSAGE;

  const renderProductionChartsSection = () => (
    <>
      <ProductionForm
        getWellProductionByDate={getWellProductionByDate}
        defaultDates={defaultDates}
        dates={dates}
      />
      {currentChartData.length
        ? <ProductionChartList chartData={currentChartData} wellName={currentWellName} />
        : <h1>No data for request</h1>}
    </>
  );

  return (
    <div className="Production">
      <div>
        <span className="header">
          {header}
        </span>
        <span className="sub-header">
          {subHeader}
        </span>
      </div>
      {!wellConfigurationLoadPending && (
      <WellButtonGroupList
        groupIsDisabled={wellConfigurationLoadPending || wellConfigurationUpdatePending}
        wellMapList={wellMapList}
        currentWellId={currentWellId}
        onWellSelected={updateCurrentWellId}
        saveWellConfig={saveWellConfig}
      />
      )}
      <TelemetryStatsList statsList={currentWellStats} />
      {loadingMessage
        ? <GasLiftLoadingIndicator loadingMessage={loadingMessage} />
        : renderProductionChartsSection()}
    </div>
  );
};

export default Production;
