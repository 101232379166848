import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { ReactNode, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../constants';
import { SET_CAN_GO_BACK_FALSE, SET_CAN_GO_BACK_TRUE } from '../../store/dashboardCanGoBack';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { StyledButton } from '../styled';

function BackButton(): ReactNode {
  const dashboardCanGoBack = useAppSelector((state) => state.dashboardCanGoBack);
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname === Routes.DASHBOARD) {
      dispatch({ type: SET_CAN_GO_BACK_FALSE });
    }
  }, [dispatch, history.location.pathname]);

  useEffect(() => {
    const historyUnlisten = history.listen((_, action) => {
      if (action === 'PUSH') dispatch({ type: SET_CAN_GO_BACK_TRUE });
    });

    return () => {
      historyUnlisten();
    };
  }, [dispatch, history]);

  function handleClick() {
    if (dashboardCanGoBack) {
      history.goBack();
    } else history.push(Routes.DASHBOARD);
  }

  return history.location.pathname === Routes.DASHBOARD ? null : (
    <StyledButton
      htmlType="button"
      icon={<ArrowLeftOutlined />}
      onClick={handleClick}
      size="large"
      style={{ margin: 'auto 5px' }}
    />
  );
}

export default BackButton;
