import React, { useState, useEffect, useRef, ReactElement } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import { Spin } from 'antd';
import queryString from 'query-string';
import {
  ReferenceArea,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  TooltipFormatter,
} from 'recharts';
import Legend from './Legend';
import EditDiv from '../../Editing/EditDiv';
import useFetch from '../../hooks/useFetch';
import { ChartContainer, Container, Footer } from './styles';
import {
  EventReference,
  LabeledSeriesDatum,
  RechartsEvent,
  SeriesData,
  UnlabeledSeriesDatum,
  WidgetSeriesProps,
} from './types';
import { AltitudeMatchParams, AssetEvent } from '../../../global.interfaces';
import { useAppSelector } from '../../../store/hooks';
import { AssetEventMessageTypes } from './constants';

import './styles.scss';

const initialZoomState = {
  startTime: null,
  endTime: null,
};

// TODO: Could use some additional cleanup
// especially around useEffects
const WidgetSeries = ({
  containerHeight,
  id,
  parentId,
  roundedTheme,
  props: { chartTitle = '', chartSubTitle = '' },
  config,
}: WidgetSeriesProps): ReactElement => {
  const history = useHistory();
  const { org_id, site_id, asset_id, device_id } = useParams<AltitudeMatchParams>();
  const [loading, data, fetchData] = useFetch('/v3/axil/series/hot/by_date');
  const [xAxisData, setXAxisData] = useState<SeriesData>([]);
  const [yAxisData, setYAxisData] = useState<Array<keyof UnlabeledSeriesDatum>>([]);
  const [eventsToggle, toggleEvents] = useState(true);
  const [yToggle, toggleYAxis] = useState(true);
  const [chartHeight, setChartHeight] = useState(0);
  const legendRef = useRef<HTMLDivElement>(null);
  const [zoomState, setZoomState] = useState<{
    startTime: string | null;
    endTime: string | null;
  }>(initialZoomState);
  const [customChartName, setCustomChartName] = useState('');

  const assetEvents = useAppSelector((state) => state.events);
  const editableLayout = useAppSelector((state) => state.layouts.editing.editable_layout);
  const themePalette = useAppSelector((state) => state.resources.theme.palette);
  const editMode = editableLayout === parentId;

  const { startTime, endTime } = zoomState;
  const { search } = useLocation();
  const { start = null, end = null } = queryString.parse(search);

  const dateFormat = 'YYYY-MM-DD HH:mm:ss';
  const messageTypes = Object.values(AssetEventMessageTypes);

  useEffect(() => {
    fetchData({
      ...config,
      series: config.series.map((s) => {
        return {
          ...s,
          org_id: s.org_id || org_id,
          site_id: s.site_id || site_id,
          asset_id: s.asset_id || asset_id,
          device_id: s.device_id || device_id,
        };
      }),

      start: start || moment.utc().subtract(24, 'hours').format(dateFormat),
      stop: end || moment.utc().format(dateFormat),
    });

    return () => setZoomState(initialZoomState);
  }, [start, end, org_id, site_id, asset_id]);

  const getPrecedingEvents = (
    presentEvents: Array<AssetEvent>,
    xDatum: LabeledSeriesDatum,
    y: number,
  ) => {
    const precedingEvents = [];

    while (
      presentEvents.length > 0 &&
      presentEvents[presentEvents.length - 1].epoch_milliseconds <= xDatum.x
    ) {
      const aEvent = presentEvents.pop() as AssetEvent;

      precedingEvents.push({
        msg: aEvent.msg,
        label: moment(aEvent.epoch_milliseconds).format(dateFormat),
        x: aEvent.epoch_milliseconds,
        [aEvent.msgtype as AssetEventMessageTypes]: y,
      });
    }

    return precedingEvents;
  };

  const mergeEventAndXData = (
    xData: SeriesData,
    dataKeys: Array<keyof UnlabeledSeriesDatum>,
  ): SeriesData & Array<EventReference> => {
    const combinedXData = [] as SeriesData & Array<EventReference>;
    let y;

    const firstXDatum = xData.shift();

    if (firstXDatum) {
      const presentEvents = assetEvents.filter(
        ({ epoch_milliseconds }) =>
          epoch_milliseconds >= firstXDatum.x && epoch_milliseconds <= xData[xData.length - 1].x,
      );

      combinedXData.push(firstXDatum);

      while (xData.length > 0) {
        const xDatum = xData.shift() as LabeledSeriesDatum;
        y = xDatum[dataKeys[dataKeys.length - 1]] || 0;

        combinedXData.push(...getPrecedingEvents(presentEvents, xDatum, y));
        combinedXData.push(xDatum);
      }
    }

    return combinedXData;
  };

  useEffect(() => {
    if (loading) {
      setXAxisData([]);
      setYAxisData([]);
    } else if (!loading && data) {
      const dataWithTimestamp = data.data.map((d: UnlabeledSeriesDatum) => ({
        ...d,
        label: moment(d.x).format(dateFormat),
      }));

      const dataKeys = Object.keys(data.agg) as Array<keyof UnlabeledSeriesDatum>;

      setXAxisData(mergeEventAndXData(dataWithTimestamp, dataKeys));
      setYAxisData(dataKeys);
    }
  }, [loading, data]);

  useEffect(() => {
    if (legendRef.current) setChartHeight(legendRef.current.offsetHeight);
  }, [legendRef.current]);

  useEffect(() => {
    if (config?.series?.[0]?.asset_name) setCustomChartName(config?.series?.[0]?.asset_name);
    else setCustomChartName('');

    return () => setCustomChartName('');
  }, [config?.series?.[0]?.asset_name]);

  const zoomMouseDown = (event: RechartsEvent) => {
    if (event) setZoomState({ ...zoomState, startTime: event.activeLabel });
  };

  const zoomMouseUp = (event: RechartsEvent) => {
    const convertToUTC = (date: string) => moment(date).utc().format(dateFormat);

    if (event && startTime && endTime) {
      const isStartAfterEnd = startTime > endTime;
      const startUTC = convertToUTC(isStartAfterEnd ? endTime : startTime);
      const endUTC = convertToUTC(isStartAfterEnd ? startTime : endTime);

      history.push({ search: `?start=${startUTC}&end=${endUTC}` });
    }
  };

  const handleChartMouseMove = (event: RechartsEvent) => {
    if (startTime && !event.isTooltipActive) {
      setZoomState(initialZoomState);
    }
    if (event && startTime) {
      setZoomState({ ...zoomState, endTime: event.activeLabel });
    }
  };

  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spin tip="LOADING..." size="large" />
      </div>
    );
  }

  const ifYAxisId = (yAxisId: string | number | undefined) => (yToggle ? undefined : yAxisId);

  const getEventLine = (msgtype: AssetEventMessageTypes) => {
    // antd blue to match timeline
    let eventColor = '#1890ff';
    switch (msgtype) {
      case AssetEventMessageTypes.SUCCESS:
        eventColor = themePalette.success.full;
        break;
      case AssetEventMessageTypes.DANGER:
        eventColor = themePalette.danger.full;
        break;
      case AssetEventMessageTypes.WARNING:
        eventColor = 'orange';
        break;
      default:
        break;
    }

    return (
      <Line
        dataKey={msgtype}
        dot={{
          r: 4,
          stroke: eventColor,
          strokeWidth: 2,
        }}
        name="Event"
        stroke={eventColor}
        strokeWidth={0}
        yAxisId={ifYAxisId(yAxisData[yAxisData.length - 1])}
      />
    );
  };

  const tooltipFormatter: TooltipFormatter = (value, _, { dataKey, payload }) =>
    messageTypes.includes(dataKey as AssetEventMessageTypes) ? payload.msg : value;

  const hasEvents = xAxisData.some((xDatum) => messageTypes.some((msgType) => !!xDatum[msgType]));

  // info on this process https://recharts.org/en-US/examples/CustomContentOfTooltip
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (label && active && payload && payload.length) {
      const payloadItems = payload.map((p: any, i: number) => {
        return (
          <>
            {payload[i] && payload[i].dataKey && (
              <p
                style={{ color: payload[i].color }}
                className={`value${i}`}
              >{`${payload[i].dataKey}: ${payload[i].value}`}</p>
            )}
          </>
        );
      });

      return (
        <div className="custom-tooltip-container">
          <div className="custom-tooltip">
            <p className="label">{`Timestamp: ${label}`}</p>
            {payloadItems}
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <Container containerHeight={containerHeight} rounded={roundedTheme}>
      {editMode && <EditDiv id={id} />}
      <div style={{ margin: '18px 0 0 18px' }} ref={legendRef}>
        {customChartName && <Footer>{`* Custom chart for ${customChartName}`}</Footer>}
        <h2 style={{ marginBottom: 0 }}> {chartTitle} </h2>
        <h3 style={{ marginBottom: 0 }}> {chartSubTitle} </h3>
        {data && (
          <Legend
            config={config}
            data={data}
            toggleEvents={hasEvents ? toggleEvents : null}
            toggleYAxis={toggleYAxis}
          />
        )}
      </div>
      <ChartContainer legendHeight={chartHeight}>
        <ResponsiveContainer width="99%">
          <LineChart
            data={xAxisData || []}
            margin={{ top: 25, right: 30, left: 0, bottom: 25 }}
            onMouseDown={zoomMouseDown}
            onMouseUp={zoomMouseUp}
            onMouseMove={(e) => handleChartMouseMove(e)}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis minTickGap={30} dataKey="label" />
            <Tooltip formatter={tooltipFormatter} />
            {yAxisData.map((label, index) => (
              <YAxis
                orientation={index > 0 ? 'right' : 'left'}
                stroke={config?.series[index]?.seriesProps?.lineColor}
                yAxisId={ifYAxisId(label)}
              />
            ))}
            {yAxisData.map((label, index) => (
              <Line
                connectNulls
                dataKey={label}
                dot={false}
                strokeWidth={2}
                stroke={config?.series[index]?.seriesProps?.lineColor}
                type="monotone"
                yAxisId={ifYAxisId(label)}
              />
            ))}
            {eventsToggle && messageTypes.map((msgtype) => getEventLine(msgtype))}
            {startTime && endTime && (
              <ReferenceArea
                yAxisId={ifYAxisId(yAxisData[0])}
                x1={startTime}
                x2={endTime}
                strokeOpacity={0.3}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </ChartContainer>
    </Container>
  );
};

export default WidgetSeries;
