import React from "react";
import "antd/dist/antd.css";
import { Table, Card, DatePicker } from "antd";
import { SettingOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const columns = [
    {
      title: "Mass Balance",
      dataIndex: "name",
      align: "center"
    },
    {
      title: "Flow Rate (mcfd)",
      key: "data",
      dataIndex: "text",
      align: "center",
      render(text) {
        return {
          props: {
            style: { 
              background: text === "TRUE" ? "green" : (text === 'FALSE' ? "red" : 'white'),
              color: text === "TRUE" ? "white" : (text === "FALSE" ? "white" :"black")
            }
          },
          children: <div>{text}</div>
        };
      }
    }
  ];
  
  const successData = [
    {
      key: "1",
      name: "Simulated Total Flush",
      text: "200"
    },
    {
      key: "2",
      name: "Difference Between Simulation vs. Total Meter",
      text: "-300"
    },
    {
      key: "3",
      name: "Simulation Error %",
      text: "2"
    },
    {
      key: "4",
      name: "Error Adj. Simulated Vol. Range",
      text: "196 ~ 204"
    },
    {
      key: "5",
      name: "Potential Vent Volume",
      text: "0 ~ 7"
    },
    {
      key: "6",
      name: "Total Meter Within Range",
      text: "TRUE"
    }
  ];

  const failedData = [
    {
      key: "1",
      name: "Simulated Total Flush",
      text: "200"
    },
    {
      key: "2",
      name: "Difference Between Simulation vs. Total Meter",
      text: "-300"
    },
    {
      key: "3",
      name: "Simulation Error %",
      text: "2"
    },
    {
      key: "4",
      name: "Error Adj. Simulated Vol. Range",
      text: "196 ~ 204"
    },
    {
      key: "5",
      name: "Potential Vent Volume",
      text: "0 ~ 7"
    },
    {
      key: "6",
      name: "Total Meter Within Range",
      text: "FALSE"
    }
  ];


export default function CalculatedStatsPassed({ props }) {
  const { status } = props;

  return (
      <Card 
        title='Calculated Statistics' 
        extra={[
        <RangePicker style={{margin: 10}} />,
        <SettingOutlined />
      ]}
      >
        <Table
          columns={columns}
          dataSource={status ? successData : failedData}
          bordered
          pagination={{hideOnSinglePage: true}} 
        />
      </Card>
  );
};
