import { wellIdList } from 'single-asset/constants/gasLiftConstants';

const well1Fault = {
  config: {
    agg: true,
    endpoint: '/v1/axil/config/tag_values/by_tag_id',
    tags: {
      value: {
        agg_func: 'last_value',
        key: 'str_val',
        tag_id: '3',
      },
    },
  },
  props: {
    precision: false,
    prefix: '',
    suffix: '',
    title: 'Fault',
    timestamp: false,
    wellId: wellIdList.at(0),
  },
};

const well1Alarm = {
  config: {
    agg: true,
    endpoint: '/v1/axil/config/tag_values/by_tag_id',
    tags: {
      value: {
        agg_func: 'last_value',
        key: 'str_val',
        tag_id: '4',
      },
    },
  },
  props: {
    precision: false,
    prefix: '',
    suffix: '',
    title: 'Alarm',
    timestamp: false,
    wellId: wellIdList.at(0),
  },
};

export default [
  well1Fault,
  well1Alarm,
];
