/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox, Tooltip, Button, Drawer, Popover } from 'antd';
import { InfoCircleOutlined, RollbackOutlined } from '@ant-design/icons';
import { GlobalContext } from '../../GlobalContext'

const ThemedDrawer = styled(Drawer)`
  ${ props => `
     & .ant-drawer-header {
       background: ${props.theme.widgets.background.full};
       border-bottom: ${props.theme.widgets.border.border.full};
     }
     & .ant-drawer-title {
       color: ${props.theme.widgets.color.full};
     }
     & .ant-drawer-body {
       background: ${props.theme.widgets.background.full};
       padding: 0;
     }
  `};
`;

const List = styled.ul`
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100%;
  flex-wrap: wrap;
`;

const SubItem = styled.li`
  ${props => `
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props.theme.widgets.background.full};
    color: ${props.theme.widgets.color.full};
    height: 50px;
    cursor: pointer;
    margin: 6px 12px;

    &:hover {
      background: ${props.theme.widgets.background.dark};
      border-radius: 24px;
    }
  `}
`;

const ResetDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const findLayoutNames = arr => {
  return arr.flatMap(item => {
    if (item.widget === 'WidgetLayout') {
      const result = findLayoutNames(item.children);
      return [item, ...result];
    }
    return [];
  })
};

const SideDrawer = ({ toggleEditMenu, open, location: { pathname }}) => {
  const [menuItems, setMenuItems]           = useState([]);
  const [selectedOption, setOption]         = useState(null);
  const [resetPopover, togglePopover]       = useState(false);
  const [resetTemplate, toggleTemplate]     = useState(false);
  const [selectedLayout, setSelectedLayout] = useState('');
  const [options]                           = useState([
    { i: 'Edit Layout' },
    { i: 'Add Widget' },
  ]);

  const addLayout       = useSelector(state => state.layouts.adding.add_layout);
  const widgetList      = useSelector(state => state.frame.widget_list);
  const currentLayout   = useSelector(state => state.frame.current_layout);
  const editableLayout  = useSelector(state => state.layouts.editing.editable_layout);
  const roundedTheme    = useSelector(state => state.resources.themeStyles.round);

  const dispatch = useDispatch();
  const setAddLayout            = layoutId => dispatch({ type: 'SET_ADD_LAYOUT', payload: layoutId });
  const setWidgetToAdd          = widget => dispatch({ type: 'SET_WIDGET_TO_ADD', payload: widget });
  const resetTemplates          = bool => dispatch({ type: 'RESET_TEMPLATES', payload: bool });
  const setEditableLayout       = layoutId => dispatch({ type: 'SET_EDITABLE_LAYOUT', payload: layoutId });
  const setResetLayoutToDefault = bool => dispatch({ type: 'RESET_LAYOUT_TO_DEFAULT', payload: bool });

  const { setHoverValue } = useContext(GlobalContext);

  const confirmReset = () => {
    resetTemplates(resetTemplate);
    setResetLayoutToDefault(true);
    togglePopover(false);
  }

  useEffect(() => { setMenuItems(findLayoutNames(currentLayout)); }, [currentLayout]);
  useEffect(() => { if (!editableLayout.length > 0) { setOption(null) }}, [editableLayout]);
  useEffect(() => { if (!addLayout.length > 0) { setOption(null); setSelectedLayout(false); toggleEditMenu(false); }}, [addLayout]);

  // If pathname changes, set all editing to false
  useEffect(() => { setAddLayout(''); setEditableLayout(''); }, [pathname])

  // Takes selected option and sets state accordingly
  // Sets the action type layout and closes the sidedrawers
  useEffect(() => {
    if (selectedOption === 'Edit Layout') {
      setEditableLayout(selectedLayout);
      setSelectedLayout(false);
      toggleEditMenu(false);
    };
  }, [selectedOption]);

 

  return (
    <ThemedDrawer title="Customize Layout" visible={open} onClose={() => toggleEditMenu(false)} closable={false}>
      <List>
        { menuItems.map(l => (
          <SubItem 
          key={l.i}         
          onMouseEnter={() => setHoverValue(l.i)}
          onMouseLeave={() => setHoverValue(null)}
          onClick={() => setSelectedLayout(l.i)}> 
          { l.i } 
          </SubItem>
        ))}
      </List>
      <ThemedDrawer
        title={`${selectedLayout} Options`}
        closable={false}
        visible={selectedLayout ? true : false }
        onClose={() => setSelectedLayout(false)}
      >
        { selectedOption === 'Add Widget' ? (
          <>
            <Button block type='link' onClick={() => setOption(false)}>
              <RollbackOutlined /> Back to Actions
            </Button>
            <List>
              { widgetList.map(widget => (
                <SubItem key={widget.name} onClick={() => { setAddLayout(selectedLayout); setWidgetToAdd(widget) }}>
                  { widget.name }
                </SubItem>
              ))}
            </List>
          </>
        ) : (
          <List>
            { options.map(o => <SubItem key={o.i} onClick={() => setOption(o.i)}> {o.i} </SubItem>)} 
          </List>
        )}
      </ThemedDrawer>
      <Popover
        title='Reset layout?'
        trigger="click"
        visible={resetPopover}
        content={(
          <>
            <Checkbox onClick={e => toggleTemplate(e.target.checked)}> Clear Template </Checkbox>
            <Tooltip title='Checking this will reset delete the Template for this asset type.' placement='bottomRight'>
              <InfoCircleOutlined />
            </Tooltip>
            <div>
              <Button type='link' onClick={() => togglePopover(false)}> Cancel </Button>
              <Button type='primary' danger ghost onClick={confirmReset}> Reset </Button>
            </div>
          </>
        )}
      >
        <ResetDiv>
          <Button shape={roundedTheme && 'round'} onClick={() => togglePopover(true)} type='primary' size='large' danger ghost>
            Reset To Default Layout
          </Button>
        </ResetDiv>
      </Popover>
    </ThemedDrawer>
  );
};

SideDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
};

export default SideDrawer;
