import { transformAssetProps } from 'single-asset/transformers/assetTransformers';

export const transformTelemetryLayout = ({
  assetTypeId: asset_type_id,
  ...assetProps
},
dashboardId) => ({
  ...transformAssetProps(assetProps),
  dashboard_id: dashboardId,
  asset_type_id,
});

export const transformStats = ({
  config,
  filters = [],
  asset,
}) => ({
  ...config,
  ...transformAssetProps(asset),
  filters,
});
