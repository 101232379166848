
const lastPMDate = {
  config: {
    agg: true,
    endpoint: '/v1/axil/config/tag_values/by_tag_id',
    tags: {
      value: {
        agg_func: 'last_value',
        key: 'str_val',
        tag_id: '33',
      },
    },
  },
  props: {
    precision: false,
    prefix: '',
    suffix: '',
    title: 'Last PM Date',
    timestamp: false,
  },
};

const nextPMDate = {
  config: {
    agg: true,
    endpoint: '/v1/axil/config/tag_values/by_tag_id',
    tags: {
      value: {
        agg_func: 'last_value',
        key: 'str_val',
        tag_id: '32',
      },
    },
  },
  props: {
    precision: false,
    prefix: '',
    suffix: '',
    title: 'Next PM Date',
    timestamp: false,
  },
};

export default [lastPMDate, nextPMDate];
