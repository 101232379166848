import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Template from 'components/Template';
import GasLiftPage from 'single-asset/pages/GasLiftPage';
import MoreInformation from 'single-asset/MoreInformation/MoreInformation';
import DigitalTwin from 'single-asset/DigitalTwin';
import GLXConfigurationPage from 'single-asset/pages/GLXConfigurationPage';
import { singleAssetRoutePaths, singleAssetBaseRoutePath } from 'single-asset/constants/singleAssetConstants';
import { isGasLiftAltitude } from 'shared/helpers/altitudeHelpers';

const SingleAssetRoutes = ({ asset }) => (
  <Switch>
    {(isGasLiftAltitude(asset)
      && (
      <Route
        exact
        path={singleAssetRoutePaths.GAS_LIFT}
        component={GasLiftPage}
      />
      ))}
    <Route
      exact
      path={singleAssetBaseRoutePath}
      component={Template}
    />
    <Route exact path={singleAssetRoutePaths.EVENTS} component={Template} />
    <Route exact path={singleAssetRoutePaths.WORK_ORDERS} component={Template} />
    <Route
      exact
      path={singleAssetRoutePaths.DIGITAL_TWIN}
      render={() => <DigitalTwin assetName={asset.assetName} />}
    />
    <Route exact path={singleAssetRoutePaths.MORE_INFO} render={() => <MoreInformation />} />
    <Route exact path={singleAssetRoutePaths.INFORMATION} component={Template} />
    <Route
      exact
      path={singleAssetRoutePaths.CONFIGURATION}
      render={() => <GLXConfigurationPage />}
    />
  </Switch>
);

export default SingleAssetRoutes;
