import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Breakpoint from 'react-socks';
import { ReactComponent as Logo } from 'assets/images/FluxLogo.svg';
import { Drawer } from 'antd';
import useReportAPI from '../Report/useReportAPI';

const ThemedDrawer = styled(Drawer)`
  ${(props) => `
     & .ant-drawer-header {
       background: ${props.theme.widgets.background.full};
       border-bottom: ${props.theme.widgets.border.border.full};
     }
     & .ant-drawer-title {
       color: ${props.theme.widgets.color.full};
     }
     & .ant-drawer-body {
       background: ${props.theme.widgets.background.full};
       padding: 0;
     }
  `};
`;

const Container = styled.div`
  background-color: ${(props) => props.theme.palette.neutral['neutral-800']};
  height: 64px;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
  @media (min-width: 768px) {
    background-color: ${(props) => props.theme.palette.neutral['neutral-800']};
    bottom: 0;
    height: 95%;
    left: 0;
    position: fixed;
    right: initial;
    top: 50px;
  }
`;

const MenuButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${(props) => props.theme.palette.neutral['neutral-200']};
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: left;
  width: 110px;
  display: flex;
  position: absolute;
  top: 18px;
  &:focus {
    outline: none;
  }
`;

const DesktopMenu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
  width: 66px;
  text-align: center;
  margin-top: 6px;
  @media (min-width: 768px) {
    display: block;
  }
`;

const Icon = styled.i`
  margin: 14px 12px;
  @media (min-width: 768px) {
    margin: 9px 0;
  }
`;

const MenuIcon = styled.i`
  display: block;
  color: ${(props) => props.theme.palette.primary.full};
  margin-left: 18px;
  @media (min-width: 768px) {
    display: none;
  }
`;
const MenuTitle = styled.p`
  display: block;
  margin: 0 8px;
  &&& {
    color: ${(props) => props.theme.palette.primary.full};
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

const Title = styled.p`
  display: block;
  padding: 10px;
  color: ${(props) => props.theme.widgets.color.full};
  &&& {
    font-weight: 500;
    font-size: 17px;
    line-height: 18px;
    letter-spacing: -0.002em;
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

//This is also used in the Framework/Header but may be slightly modified

const FluxLogo = styled(Logo)`
  && {
    height: 70%;
    width: 85px;
  }
`;

const AxilLogo = styled.a`
  display: block;
  float: right;
  margin-top: 18px;
  margin-right: 18px;
  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    float: none;
    margin-right: 0;
    margin-bottom: 18px;
  }
`;

const Profile = styled.img`
  display: flex;
  justify-content: center;
  float: none;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  margin: 14px 12px;
  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    float: none;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    margin: 0;
  }
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 16px;
  height: 50px;
  color: ${(props) => props.theme.widgets.color.full};
  padding-bottom: 1px;
  background: /* gradient can be an image */ linear-gradient(
      to left,
      ${(props) => props.theme.widgets.background.light} 35%,
      ${(props) => props.theme.widgets.background.dark} 80%
    )
    left bottom no-repeat;
  background-size: 100% 0.7px;
  &:hover {
    color: ${(props) => props.theme.palette.primary.full};
  }
  &&& {
    p {
      font-size: 14px;
      font-weight: 500;
    }
    &.active {
      border-right: 6px solid ${(props) => props.theme.palette.primary.full};
    }
  }
  @media screen and (min-width: 768px) {
    justify-content: center;
    background: none;
    padding: 0px;
    &&& {
      &.active {
        border-right: none;
        color: ${(props) => props.theme.palette.primary.full};
      }
    }
  }
`;
const SettingsNavLink = styled(NavLink)`
  display: flex;
  justify-content: flex-start;
  @media (min-width: 768px) {
    position: absolute;
    bottom: 30px;
    left: 10px;
    padding-left: 10px;
  }
  &&& {
    p {
      font-size: 14px;
      font-weight: 500;
    }
    &.active {
      border-right: 6px solid ${(props) => props.theme.palette.primary.full};
    }
  }
`;

const NavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export default function Navbar({ routes: propRoutes, user }) {
  const [visible, setVisible] = useState(false);
  const { canAccessReports } = useReportAPI();

  // TODO: A little hacky - need to refactor routing so that it can pull from GlobalContext
  const routes = canAccessReports
    ? propRoutes
    : propRoutes.filter((route) => route.path !== '/report');

  const handleIconClick = () => {
    setVisible(false);
  };

  const createLink = (route, index) => {
    const { external = false } = route;
    if (external) {
      return (
        <div
          role="button"
          onClick={() => {
            window.location.replace(route.path);
          }}
        >
          <Icon className="material-icons">{route.icon}</Icon>
        </div>
      );
    }
    return (
      <li key={index}>
        {route.mainNav && (
          <StyledNavLink
            to={route.link}
            exact={route.navExact}
            onClick={() => handleIconClick(route)}
          >
            <Icon className="material-icons">{route.icon}</Icon>
            <Title>{route.title}</Title>
          </StyledNavLink>
        )}
      </li>
    );
  };

  return (
    <>
      <Breakpoint small down>
        <Container>
          <AxilLogo href="/">
            <FluxLogo />
          </AxilLogo>
          <MenuButton
            type="button"
            onClick={() => setVisible(true)}
            aria-label="Menu"
            className="Menu"
          >
            <MenuIcon className="material-icons">{'menu'}</MenuIcon>
            <MenuTitle>{'MENU'}</MenuTitle>
          </MenuButton>
        </Container>
        <ThemedDrawer
          visible={visible}
          placement="left"
          onClose={() => setVisible(false)}
          closable={false}
        >
          <NavList>{routes.filter((route) => !route.desktopOnly).map(createLink)}</NavList>
          <SettingsNavLink to="/settings" exact onClick={() => setVisible(false)}>
            {user && <Profile src={user.picture} alt="Profile" />}
            <Title>Settings</Title>
          </SettingsNavLink>
        </ThemedDrawer>
      </Breakpoint>
      <Breakpoint medium up>
        <Container>
          <DesktopMenu>{routes.filter((route) => route.mainNav).map(createLink)}</DesktopMenu>
          <SettingsNavLink to="/settings" exact>
            {user && <Profile src={user.picture} alt="Profile" />}
          </SettingsNavLink>
        </Container>
      </Breakpoint>
    </>
  );
}

Navbar.defaultProps = {
  user: {},
};

Navbar.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.string,
      exact: PropTypes.bool,
      navExact: PropTypes.bool,
      mainNav: PropTypes.bool,
      component: PropTypes.ReactNode,
    }),
  ).isRequired,
  user: PropTypes.object,
};
