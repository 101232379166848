import React from 'react';
import {
  Form, Input,
} from 'antd';

import { FORM_MODE_READ_ONLY } from 'single-asset/constants/gasLiftConstants';

const WellInformationEditFields = ({
  mode,
  wellNameValidator,
  wellDescriptionValidator,
  well: {
    title,
    description,
  },
}) => {
  const readOnlyModeFields = [
    <Form.Item
      label="Well Name:"
      key="readonly-title"
    >
      <div className="well-name">
        {title}
      </div>
    </Form.Item>,
    <Form.Item
      key="readonly-description"
      label="Well Description"
    >
      <div className="description">
        <p>{description}</p>
      </div>

    </Form.Item>,
  ];

  const editModeFields = [
    <Form.Item
      key="edit-title"
      label="Well Name:"
      name="title"
      initialValue={title}
      rules={[{
        validator: wellNameValidator,
      },
      ]}
    >
      <Input
        placeholder="Well Name:"
      />
    </Form.Item>,
    <Form.Item
      key="edit-descriiption"
      initialValue={description}
      label="Well Description"
      name="description"
      rules={[{
        validator: wellDescriptionValidator,
      },
      ]}
    >
      <Input.TextArea
        placeholder="Well Description:"
      />
    </Form.Item>,
  ];

  return (
    <>{mode === FORM_MODE_READ_ONLY
      ? readOnlyModeFields
      : editModeFields}
    </>
  );
};

export default WellInformationEditFields;
