const initialState = {
  filter_tags: [] as Array<string>,
};

export const SET_FILTER_TAGS = 'SET_FILTER_TAGS';

export function reducer(
  state = initialState,
  { type, payload }: { type: string; payload: Array<string> },
): typeof initialState {
  switch (type) {
    case SET_FILTER_TAGS:
      return {
        ...state,
        filter_tags: payload,
      };
    default:
      return state;
  }
}
