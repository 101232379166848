import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';

const StyledCheckbox = styled(Checkbox)`
  && {
    color: ${(props) => props.theme.widgets.color.full};

    .ant-checkbox-input:focus + .ant-checkbox-inner,
    &.ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner {
      border-color: ${(props) => props.theme.palette.primary.full};
    }
    .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-indeterminate .ant-checkbox-inner {
      background-color: ${(props) => props.theme.palette.primary.full};
      border-color: ${(props) => props.theme.palette.primary.full};
    }
    .ant-checkbox-checked:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 2px;
      border: 1px solid ${(props) => props.theme.palette.primary.full};
      content: '';
      -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
      animation: antCheckboxEffect 0.36s ease-in-out;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      visibility: hidden;
    }
  }
`;

export const StyledCheckboxGroup = (props: CheckboxGroupProps): ReactElement => (
  <StyledCheckbox as={Checkbox.Group} {...props} />
);

export default StyledCheckbox;
