import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Select } from 'antd';

const { Option } = Select;

const StyledSelect = styled(Select)`
  && {
    && .ant-select-selector {
      border-radius: ${(props) => (props.rounded ? '12px' : '0px')};
      background-color: ${(props) => props.theme.widgets.background.light};
      color: ${(props) => props.theme.widgets.color.full};
      border: ${(props) => props.theme.widgets.border.border.light};
    }
    .ant-select-selection:hover {
      border-color: ${(props) => props.theme.palette.primary.full};
    }
    &.ant-select-open .ant-select-selection {
      border-color: ${(props) => props.theme.palette.primary.full} !important;
      box-shadow: ${(props) => props.theme.palette.primary.boxShadow} !important;
    }
    .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active,
    .ant-select-open .ant-select-selection {
      border-color: ${(props) => props.theme.palette.primary.full} !important;
      box-shadow: ${(props) => props.theme.palette.primary.boxShadow} !important;
    }
  }
`;

const SelectComp = ({ doNotFormatValue = false, options, onChange = (...args) => {}, ...rest }) => {
  const themeProps = useSelector((state) => state.resources.theme);
  const roundTheme = useSelector((state) => state.resources.themeStyles.round);

  const handleChange = (val, option) => {
    const value = { label: option.label, value: val };
    // TODO: doNotFormatValue not ideal solution - look into alternatives
    // Resolves issue where wrapping Form.Item controls Select props.value
    onChange(doNotFormatValue ? val : value);
  };

  return (
    <StyledSelect
      rounded={roundTheme}
      showSearch
      onChange={handleChange}
      filterOption
      optionFilterProp="label"
      dropdownStyle={{
        backgroundColor: themeProps.widgets.background.full,
        color: themeProps.widgets.color.full,
      }}
      {...rest}
    >
      {options.map((option, index) => (
        <Option key={index} value={option.value} label={option.label} style={{ color: '#a5a5a5' }}>
          {option.label}
        </Option>
      ))}
    </StyledSelect>
  );
};

export default SelectComp;
