import { fetchSeriesByDate } from 'shared/repositories/axilSeriesRepository';
import * as glxProductionActionTypes from 'single-asset/actions/glxProductionActionTypes';
import { transformGLXSeries } from 'single-asset/transformers/glxProductionTransformers';

export const fetchGLXProductionSeries = async (seriesConfig, token, body) => {
  try {
    const response = await fetchSeriesByDate(token, body);

    if (response.ok) {
      return {
        ok: true,
        wellId: seriesConfig.wellId,
        seriesResult: await response.json(),
        seriesConfig,
      };
    }

    throw response;
  } catch (error) {
    const {
      url, status, statusText, type,
    } = error;

    return {
      ok: false,
      error: url && status && statusText && type && {
        statusText: `${status} ${type}: ${statusText} ${url}`,
      }
      || { statusText: error.toString() },
    };
  }
};

export const fetchGLXProductionSeriesList = (seriesConfigList, dates) => async (dispatch, getState) => {
  const {
    session: { token },
    singleAsset: { asset },
  } = getState();

  dispatch({
    type: glxProductionActionTypes.GLX_PRODUCTION__FETCH_SERIES_LIST,
    dates,
  });

  const fetchResults = await Promise.all(seriesConfigList.map((seriesConfig) => fetchGLXProductionSeries(
    seriesConfig,
    token,
    transformGLXSeries(seriesConfig, dates, asset),
  )));
  const successfulResultList = fetchResults.filter(({ ok }) => ok);

  successfulResultList.length
  && dispatch({
    type: glxProductionActionTypes.GLX_PRODUCTION__FETCH_SERIES_LIST_SUCCEEDED,
    successfulResultList,
  })
  || dispatch({
    type: glxProductionActionTypes.GLX_PRODUCTION__FETCH_SERIES_LIST_FAILED,
    failedResultList: fetchResults,
  });
};
