import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
`;

export const ClusterPoint = styled.button<{ size: number }>`
  all: unset;
  background: radial-gradient(${({ color }) => color}, #404040);
  background-repeat: no-repeat;
  border-radius: 50%;
  display: block;
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};

  & :hover {
    cursor: pointer;
  }
`;

export const GPSError = styled.div`
  display: flex;
  padding: 0px 6px;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  background: white;
  height: 30px;
  width: 300px;
  left: -150px;
  border: 1px solid #cdd4da;
  border-radius: 6px;
`;

export const HeaderKey = styled.div`
  display: flex;
  font-weight: 500;
  justify-content: flex-start;
  color: #404040;
  min-width: 40%;
  max-width: 60%;
  text-align: start;
`;

export const HeaderBody = styled.div`
  display: flex;
  min-width: 40%;
  max-width: 60%;
  justify-content: flex-end;
  text-align: end;
`;

export const LoaderDiv = styled.div`
  width: 100%;
  text-align: center;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const MapTooltipDiv = styled.div`
  position: absolute;
  bottom: 5px;
  left: -150px;
  width: 300px;
  background-color: white;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  padding: 10px;
  font-size: 14px;
  z-index: 100;
`;

export const NoGPS = styled.div`
  display: flex;
  padding: 0px 6px;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  background: #f8f9fa;
  height: 30px;
  min-width: 90px;
  left: -45px;
  border-radius: 6px;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 3px;
  padding-bottom: 3px;
  border-bottom: 1px solid #dadada;
  justify-content: space-between;
`;

export const SingleAssetButton = styled.button`
  all: unset;

  > i {
    color: ${({ color }) => color};
    cursor: pointer;
  }
`;

export const TooltipHeader = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin: 3px 0 12px 0;
`;
