import { wellIdList } from 'single-asset/constants/gasLiftConstants';

export const createFieldLengthValidator = (min, max, onValidationSuccess, onValidationFailed) => (fieldData, value) => (
  new RegExp(`^.{${min},${max}}$`).test(value)
    ? onValidationSuccess(fieldData)
    : onValidationFailed(fieldData, value, min, max)
);

export const getWellMapListFromConfig = (wellConfig, wells) => wellIdList
  .slice(0, wellConfig.length - 1)
  .map((wellId) => (({
    wellId,
    well: wells?.[wellId],
  })));

export const convertListToSet = (key, list) => Object.fromEntries(
  list.map((listItem, index) => [listItem?.[key] ?? index, listItem]),
);

export const calculateFormFieldStatusMap = (
  fieldNameList,
  prop = {},
  validator = (fieldName, propObj) => !!propObj?.[fieldName]?.length,
) => Object.fromEntries(
  fieldNameList.map((fieldName) => [fieldName, validator(fieldName, prop)]),
);
