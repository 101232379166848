import moment from 'moment';

import { displayedDateFormat } from 'single-asset/constants/glxProductionConstants';

export const getDateRange = (subtractedDays = 14) => ([
  moment().subtract(subtractedDays, 'days'),
  moment(),
]);

export const calculatePropertyName = ({ agg, label }) => {
  const [, aggregatorMethodName] = Object.keys(agg);

  return `${label} ${aggregatorMethodName}`;
};

export const formatXAxisPropertyToDatestring = (dataList, propertyName) => dataList.map((data) => ({
  ...data,
  [propertyName]: moment(data[propertyName]).format(displayedDateFormat),
}));
