import React, {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  ReactElement,
  SetStateAction,
  useState,
} from 'react';
import styled from 'styled-components';
import { StyledInput } from '../styled';

const FilterInput = styled(StyledInput)`
  width: 96%;
`;

const FilterWrapperDiv = styled.div`
  display: flex;
  justify-content: space-around;
  height: 40px;
  margin: 12px 0;
`;

type FilterCompProps = {
  filterTags: Array<string>;
  setFilter: Dispatch<SetStateAction<string>>;
  setFilterTags: (a: Array<string>) => void; // TODO: Fix this after AssetList conversion
};

const FilterComp = ({ setFilter, setFilterTags, filterTags }: FilterCompProps): ReactElement => {
  const [text, setText] = useState('');

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setText(value);
    setFilter(value);
  };

  const handleEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const tagName = text.trim();

      if (tagName !== '') {
        setText('');
        setFilterTags([...filterTags, tagName]);
      }
    }
  };

  return (
    <FilterWrapperDiv>
      <FilterInput
        onChange={handleChange}
        onKeyDown={handleEnter}
        placeholder="Filter Asset List (lease, asset, status, driver, model)"
      />
    </FilterWrapperDiv>
  );
};

export default FilterComp;
