import React, { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Tabs } from 'antd';

import { GlobalContext } from '../../GlobalContext';
import AccountInformation from './AccountInformation'; 
import AccountSettings from './AccountSettings'; 
import NotificationSettings from './NotificationSettings'; 
import Logout from './Logout';

import useFetch from '../hooks/useFetch';

const { TabPane } = Tabs;

const Container = styled.div`
  margin: 5% 3%;
`;

const Body = styled.div`
  background: ${props => props.theme.widgets.background.full};
  border: ${props => props.theme.widgets.border.border.light};
  border-radius: ${props => props.rounded ? '12px' : 'none'};
  padding: 12px;

  &&& .ant-tabs-tab-active, .ant-tabs-tab {
    color: ${props => props.theme.widgets.color.full};
  }
`;

const Settings = ({ user, logout }) => {
  const [selectedRows, setSelectedRows] = useState({});
  const [userSettings, setUserSettings] = useState({
    phone_number: null,
    send_email: null,
    send_sms: null,
    dnd_start: null,
    dnd_stop: null,
    dnd: null
  });

  const { assetList, subscriptionList } = useContext(GlobalContext); 

  const [
    preferencesLoading,
    preferences,
    fetchPreferences
  ] = useFetch('/v1/axil/notifications/list/preferences');

  const [
    updating,
    updated,
    updatePreferences
  ] = useFetch('/v1/axil/notifications/update/preferences');
  
  const [
    savingSubs,
    saveStatus,
    saveSubs
  ] = useFetch('/v1/axil/notifications/subscribe/assets');

  const roundedTheme = useSelector(state => state.resources.themeStyles.round);

  const tabs = [ 
    { id: 1, name: 'Information', component: AccountInformation },
    { id: 2, name: 'Notifications', component: NotificationSettings },
    { id: 3, name: 'Account Settings', component: AccountSettings },
    { id: 4, name: 'Logout', component: Logout }
  ];

  useEffect(() => { fetchPreferences() }, []);
  useEffect(() => {
    if (!preferencesLoading && preferences) { setUserSettings(preferences) }
  }, [preferencesLoading, preferences]);

  useEffect(() => {
    if (Object.keys(subscriptionList).length > 0) {
      setSelectedRows(subscriptionList)
    }
  }, [subscriptionList])

  return (
    <Container>
      <Body rounded={roundedTheme}>
        <Tabs defaultActiveKey='1' tabPosition='left'>        
          {tabs.map(t => {
            const Comp = t.component;
            return (
              <TabPane tab={t.name} key={t.id}>
                <Comp
                  user={user}
                  logout={logout}
                  assets={assetList}
                  selectedRows={selectedRows}
                  userSettings={userSettings}
                  setUserSettings={setUserSettings}
                  setSelectedRows={setSelectedRows}
                  updatePreferences={updatePreferences}
                  savingSubs={savingSubs}
                  saveSubs={saveSubs}
                  saveStatus={saveStatus}
                />
              </TabPane>
            )
          })}
        </Tabs>
      </Body>
    </Container>
  );
};

export default Settings;
