import { fetchTagById } from 'shared/repositories/axilTagRepository';
import * as telemetryStatsActionTypes from 'single-asset/actions/telemetryStatsActionTypes';
import { transformStats } from 'single-asset/transformers/telemetryTransformers';

export const fetchTelemetryStat = async (token, statsConfig, body, props) => {
  try {
    const response = await fetchTagById(token, body);

    if (response.ok) {
      return {
        ok: true,
        statsConfig,
        statsResult: await response.json(),
        props,
        ...(props?.wellId && { wellId: props.wellId }),
      };
    }

    throw response;
  } catch (error) {
    const {
      url, status, statusText, type,
    } = error;

    return {
      ok: false,
      error: url && status && statusText && type && {
        statusText: `${status} ${type}: ${statusText} ${url}`,
      }
      || { statusText: error.toString() },
    };
  }
};

export const fetchStatsList = async (statsConfig, [PENDING_ACTION_TYPE, SUCCESS_ACTION_TYPE, FAILED_ACTION_TYPE], dispatch, state) => {
  const {
    session: { token },
    singleAsset: { asset },
  } = state;

  dispatch({
    type: PENDING_ACTION_TYPE,
  });

  const fetchResults = await Promise.all(statsConfig.map(({ config, props }) => fetchTelemetryStat(
    token,
    config,
    transformStats({ config, asset }),
    props,
  )));
  const successfulResultList = fetchResults.filter(({ ok }) => ok);

  successfulResultList.length
  && dispatch({
    type: SUCCESS_ACTION_TYPE,
    successfulResultList,
  })
  || dispatch({
    type: FAILED_ACTION_TYPE,
    failedResultList: fetchResults,
  });
};

export const fetchTelemetryStatsLists = ({ statsConfig, wellsConfig }) => async (dispatch, getState) => {
  statsConfig && fetchStatsList(
    statsConfig,
    [
      telemetryStatsActionTypes.TELEMETRY_STATS__FETCH_STATS_LIST,
      telemetryStatsActionTypes.TELEMETRY_STATS__FETCH_STATS_LIST_SUCCEEDED,
      telemetryStatsActionTypes.TELEMETRY_STATS__FETCH_STATS_LIST_FAILED,
    ],
    dispatch,
    getState(),
  );

  wellsConfig && fetchStatsList(
    wellsConfig,
    [
      telemetryStatsActionTypes.GASLIFT_WELL_STATS__FETCH_STATS_LIST,
      telemetryStatsActionTypes.GASLIFT_WELL_STATS__FETCH_STATS_LIST_SUCCEEDED,
      telemetryStatsActionTypes.GASLIFT_WELL_STATS__FETCH_STATS_LIST_FAILED,
    ],
    dispatch,
    getState(),
  );
};
