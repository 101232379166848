/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Highcharts from 'react-highcharts';
import styled from 'styled-components';

const Container = styled.div`
  ${ props => `
     background: ${props.theme.widgets.background.full};
     border: ${props.bordered ? props.theme.widgets.border.border.full : 'none'};
     border-radius: ${props.rounded ? '12px' : 'none'};
     
     .highcharts-yaxis-grid {
       .highcharts-grid-line {
         stroke: ${props.theme.widgets.border.color}
       }
     }
     
     .highcharts-xaxis {
       .highcharts-axis-line {
         stroke: ${props.theme.widgets.border.color}
       }
     }

     && text {
       color: white;
     }
  `}
`;

const NoDataChart = styled.div`
  width: 100%;
  text-align: center;
  padding: 89px 0;
`;

const colors = [
  '#37B24D',
  '#4C6EF5',
  '#5F3DC4',
];

const WidgetChart = ({ config, props: { series } }) => {
  const [ chartSeries, setSeries ] = useState([]);
  const {
    type,
    chartTitle,
    chartSubTitle,
    yAxis,
  } = config;

  const offset = new Date().getTimezoneOffset();
  const themeProps = useSelector(state => state.resources.theme);

  useEffect(() => {
    if (series && series.length > 0) {
      setSeries(series);
    }
  }, [series]);

  const formatSeriesData = (data, index) => {
    if (!yAxis) {
      return ({
        name: data.label,
        data: data.series,
        color: data.color || colors[index],
        zIndex: data.zIndex
      })
    };

    return ({
      name: data.label,
      data: data.series,
      color: data.color || colors[index],
      yAxis: index,
      zIndex: data.zIndex
    });
  };
  
  const decorateYAxis = (opposite) => chartSeries.map ((s, i) => {
    return {
      plotLines: [{
        color: 'red',
        width: 1,
        dashStyle: 'Dash',
        zIndex: 2
      }],
      opposite: opposite || i > 0,
      labels: {
        format: '{value:.2f}',
        style: {
          fontSize: '0.8em',
          color: colors[i]
        }
      },
      title: {
        text: null
      }
    };
  });

  const chartConfig = {
    chart: {
      type,
      backgroundColor: themeProps.widgets.background.full
    },
    scrollbar: {
      enabled: false
    },
    navigator: { 
      enabled: false
    },
    rangeSelector: {
      enabled: false,
    },
    time: {
      timezoneOffset: offset,
    },
    title:{
      text: chartTitle
    },
    subtitle: {
      text: chartSubTitle
    },
    yAxis: [
      ...decorateYAxis(),
    ],
    xAxis: {
      title: { text: 'Pressure(psi)' }
    },
    tooltip: {
      formatter: function() {
        let s = `<b>Production Rate: ${this.x}</b>`
        this.points.map(p => {
          s += `<br/> ${p.series.name}: ${this.y}(psi)`
        });
        return s;
      },
      backgroundColor: 'rgba(255, 255, 255, 0.94)',
      borderColor: '#CCD1D9',
      borderRadius: 6,
      split: false,
      shared: true,
      valueDecimals: 2,
    },
    series: [
      ...chartSeries.map((s, i) => formatSeriesData(s, i))
    ],
    credits: false,
    responsive: {
      rules: [{
        condition: {
          maxWidth: 750
        },
        chartOptions: {
          yAxis: chartSeries.map((s, i) => ({
            labels: {
              rotation: -90,
              format: '{value}',
              style: { fontSize: 10 }
            }
          })),
        }
      }]
    }
  };

  return (
    <Container>
      { chartSeries.length > 0
        ? ( <Highcharts config={chartConfig} /> )
        : ( <NoDataChart>No Data for the current time range</NoDataChart> )
      }
    </Container>
  );
};

WidgetChart.defaultProps = {
}

WidgetChart.propTypes = {
};

export default WidgetChart
